import {Model, classFields} from '../../app/classes';



export default class TaskModel extends Model {
    getClass(){return TaskModel;}
}


classFields(TaskModel, Model, {
    id: {
        type: 'int',
    },
    status: {
        type: 'string',
        required: true,
    },
    name: {
        type: 'string',
        required: true,
        value: "TESTING 444",
    },
});


