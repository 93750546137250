import React from "react";
import utils from "../../functions/utils";
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from "./Icon";
import Menu from "../lists/Menu";
import "./Input.scss";
import { ValidationError } from "../../app/errors";


// functions
function makeItems(options){
    if(!options) return [];

    let items = [];
    if(utils.isType(options, "array") && options.length){

        // array with string items?
        if(utils.isType(options[0], "string")){
            items = options.map((label, key) => ({label: label, value: key + 1}));
        }
    } else if(utils.isType(options, "map")){
        // map: label => value
        items = Object.keys(options).map(key => ({
            label: key,
            value: options[key],
        }))
    }

    return items;
}

// other components
function TextForSelect(props){
    let {className, icon, leftIcon, onClick, style, valueLabel, label, value, name} = props;

    const css2 = utils.classNames("lds-select-text", className);
    if(icon && typeof icon === 'string') icon = <Icon type={icon} />
    if(leftIcon && typeof leftIcon === 'string') leftIcon = <Icon type={icon} />

    // render
    return (
        <div onClick={onClick} className={css2} style={style}>
            {leftIcon}<span>{valueLabel || label}</span>{icon}<input type="hidden" name={name} value={value} />
        </div>
    );
}


function Hint(props){
    let {hint, error, formError, name} = props;
    if(formError) error = 'asdf';
    // else if(error && typeof error === "object") error = 'asdf';

    // render
    if(error) return (
        <div className="lds-input-hint is-text-sm is-type-error"><Icon type="alert" color="red" />{error}</div>
    );
    if(hint) return (
        <div className="lds-input-hint is-text-sm">{hint}</div>
    );
}




// ================== CHECKBOX ================== //


function AutoToggle(props){
    const {on, onChange, } = props;
    const [isOn, setOn] = React.useState(on);

    const onClick = () => {
        setOn(!isOn);
        onChange(!isOn);
    }

    return <Toggle {...props} onClick={onClick} on={isOn} />
}
  
function Toggle(props){
    const {label, sprite, onClick, style, id, className, on, debug, size, } = props;

    // auto toggle?
    if(!onClick) return <AutoToggle {...props} />;

    const onClick2 = () => onClick(on);

    // render
    const css = utils.classNames("lds-toggle", className, label ? "is-withlabel" : "is-nolabel", size && `is-size-${size}`, sprite && "is-sprite", debug && 'is-debug', on && 'is-on');
    return (
        <div className={css} onClick={onClick2} id={id} style={style}>
            <div className="lds-toggle-control">
                <div className="lds-toggle-dot" />
            </div>
            {label && <div className="lds-toggle-label">{label}</div>}
            {/* <div className="lds-toggle-control" />{label && <div className="lds-toggle-label">{label}</div>} */}
        </div>
    );
}


function Radio(props){
    return <Checkbox {...props} type="radio" />;
}

// auto
function CheckboxAuto(props){
    const {checked} = props;
    const [checked2, setSelected] = React.useState(checked || false);

    const onClick = (item) => {
        setSelected(!item.checked);
    }

    return <Checkbox {...props} onClick={onClick} checked={checked2} />
}

// main
function Checkbox(props){
    let {label, children, id, className, name, value, style, debug, sprite, bgColor, type, size, checked, onClick, icon = "checkbox", groupValue} = props;
    const labeled = (label || children);

    // groupValue (radio buttons)
    if(groupValue !== undefined) checked = (value === groupValue);

    // types
    if(type) icon = type;

    // auto?
    if(!onClick) return <CheckboxAuto {...props} />;

    // handler
    const onClick2 = () => {
        if(onClick) onClick({name: name, value: value, checked: checked || false});
    }
    
    // sprite / bgcolor
    const iconCss = (sprite && bgColor) ? {backgroundColor: bgColor, } : undefined;
    
    // render
    const css = utils.classNames("lds-checkbox", className, type && `is-type-${type}`, sprite && `is-sprite`, debug && `is-debug`, bgColor && `is-bgcolor-${bgColor}`, icon && `is-icon-${icon}`, size && `is-size-${size}`, labeled ? 'is-labeled' : 'is-notlabeled');
    return (
        <div className={css} id={id} onClick={onClick2} style={style}>
            <Icon type={icon} selected={checked} size={size} style={iconCss} />
            {name && checked && <input type="hidden" name={name} value={value || 1} />}
            <span className="lds-checkbox-label">{label || children}</span>
            {checked && <input type="hidden" name={name} value={value} />}
        </div>
    );
}




// ================== SELECT ================== //


function SelectText(props){
    return <Select {...props} type="text" />;
}

function SelectAuto(props){
    const [value, setValue, placeholder] = React.useState(null);
    delete props.onChange; // just in case
    
    // set init value
    React.useEffect(() => {
        setValue(() => props.value || 1);
    }, []);

    // change handler
    const onChange = (item) => {
        setValue(item.value);
    }
    
    // render
    return <Select {...props} onChange={onChange} value={value} />
}


export function Select(props){
    let {value, options, label, placeholder, onChange, type, icon, leftIcon, className, menu, style, name} = props;

    // menu
    if(!menu) menu = {};
    if(!menu.width && style?.width) menu.width = style.width;

    const [anchorEl, setAnchorEl] = React.useState(null); // must be before if statement

    // auto select?
    if(!onChange) return <SelectAuto {...props} />;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const ao = {
        vertical: menu.top || 'bottom',
        horizontal: menu.left || 'left',
      }

    // label
    if(!label) label = "No label";

    // options / items
    let items = makeItems(options);

    // find select
    let valueLabel = placeholder || "Empty";
    let filled = false;
    items.forEach(item => {
        item.selected = item.checked = (item.value == value);
        if(item.selected){
            filled = true;
            valueLabel = item.label;
        }
    })

    // on change / click
    const onClick = (item) => {
        if(onChange && item.value != value) onChange(item);
        handleClose();
    } 

    // button
    let btn;
    if(type === "text"){
        btn = <TextForSelect className={className} icon={icon} leftIcon={leftIcon} onClick={handleClick} style={style} valueLabel={valueLabel} label={label} value={value} name={name} />
    } else {
        btn = <SelectButton name={name} label={label} icon={icon} value={valueLabel} onChange={() => {}} onClick={handleClick} style={style} />
    }

    // render
    return (
        <React.Fragment>
            {btn}
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={ao}>
                <Menu.List items={items} closer={handleClose} onClick={onClick} width={menu.width} />
            </Popover>
        </React.Fragment>
    );
}







// ================== INPUT ================== //

function SelectButton(props){
    return <Input {...props} type="select" />
}

function Password(props){
    return <Input {...props} type="password" />
}

function TextArea(props){
    return <Input {...props} type="textarea"  />
}


// auto
function InputAuto(props){
    const [value, setValue] = React.useState(props.value);

    const onChange = (e) => {
        setValue(e?.target?.value);
    }

    return <Input {...props} onChange={onChange} value={value} />
}


// --- main --- //

const TYPES = {text: 1, password: 1, textarea: 1, }

function InputTag(props){return <input {...props} />}
function TextareaTag(props){return <textarea {...props} />}


function Input(props){
    let {label, value, onClick, onKeyPress, onEnter, onChange, onFocus, onBlur, debug, id, inputId, className, type, size, name, style, icon, rightIcon, disabled, readOnly, standard, sprite, variant, error, formError, noHint} = props;

    if(formError && formError.errors[name]) error = "asdf"; // formError.errors[name].getMessage();
    // utils.showError(formError);

    // is auto?
    if(!onChange) return <InputAuto {...props} />;

    // on enter
    if(onEnter) onKeyPress = (e) => ((e.key == 'Enter') && onEnter(e));

    // types
    if(!type) type = "text";
    let typeprop = TYPES[type] ? type : "text";
    if(type === "password" && !label) label = "Password";
    if(type === "select"){
        if(!rightIcon) rightIcon = "chevron";
        readOnly = true;
    }

    // set value (if not scalar otherwise)
    if(value === null || value === undefined) value = '';

    // set tag (input or textarea)
    const Tag = (type === 'textarea') ? TextareaTag : InputTag;

    // css
    const filled = (typeof value === 'string') && value.length;
    const css = utils.classNames("lds-input", className, filled ? 'is-filled' : 'is-empty', type && `is-type-${type}`, standard && `is-standard`, sprite && `is-sprite`, debug && `is-debug`, variant && `is-variant-${variant}`, size && `is-size-${size}`, name && `is-name-${name}`, icon && 'is-iconic', rightIcon && 'is-iconic-right', disabled && 'is-disabled', error && 'is-error');
    
    // render
    return (
        <div className={css} id={id} style={style} onClick={onClick}>
            {icon && <Icon className="lds-input-icon"  type={icon} />}
            <Tag 
                name={name}
                className="lds-input-field is-label-lg is-color-gray9" 
                onChange={onChange} 
                onKeyPress={onKeyPress}
                onFocus={onFocus}
                onBlur={onBlur}
                readOnly={readOnly} 
                type={typeprop} 
                placeholder={label} 
                value={value} 
                disabled={disabled} 
                id={inputId}
            />
            <div className="lds-input-label is-label-sm is-color-gray6">{label}</div>
            {rightIcon && <Icon className="lds-input-icon-right"  type={rightIcon} />}
            {!noHint && <Hint {...props} />}
        </div>
    );
}




// --- public --- //

Input.Radio = Radio;
Input.Checkbox = Checkbox;
Input.Select = Select;
Input.SelectText = SelectText;
Input.SelectButton = SelectButton;
Input.Password = Password;
Input.TextArea = TextArea;
Input.Toggle = Toggle;

export default Input;
