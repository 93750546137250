import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import Mobile from "../../components/containers/Mobile";
import ApptsNewModal from "./ApptsNewModal";
import "./Appts.scss";


const debug = 0;


function Details(){
    return (
        <Mobile.Wrapper type="sub" toBack="/app/appts/" label="Appointment Details" spriteId="lds-app-appts-details">

        </Mobile.Wrapper>
    ); 
}


export default function Screen(){
    const dispatch = useDispatch();
    const {apptAdded} = useGlobals();
    const onClickNew = () => dispatch({call: "SHOW_MODAL", component: <ApptsNewModal />});
    const cardTop = apptAdded ? 500 : 300;

    // // reset appt added
    // React.useEffect(() => {
    //     console.log("=================== SET_APPT_ADDED 111: " + apptAdded);

    //     return () => {
    //         console.log("=================== SET_APPT_ADDED 222: " + apptAdded);
    //         if(apptAdded) dispatch({call: "SET_APPT_ADDED", value: false, });
    //     };
    // }, []);



    // console.log("=================== SET_APPT_ADDED 2222: " + apptAdded);


    // render
    return (
        <React.Fragment>

            {/* <Button to="/app/meds/refill" type="primary" mobileBottom icon="shop-white" shadow label="Order" /> */}
            <Mobile.Wrapper type="tab" label="Appointments">

                <Sprite.Container id="lds-app-appts" className={apptAdded && "is-2cards"}>

                    <Sprite.Button to="/app/appts/details" debug={debug} style={{top: 20, width: '100%', height: 200, }} />

                    <Sprite.Button debug={debug} onClick={onClickNew} style={{top: cardTop, width: '100%', height: 160, }} />

                    {/* <Sprite.Button debug={debug} style={{top: 230, width: '100%', height: 210, }} />
                    <Sprite.Button debug={debug} style={{top: 530, width: '100%', height: 210, }} /> */}


                </Sprite.Container>

            </Mobile.Wrapper>

        </React.Fragment>

    );
}

Screen.Details = Details;
