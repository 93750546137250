import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Loader from "../../components/core/Loader";
import Mobile from "../../components/containers/Mobile";
import { useGlobals, useDispatch } from '../../app/context';
import MedsTabs from "./MedsTabs";
import utils from "../../functions/utils";

const debug = 0;

const title = "Fill Your Prescription";
const toExit = "/app/meds";
const steps = [
    "/app/meds/refill",
    "/app/meds/refill/ins2",
    "/app/meds/refill/info",
    "/app/meds/refill/method",
    "/app/meds/refill/review",
    "/app/meds/refill/confirm",
    "/app/meds",
];


// start (main)
export default function Screen(){
    const step = 0;
    return (
        <Mobile.Wrapper type="sub" label={title} toBack="/app/meds" toExit={toExit} spriteId="lds-app-meds-refill">

            {false && <Loader type="preload" color="translucent" duration={1000} style={{top: 0, width: '100%', height: '100%', }} />}

            <Sprite.Button debug={debug} to={steps[step+1]} style={{top: 680, width: '100%', height: 50, }} />
        </Mobile.Wrapper>
    );
}


export function Ins2(){
    const step = 1;
    return (
        <Mobile.Wrapper type="sub" label={title} toBack={steps[step-1]} toExit={toExit} noScroll spriteId="lds-app-meds-refill-ins2">
            <Sprite.Button debug={debug} to={steps[step+1]} style={{top: 640, width: '100%', height: 50, }} />
        </Mobile.Wrapper>
    );
}


export function Info(){
    const step = 2;
    return (
        <Mobile.Wrapper type="sub" label={title} toBack={steps[step-1]} toExit={toExit} noScroll spriteId="lds-app-meds-refill-info">
            <Sprite.Button debug={debug} to={steps[step+1]} style={{top: 640, width: '100%', height: 50, }} />
        </Mobile.Wrapper>
    );
}


export function Method(){
    const step = 3;
    const [status, setStatus] = React.useState(false);
    const onStatusClick = (item) => setStatus(item.value);

    // render
    return (
        <Mobile.Wrapper type="sub" label={title} toBack={steps[step-1]} toExit={toExit} noScroll spriteId="lds-app-meds-refill-method">
            

            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="lg" value="pickup" style={{top: 309, left: 32, width: 300, }} />
            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="lg" value="delivery" style={{top: 373, left: 32, width: 300, }} />
            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="lg" value="mail" style={{top: 437, left: 32, width: 300, }} />
            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="lg" value="curbside" style={{top: 501, left: 32, width: 300, }} />


            <Sprite.Button debug={debug} to={steps[step+1]} style={{top: 640, width: '100%', height: 50, }} />

        </Mobile.Wrapper>
    );
}


export function Review(){
    const dispatch = useDispatch();
    const nav = useNavigate();
    const step = 4;

    // handler
    const onClickCTA = () => {
        utils.delayRedirect(dispatch, nav, steps[step+1]);
    }

    // render
    return (
        <Mobile.Wrapper type="sub" label={title} toBack={steps[step-1]} toExit={toExit} spriteId="lds-app-meds-refill-review">
            <Sprite.Button debug={debug} to222={steps[step+1]} onClick={onClickCTA} style={{top: 860, width: '100%', height: 50, }} />
        </Mobile.Wrapper>
    );
}


export function Confirm(){
    const dispatch = useDispatch();
    const nav = useNavigate();
    const step = 5;

    // handler
    const onClickCTA = () => {
        utils.delayRedirect(dispatch, nav, steps[step+1]);
    }

    // render
    return (
        <Mobile.Wrapper type="sub" label={title} toBack222={steps[step-1]} toExit={toExit} spriteId="lds-app-meds-refill-confirm">

            {/* <Loader type="preload" duration={2500} style={{top: 0, width: '100%', height: '100%', }} /> */}

            <Sprite.Button debug={debug} to222={steps[step+1]} onClick={onClickCTA} style={{top: 640, width: '100%', height: 50, }} />
        </Mobile.Wrapper>
    );
}



Screen.Ins2 = Ins2;
Screen.Info = Info;
Screen.Method = Method;
Screen.Review = Review;
Screen.Confirm = Confirm;


