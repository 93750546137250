import React from "react";
import SideNav from "../../components/nav/SideNav";
import { useGlobals, useDispatch } from '../../app/context';

// import utils from "../../functions/utils";
// import {NewTaskModal} from "./tasks.views";
import TaskFormModal from "./TaskFormModal";
// import {NewTaskModal} from "./TasksModals";


const Tab = SideNav.Tab;
const Filter = SideNav.Filter;


export default function TasksSideNav(){
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch({call: "SHOW_MODAL", component: <TaskFormModal/>})
    }

    const open = true;
    const handleClose = () => {}

    return (
        <SideNav>
            <SideNav.CTA label="New task" onClick={onClick} />
            <SideNav.TabGroup>
                <Tab label="All tasks" icon="inbox" selected />
                <Tab label="Flagged" icon="bookmark" />
                <Tab label="Resolved"icon="task" />
                <Tab label="Unresolved"icon="alert-circle" />
                <Tab label="Recurring" icon="recurring"/>
                <Tab label="Archive" icon="archive" />
                <Tab label="Trash" icon="trash" />
            </SideNav.TabGroup>
            <SideNav.FilterGroup label="Filters">
                <Filter label="Created by me" selected />
                <Filter label="High priority" />
            </SideNav.FilterGroup>
        </SideNav>
    );
}


