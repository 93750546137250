import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Mobile from "../../components/containers/Mobile";

const debug = 0;

export default function Screen(){
    return (
        <Mobile.Wrapper type="sub" noScroll label="Your Account" toBack="/app/account" spriteId="lds-app-account-personal">

            <Input sprite label="First name" debug={debug} value="Martha" style={{top: 27, left: 28, width: 320, }} />
            <Input sprite label="Last name" debug={debug} value="Smith" style={{top: 101, left: 28, width: 320, }} />
            <Input sprite label="DOB (mm/dd/yyyy)" debug={debug} value="9/2/1974" style={{top: 175, left: 28, width: 320, }} />

            {/* <Input label="First name" debug={debug} value="Martha" style={{top: 87, left: 28, width: 300, }} /> */}

            {/* <Sprite.LikeText debug={debug} style={{top: 40, width: '100%', height: 80, }} /> */}
            <Sprite.Button debug={debug} style={{top: 230, width: '100%', height: 210, }} />
            <Sprite.Button debug={debug} style={{top: 530, width: '100%', height: 210, }} />

        </Mobile.Wrapper>
    );
}
