import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, useStepper, steps, } from "./Onboard2.config";
import "./Onboard2.scss";

const DEBUG = 0;


export default function Page(props){
    const dispatch = useDispatch();
    const {onboard2, } = useGlobals();
    const {nextUrl, backUrl, changeHandler, submitHandler, } = useStepper();
    const [locals, setLocals] = React.useState({...onboard2, });

    const onChange = (e) => changeHandler(e, locals, setLocals);
    const onSubmit = (e) => submitHandler(dispatch, locals, );


    // Amplitude

    // render
    return (
        <Container icon="clock" title="Account" description="Please confirm or fill-in the following info.">

            <Sprite.Container debug={0} className="lds-onboard2-account">

                {/* <Sprite.Button debug={DEBUG} style={{top: 0, width: 120, }} />
                <Sprite.Container debug={DEBUG} style={{top: 50, width: '100%', height: 290, cursor: 'text', }} />
                <Sprite.Button debug={DEBUG} style={{top: 340, left: 'auto', right: 0, width: 120, height: 30, }} /> */}

                {/* <Input sprite debug={0} label="Username" name="username" value={locals.username} onChange={onChange} style={{top: 56, left: 10, width: 136, }} />
                <Input sprite debug={0} label="Username" name="username" value={locals.username} onChange={onChange} style={{top: 56, left: 158, width: 136, }} /> */}


                <Input sprite debug={0} label="Name" name="name" value={locals.name} onChange={onChange} style={{top: 42, left: 10, width: 330, }} />
                <Input sprite debug={0} label="Phone number" name="phone" value={locals.phone} onChange={onChange} style={{top: 118, left: 10, width: 330, }} />
                <Input sprite debug={0} label="Email address" name="input1" value={locals.input1} onChange={onChange} style={{top: 191, left: 10, width: 330, }} />
                <Input sprite debug={0} label="Brand name" name="input2" value={locals.input2} onChange={onChange} style={{top: 313, left: 10, width: 330, }} />
                <Input sprite debug={0} label="Parent company" name="input3" value={locals.input3} onChange={onChange} style={{top: 388, left: 10, width: 330, }} />
                <Input sprite debug={0} label="Pharmacy name" name="input4" value={locals.input4} onChange={onChange} style={{top: 509, left: 10, width: 330, }} />
                <Input sprite debug={0} label="Legal entity name" name="input5" value={locals.input5} onChange={onChange} style={{top: 583, left: 10, width: 330, }} />
                <Input sprite debug={1} label="DBA" name="input6" value={locals.input5} onChange={onChange} style={{top: 683, left: 10, width: 330, }} />
                <Input sprite debug={1} label="Status" name="input6" value={locals.input5} onChange={onChange} style={{top: 783, left: 10, width: 330, }} />


                {/* */}<Sprite.Container style={{top: 1610, left: 0, }}>
                    <Sprite.Button debug={0} to={backUrl} style={{top: 0, width: 170, }} />
                    <Sprite.Button debug={0} onClick={onSubmit} style={{left: 180, width: 170, }} />
                </Sprite.Container> 
                
            </Sprite.Container>

        </Container>
    );
}
