import Sprite from "../core/Sprite";
import utils from "../../functions/utils";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import "./TabNav.scss";

const debug = 0;

function Tab(props){
    const {name, to} = props;
    return <Sprite.Button to={to} className={'is-name-' + name} debug={debug} />;
}

export default function TabNav(props){
    let {tab} = props;
    const location = useLocation();
    const parts = location?.pathname?.split('/');
    // tab
    if(!tab) tab = parts[2];

    // render
    const css = utils.classNames("lds-tabnav", tab && `is-tab-${tab}`);
    return (
        <div className={css}>
            <div className="lds-tabnav-tabs">
                <Tab name="home" to="/app/home" />
                <Tab name="meds" to="/app/meds" />
                <Tab name="messages" to="/app/messages" />
                <Tab name="appts" to="/app/appts" />
                <Tab name="account" to="/app/account" />
            </div>
        </div>
    );
}