import types from "./types";

const dateObj = (value) => {
    if(value instanceof Date) return value;
    value = new Date(value);
    // return value;
    if(value.getFullYear() !== '1969') return value;
}

const year = "2023";
const today = new Date("03/31/2023 12:00:00");
const pastDays = {"03/30/2023": 1, "03/29/2023": 1, "03/28/2023": 1, "03/27/2023": 1, "03/26/2023": 1, };
const futurDays = {"04/01/2023": 1, "04/02/2023": 1, "04/03/2023": 1, "04/04/2023": 1, "04/05/2023": 1, };

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const days2 = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const months2 = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const pad2 = (n) => {
    return n > 10 ? n : ('0' + n);
}


const formats = {

    niceTimestamp: (d) => {
        d = dateObj(d);
        if(!d) return;
        const datestr = formats.date(d);

        // today?
        if(formats.date(today) === formats.date(d)){
            return formats.time(d);
        }
        if(futurDays[datestr]){
            return formats.niceDay(d) + " " + formats.time(d);
        }

        if(d.getFullYear() === year){
            return formats.niceMonth(d) + " " + d.getDate();
        }
        return formats.date(d);
    },

    niceDay: (d) => {
        d = dateObj(d);
        if(d) return days[d.getDay()];
    },

    niceDate: () => {

    },

    niceMonth: (d) => {
        d = dateObj(d);
        if(d) return months[d.getMonth()];
    },

    time: (d) => {
        d = dateObj(d);
        if(!d) return;
        let h = d.getHours();
        let str = '';
        if(h > 12) str += (h - 12);
        else str += h;
        str += ':' + pad2(d.getMinutes());
        str += (h > 12) ? " PM" : " AM";
        return str;
    },

    weekday: (d) => {
        // 14.21.432.31
        // Anytime
    },

    date: (d) => {
        d = dateObj(d);
        // if(!d) return;
        let month = d.getMonth() + 1;
        let date = d.getDate() + 1;
        month = (month < 10) ? ('0' + month) : month;
        date = (date < 10) ? ('0' + date) : date;
        // return "yoooo: " + d.getMonth();
        return month + "/" + date + '/' + d.getFullYear();
    },

    timestamp: (d) => {
        return formats.date(d) + " " + formats.time(d);
    },

    // END OF FORMATS
}


export default formats;