import React from "react";
import utils from "../../functions/utils";
import { BrowserRouter as Router, Route, Routes, useLocation, Link, useNavigate } from 'react-router-dom';
import Icon from "./Icon";
import "./Button.scss";

function ButtonPrimary(props){
    return <Button {...props} type="primary" />
}

function ButtonGroupForm(props){
    const {buttons, isForm} = props;
    if(!buttons) return;
    let {cancel, ok, add, back, close} = buttons;
    const fn = () => {};

    let arr = [];
    if(buttons.delete) arr.push(<Button key='delete' label="Delete" name="delete" onClick={buttons.delete} icon="trash" />);
    if(cancel) arr.push(<Button key='cancel' label="Cancel" name="cancel" onClick={cancel} />);
    if(ok) arr.push(<ButtonPrimary key='ok' label="OK" name="ok" onClick={ok===true?fn:ok} isSubmit={isForm} />);
    if(add) arr.push(<ButtonPrimary key='add' label="Add" name="add" onClick={add===true?fn:ok} isSubmit={isForm} />);
    if(back) arr.push(<Button key='back' label="Back" name="back" onClick={back} />);
    if(close) arr.push(<ButtonPrimary key='close' label="Close" name="close" onClick={close} />);
    return arr;
}

function ButtonGroup(props){
    var {children, type, className} = props;
    const css = utils.classNames("lds-buttongroup", className);

    // this is 
    switch(type){
        case 'form': children = <ButtonGroupForm {...props} />
    }
    return (<div className={css}>{children}</div>);
}


function Button(props){
    let {label, children, onClick, id, className, type, size, name, icon, iconColor, isSubmit, mobileBottom, shadow, to} = props;
    const nav = useNavigate();

    // nav
    if(to) onClick = (e) => {
        e.preventDefault();
        // nav(to);
        utils.nav(nav, to);
    };

    const css = utils.classNames("lds-button is-text-md", className, type && `is-type-${type}`, size && `is-size-${size}`, name && `is-name-${name}`, mobileBottom && 'is-mobilebottom', shadow && 'is-shadow');
    const props2 = {onClick, id, type: isSubmit && "submit"};
    if(typeof props2.type !== 'string') delete props2.type; // NOTE: was getting warnings
    if(!iconColor) iconColor = "dark";
    

    return <button className={css} {...props2}>{icon && <Icon type={icon} color={iconColor} />}{label || children}</button>
}


// function ButtonDropdown(props){
//     let {value, options, label, placeholder, onChange} = props;

//     const [anchorEl, setAnchorEl] = React.useState(null); // must be before if statement

//     // auto select?
//     if(!onChange) return <SelectAuto {...props} />;


//     const handleClick = (event) => {
//       setAnchorEl(event.currentTarget);
//     };
  
//     const handleClose = () => {
//       setAnchorEl(null);
//     };
  
//     const open = Boolean(anchorEl);
//     const id = open ? 'simple-popover' : undefined;

//     const ao = {
//         vertical: 'bottom',
//         horizontal: 'left',
//       }

//     // label
//     if(!label) label = "No label";

//     // options / items
//     let items = [];
//     if(options){
//         if(utils.isType(options, "array") && options.length){

//             // array with string items?
//             if(utils.isType(options[0], "string")){
//                 items = options.map((label, key) => ({label: label, value: key + 1}));
//             }
//         } else if(utils.isType(options, "map")){
//             // map: label => value
//             items = Object.keys(options).map(key => ({
//                 label: key,
//                 value: options[key],
//             }))
//         }
//     }

//     // find select
//     let valueLabel = placeholder || "Empty";
//     let filled = false;
//     items.forEach(item => {
//         item.selected = item.checked = (item.value == value);
//         if(item.selected){
//             filled = true;
//             valueLabel = item.label;
//         }
//         console.log("---- YO: " + value, item);
//     })

//     // on change / click
//     const onClick = (item) => {

//         // console.log("====== onClick 333: ", item);
//         if(onChange && item.value != value) onChange(item);
//         handleClose();
//     } 

//     const css = utils.classNames("lds-select", filled && "is-filled");
    

//     return (
//         <div className={css}>
//             <button onClick={handleClick}><b>{label}: </b>{valueLabel}</button>
//             <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={ao}>
//                 <Menu items={items} onClick={onClick} />
//             </Popover>
//         </div>
//     );
// }



Button.Group = ButtonGroup;
Button.Primary = ButtonPrimary;
// Button.Dropdown = ButtonDropdown;

export default Button;
