import React from "react";
import utils from "../../functions/utils";
import Popover from '@mui/material/Popover';
import Icon from "../core/Icon";
import { useNavigate } from "react-router-dom";
import "./Menu.scss";



let key = 1;

function MenuItem(props){
    let {onClick: onClick_global, item, iconic, closer} = props;
    let {icon, righticon, checked, label, selected, to, divider, onClick: onClick_item } = item;
    const nav = useNavigate();

    // iconic?
    if(icon) iconic = true;

    // checkmark
    if(checked) righticon = "check";
    if(selected) {
        selected = true;
        righticon = "check";
    }

    // 
    // if(!onClick && to) onClick = () => {
    //     nav(to); 
    //     closer();
    // }
    const onClick2 = () => {
        closer();
        if(onClick_item) onClick_item(item);
        if(onClick_global) onClick_global(item);
        if(to) utils.nav(nav, to);
    }
    // const onClick2 = onClick ? () => onClick(item) : undefined;
    // const onClick2 = to ? (() => {nav(to); closer(); }) : () => (onClick && onClick(item));

    // divider?
    if(divider) return <li className="lds-menuitem-divider"><hr/></li>;

    // render
    const css = utils.classNames("lds-menuitem", iconic && "is-iconic", selected && "is-selected", righticon && "is-righticon");
    return (
        <li className={css} onClick={onClick2}>
            {icon && <Icon size="sm" type={icon} selected={selected} className="lds-menuitem-icon" />}
            {label}
            {righticon && <Icon size="sm" type={righticon} selected={selected} className="lds-menuitem-icon-right" />}
        </li>
    )
}



function MenuList(props){
    let {items, onClick, children, width, closer} = props;
    const css = utils.classNames("lds-menulist");
    if(items) children = items.map((item) => (<MenuItem closer={closer} item={item} onClick={onClick} key={key++} />));

    return (
        <ul className={css} style={width && {width: width}}>{children}</ul>
    );
}


export default function Menu(props){
    let {children, onClick, className, items, style, position, width, } = props;

    const [anchorEl, setAnchorEl] = React.useState(null); // must be before if statement

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // position
    let p = {ao: {vertical: 'bottom', horizontal: 'left', }, to: {vertical: 'top', horizontal: 'left', }}; // top-left
    switch(position){
        case 'top-right':       p = {ao: {vertical: 'bottom', horizontal: 'right', }, to: {vertical: 'top', horizontal: 'right', }}; break;
        case 'bottom-left':     p = {ao: {vertical: 'top', horizontal: 'left', }, to: {vertical: 'bottom', horizontal: 'left', }}; break;
        case 'bottom-right':    p = {ao: {vertical: 'top', horizontal: 'right', }, to: {vertical: 'bottom', horizontal: 'right', }}; break;
    }


    const onClick2 = (item) => {
        if(onClick) onClick(item);
        handleClose();
    }

    // render
    const css = utils.classNames("lds-menu", className);
    return (
        <React.Fragment>
            <div className={css} style={style} onClick={handleClick}>{children}</div>
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={p.ao} transformOrigin={p.to}>
                <Menu.List items={items} closer={handleClose} onClick={onClick2} width={width} />
            </Popover>
        </React.Fragment>
    );
}


Menu.Item = MenuItem;
Menu.List = MenuList;


/*
function MenuItem(props){
    const {label, selected, onClick, item} = props;
    return <li onClick={() => onClick(item)}>{label}{selected && <b>SEL</b>}</li>
}




// exports

export default function Menu(props){
    const {children, options, value, onChange} = props;
    const css = utils.classNames("lds-menu");
    const onClick = (item) => {
        if(value !== item.value) onChange(item.value, item);
    }
    if(options) children = options.map(option => (<MenuItem label={option.label} value={option.value} selected={value === option.value} onClick={onClick} />));

    return (
        <ul className={css}>{children}</ul>
    );
}

Menu.Item = MenuItem;
*/