// NOTE: no imports here!

// configs
let CONFIGS = {
    DEBUG: true,
    DOMAIN: window?.location?.hostname,
    SALES: null, // --- config here?
}

// is demo?
if(CONFIGS.SALES === null){
    CONFIGS.SALES = CONFIGS.DOMAIN === 'demo.lumistry.com';
}

// state
const STATE = {
    domain: CONFIGS.DOMAIN,
    isSales: CONFIGS.SALES,
    sales: (value, _else) => (CONFIGS.SALES ? value : _else),
    messageAdded: false,
    isAuth: localStorage.getItem("demoAuth") === 'true',
    showModal: false,
    showMobile: false, // TEMP
    showLoader: false,
    showModalLoader: false,
    showMessagesPanel: true,
    showTasksPanel: true,
    showPatientsPanel: false,
    showSchedulePanel: false,
    pharmacyChat: 1,
    patientChat: 0,
    apptAdded: false,
    messageAdded: false,
    redirect: false,

    // context menu
    context: {id: 1, label: "Town Pharmacy - Congress Ave.",  },
    contextItems: [
        {id: 1, label: "Find a location",  icon: "search", }, 
        {divider: true, },
        {id: 2, label: "Town Pharmacy HQ",  icon: "globe", isBrand: true, }, 
        {id: 3, label: "Smithville's HQ",  icon: "globe", isBrand: true, }, 
        {id: 4, label: "Town Pharmacy - Congress Ave.", selected: true,  },
        {id: 5, label: "Smithville's - South Lamar", },
        {id: 6, label: "Town Pharmacy - UT Campus",  },
        {id: 7, label: "Smithville's - Riverside",  },
        {id: 8, label: "Smithville's - North Austin",  isAltStore: true, },
    ],

    // --- demos --- //
    
    onboard1: {
        step: 0, 
        // design
        hasLogo: false,
        hasLogo2: false,
        hasLogoInverse: false,
        bgColor: "#0063B2", // dark blue
        textColor: "#0063B2", // dark blue
    },

    onboard2: {
        username: "msmith@gmail.com",
        // password: "pw",
        // password_confirm: 
    },

    // END STATE
};


// labels
const LABELS = {
    // text
    't-submit': 'Submit',

    // info
    'i-info': 'Information',

    // affirms
    'a-success': 'Successful',
    'a-upload-completed': 'Upload completed',

    // notifications
    'n-notice': 'Notice',

    // errors
    'e-error': 'An error has occured',
    'e-value-invalid': "Value is invalid",
    'e-value-required': "Value is required",
    'e-validation-error': "Validation error",
}


// export
export {STATE, CONFIGS, LABELS, };