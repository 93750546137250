import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import "./Webapp.scss";

const debug = 0;


export default function Page(){
    

    // const onClickCTA = () => {};


    // render
    return (
        <React.Fragment>



            <Sprite.Split id="lds-webapp-topnav">
                <Sprite.Left />
                <Sprite.Right />
            </Sprite.Split>



            <div id="lds-webapp-content-outer">
                <Sprite.Container id="lds-webapp-content" className="is-cursor-text">
                    <Input.Checkbox size="lg" debug={debug} checked style={{top: 212, left: 48, width: 300, height: 80, }} />
                    <Input.Checkbox size="lg" debug={debug} style={{top: 386, left: 48, width: 300, height: 80, }} />
                    <Input.Checkbox size="lg" debug={debug} style={{top: 488, left: 48, width: 300, height: 80, }} />

                    <Sprite.Button debug={debug} style={{top: 450, left: 500, width: 70, height: 40, }} />
                    <Sprite.Button debug={debug} style={{top: 450, left: 700, width: 170, height: 40, }} />

                    <Sprite.Button debug={debug} style={{top: 710, left: 600, }} />
                    <Sprite.Button debug={debug} style={{top: 710, left: 750, }} />
                </Sprite.Container>
            </div>


            {/* <h1>Web app</h1> */}

            <div id="lds-webapp-footer-outer">
                <Sprite.Container id="lds-webapp-footer" />
            </div>


        </React.Fragment>

    )
}