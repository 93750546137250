import {Module, Table, classFields} from "../../app/classes";
import utils from "../../functions/utils";
import TaskModel from "./TaskModel";
import data from "./tasks.data";
// import reducers from "./tasks.reducers";

// create module
// const module = new Module({
//     name: "tasks", 
//     data: data, 
//     reducers: reducers,
//     table: new Table({
//         name: "tasks",
//         rowClass: TaskModel,
//     }),
// });

// const value = utils.cast("one", ":yes:no:");



class TasksModule extends Module {

    init(){
        super.init();
        this.name = "tasks";
        this.data = data;
        this.table = new Table({name: this.name,  rowClass: TaskModel, });
    }

    // ADD(state, actions){
    //     console.log("================ ADD 222: ", state);
    //     return state;
    // }

    // // EDIT(state, actions){
    // //     console.log("================ EDIT: ", state);
    // //     return state;
    // // }

    // REMOVE(state, actions){
    //     console.log("================ REMOVE: ", state);
    //     return state;
    // }

    // FIND(state, actions){
    //     console.log("================ REMOVE: ", state);
    //     return state;
    // }


    // functions
}

classFields(TasksModule, Module, {});

const module = new TasksModule();




export default module;