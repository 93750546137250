import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, useStepper, steps, } from "./Onboard2.config";
import "./Onboard2.scss";

const DEBUG = 1;


export default function Page(props){
    const {startUrl, } = useStepper();



    // render
    return (
        <Container icon222="clock" title="Welcome">
        <h1>Onboarding Completed</h1>

        <Button type="primary"  to={startUrl} label="Start over" debug={1} style={{top: 990, left: 90, width: 220,  }} />
        </Container>
    );
}
