import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import {useStepper, } from "./Onboard2.config";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import TopNav from "../../components/nav/TopNav";


// --- components --- //

function OnboardNav_Item({item, name, index, stepper, }){
    const nav = useNavigate();
    const {title, navTitle, } = item;
    const {stepIndex, steps, navSteps, baseUrl, navIndex, } = stepper;
    const label = navTitle || title;
    const first = !index;
    const last = index == (navSteps.length - 1);

    // status
    let status = "incomplete";
    if(index == navIndex) status = "current";
    else if(index < navIndex) status = "complete";

    const url = (status !== "incomplete") ? `${baseUrl}/${name}` : undefined;
    const onClick = () => {
        if(url) utils.nav(nav, url);
    }

    // render
    const css = utils.classNames("lds-onboard2-topnav-item", `is-status-${status}`, `is-index-${index}`, url && `is-clickable`, first && `is-first`, last && `is-last`);
    return (
        <div className={css} onClick={onClick}>
            <Icon type={"progress-" + status} size="lg" />
            <div className="lds-onboard2-topnav-line">
                {!first && <div className="lds-onboard2-topnav-leftline" />}
                {!last && <div className="lds-onboard2-topnav-rightline" />}
            </div>
            <div >{label}</div>
        </div>
    );
}

export function OnboardNav(){
    const stepper = useStepper();
    const {pages, stepIndex, navSteps, } = stepper;
    const {onboard2, } = useGlobals();

    // const [items, setItems] = React.useState([]);
    // React.useEffect(() => {
    //     let arr = 
    // }, []);

   //  const items = 

    // return
    return (
        <div className="lds-onboard2-topnav">
            {navSteps.map((name, index) => <OnboardNav_Item key={index} name={name} item={pages[name]} index={index} stepper={stepper} />)}
        </div>
    );
}



export function StartLoader(props){
    let {height, duration, debug, onComplete, } = props;

    if(!duration) duration = 1000;


    // render
    return  <Loader type="preload" color="white" onComplete={onComplete} duration={duration} debug={debug} style={{top: 0, left: 0, width: '100%', height: height||800,  }} />;
}



export function Container(props){
    const {name, children, title, description, icon,  } = props;
    const dispatch = useDispatch();
    const nav = useNavigate();
    const [loaded, setLoaded] = React.useState(false);
    const {stepName, startUrl, } = useStepper();

    // handlers
    const onLoaderComplete = () => setLoaded(true);
    const onLogoClick = () => {
        dispatch({call: "RESET_ONBOARD2", }); // --- WIP
        utils.nav(nav, startUrl);
    }

    // render
    const css = utils.classNames("lds-onboard1", `is-step-${stepName}`, `is-name-${stepName}`);
    return (
        <div className={css}>
            <TopNav.Basic />
            <Sprite.Button debug={0} to222={startUrl} onClick={onLogoClick} abs style={{zIndex: 10, width: 200, height: 80, position: 'fixed', }} />

            <StartLoader duration={1000} height={800} onComplete={onLoaderComplete} />

            <div className="lds-onboard1-top">
                {icon && <OnboardNav />}
            </div>

            {icon && <div className={`lds-onboard2-page-icon is-name-${stepName}`} />}
            {icon && <div className="lds-onboard2-page-title">{title}</div>}
            {description && <div className="lds-onboard2-page-description">{description}</div>}
            {false && <div className="lds-onboard2-page-line" />}

            {/* <div className="lds-onboard1-content" style={{opacity: loaded?1:0, height: loaded?'auto':600, }}>{children}</div> */}
            <div className="lds-onboard1-content" style={loaded ? {} : {opacity: 0, height: 800, overflow: 'hidden', }}>{children}</div>
        </div>
    );
}



