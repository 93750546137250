import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Button, Misc, } from "../../components/core";
import utils from "../../functions/utils";
import { useGlobals, useDispatch } from '../../app/context';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Mobile from "../../components/containers/Mobile";
import NotificationModal from "../../screens/general/NotificationModal";
import "./Messages.scss";
import Loader from "../../components/core/Loader";

const debug = 0;

// const onClickUpload = () => {

// }

// MuiDialog-container MuiDialog-scrollPaper css-hz1bth-MuiDialog-container
// class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm css-1t1j96h-MuiPaper-root-MuiDialog-paper"

export default function Screen(){
    const dispatch = useDispatch();
    const nav = useNavigate();
    const [hasKeyboard, showKeyboard] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    // const [sent, setSent] = React.useState(null);
    // const onMessageChange = (e) => setMessage(e.target.value);

    // new
    // const {patientChat} = useGlobals();
    const [patientChat, setPatientChat] = React.useState(0);
    console.log("patientChat: " + patientChat);

    const [value, setValue] = React.useState("");
    const onValueChange = (e) => {
        const messages = ["Hello, I am in the process of refilling my prescription. Can I do a 90 day supply instead of 30 day?", 0, 0, "Great, Thanks you"];
        utils.typing(e, setValue, messages, patientChat);
    }
    const onSend = () => {
        // dispatch({call: "SET_PATIENT_CHAT", value: patientChat + 1, });
        setPatientChat(patientChat + 1);
        setValue("");
    }
    // on chat count
    React.useEffect(() => {
        if(patientChat === 1) setTimeout(() => {
            // dispatch({call: "SET_PATIENT_CHAT", value: patientChat + 1, });
            setPatientChat(patientChat + 1);
        }, 3000); // delay
    }, [patientChat]);


    // handlers
    const onFocus = () => {
        showKeyboard(true);
    }
    const onBlur = () => {
        // showKeyboard(false);
    }


    // const onKeyPress = (e) => {
    //     if(e.key === 'Enter'){
    //         // showKeyboard(false);
    //         setMessage(null);
    //         setSent(true);
    //         const currentTarget = e.currentTarget;
    //         currentTarget.blur();
    //     }
    // }

    const onBack = () => {
        utils.delayRedirect(dispatch, nav, "/app/messages");
        // showKeyboard(false);
    }

    const onClickOutside = () => {
        showKeyboard(false)
    }

    const onDotsEnd = () => {
        // dispatch({call: "SET_PATIENT_CHAT", value: patientChat + 1, });
        setPatientChat(patientChat + 1);
    }

    // render
    const css = utils.classNames("lds-chat", hasKeyboard && 'is-keyboard');
    const css2 = utils.classNames("lds-chat-convo", patientChat && `is-count-${patientChat}`);
    return (
        <Mobile.Wrapper type="sub" variant="chat" noScroll toBack222="/app/messages" onBack={onBack} label="Refills - Martha Smith">

            <div className={css}>

                <div className="lds-chat-top" onClick={onClickOutside}>
                    <div className={css2}>
                    {/* <Misc.ChatDots isPhone style={{top: 131, left: 16, }} duration={0} />
                    <Misc.ChatDots isPhone style={{top: 358, left: 16, }} duration={0} /> */}
                    {(patientChat === 2) && <Misc.ChatDots phone style={{top: 358, left: 16, }} duration={5000} onComplete={onDotsEnd} />}

                    </div>
                </div>
                <div className="lds-chat-bottom">
                    <Loader type="preload" duration={800} debug22 style={{height: 630, }} />
                    <Sprite.Container id="lds-messages-chat-input">
                        <Input label="Secure message..." onEnter={onSend} onChange={onValueChange} value={value} onFocus={onFocus} onBlur={onBlur} sprite standard size="sm" style={{top: 18, left: 57, width: 270, }} />
                    </Sprite.Container>
                    <Sprite.Keyboard />
                </div>
            </div>


        </Mobile.Wrapper>
    );
}
