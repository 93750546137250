import React from "react";
import {Sprite, Select, Alert} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Modal from "../../components/containers/Modal";

const DEBUG = 0;

export default function Modal2(props){
    const dispatch = useDispatch();
    const [formError, setFormError] = React.useState(null);

    utils.showError("yooooo 333", formError);

    const onOK = (e) => {
        e.preventDefault();
        // console.log("++++++++++ onOK");
        // dispatch({call: "HIDE_MODAL"});

        dispatch({call: "SHOW_MODAL_LOADER", });
        setTimeout(() => {
            dispatch({call: "HIDE_MODAL_LOADER", });
            dispatch({call: "HIDE_MODAL"});
        }, 0); // --- WIP
    }

    return (
        <Modal size="lg" label="New Chat Message" buttons={{ok: onOK}} message={formError}>
            
            <Sprite.Container id="lds-messages-chat-modal">
                 
                <Sprite.Button debug={DEBUG} style={{top: 0, left: 0, width: 220, height: 30, }} className="is-cursor-text" />
                <Sprite.Button debug={DEBUG} style={{top: 40, left: 0, width: 220, height: 400, }} />
                <Sprite.Button debug={DEBUG} style={{top: 0, left: 240, width: 530, height: 30, }} className="is-cursor-text" />
                <Sprite.Button debug={DEBUG} style={{top: 0, left: 240, width: 530, height: 50, }} className="is-cursor-text" />
                <Sprite.Button debug={DEBUG} style={{top: 80, left: 240, width: 530, height: 50, }} className="is-cursor-text" />
                <Sprite.Button debug={DEBUG} style={{top: 160, left: 240, width: 530, height: 190, }} className="is-cursor-text" />

            </Sprite.Container>
        </Modal>
    );
}

