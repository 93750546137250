import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import "./Forms.scss";

const debug = 0;


export default function Page(){

    const onCTA = () => {};


    // render
    return (
        <React.Fragment>

            {/* {false && <Loader type="preload" duration={2000} onComplete={onLoaderComplete} style={{top: 70, left: 280, width: 1020, height: 680,  }} />} */}

            <Layout.WithPanel showPanel={false}>
                <Layout.Sidenav spriteId="lds-forms-sidenav">

                    <Layout.CTA to="/forms/editor" debug={debug} />
                    <Layout.Selection label="Submission" style={{top: 91, }} />
                    <Layout.Item to="/forms/forms/" style={{top: 130, }} />

                </Layout.Sidenav>
                <Layout.Content>


                    <Sprite.Split id="lds-forms-details">
                        <Sprite.Left>

                            <Sprite.Button debug={debug} to="/forms" style={{width: 200, top: 20, }} />

                        </Sprite.Left>
                        <Sprite.Right>
                        </Sprite.Right>
                    </Sprite.Split>

                </Layout.Content>
            </Layout.WithPanel>




        </React.Fragment>

    )
}