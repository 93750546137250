import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Loader from "../../components/core/Loader";
import "./Auto.scss";

const debug = 0;


// --- main --- //

export default function Screen(){
    return (
        <React.Fragment>

            <Loader type="preload" color="translucent" debug={debug} duration={2000} style={{top: 70, left: 24, width: 1000, height: 560, }} />

            <Sprite.Container id="lds-auto" style={{left: 24, }}>

                        <Input.Toggle on sprite style={{top: 89, left: 441, }} />
                        <Input.Toggle on sprite style={{top: 229, left: 441, }} />
                        <Input.Toggle on sprite style={{top: 369, left: 441, }} />
                        <Input.Toggle on sprite style={{top: 509, left: 441, }} />
                        <Input.Toggle on sprite style={{top: 89, left: 948, }} />
                        <Input.Toggle on sprite style={{top: 229, left: 948, }} />
                        <Input.Toggle on sprite style={{top: 369, left: 948, }} />
                        <Input.Toggle on sprite style={{top: 509, left: 948, }} />
            </Sprite.Container>


        </React.Fragment>



    );
}
