import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import "./General.scss";

const debug = 0;

const items = {
    'refillready': {id: "lds-app-push-notifications", to: "/app/login?bypass=refillready", },
}

// useParams

export default function Screen(props){
    const {type} = props;
    let {id, to} = items[type] || items['refillready'];

    // render
    return (
        <Sprite.Container id="lds-app-push-bg" debug={debug} >
            <Sprite.Button debug={debug} id={id} to={to} style={{top: 250, }} />
        </Sprite.Container>
    );
}

