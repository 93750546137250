import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import "./Forms.scss";

const debug = 0;

export default function Page(){

    const onCTA = () => {
        console.log("IT WORKS");
    }

    // render
    return (
        <React.Fragment>
            <Layout.WithPanel showPanel={false}>
                <Layout.Sidenav spriteId="lds-forms-sidenav">

                    <Layout.CTA to="/forms/editor" debug={debug} />
                    <Layout.Item to="/forms/" style={{top: 91, }} />
                    <Layout.Selection label="Forms" style={{top: 130, }} />

                </Layout.Sidenav>
                <Layout.Content>

                    <Sprite.Split id="lds-forms-forms">
                        <Loader abs debug={debug} type="preload" color="translucent" duration={1000} style={{top: 70, height: 610,  }} />
                        <Sprite.Button abs debug={debug} to="/forms/editor/" style={{top: 110, width: '100%', height: 260, }} />
                        <Sprite.Button abs debug={debug} to="/forms/editor/" style={{top: 510, width: '100%', height: 170, }} />
                        <Sprite.Left />
                        <Sprite.Right />
                    </Sprite.Split>

                </Layout.Content>
            </Layout.WithPanel>
        </React.Fragment>

    )

}