import React from "react";
import utils from "../../functions/utils";
import Sprite from "../../components/core/Sprite";
import { useLocation } from 'react-router-dom';
import "./Email.scss";

const debug = 0;

export default function Email(props){
    const {children, title, spriteId, } = props;
    // return <div>EMAIL</div>;


    return (
        <Sprite.Split className="lds-email" debug={debug} >
            <Sprite.Left>
                <div className="lds-email-title">{title}</div>
                <div className="lds-email-content">
                    <Sprite.Container id={spriteId}>{children}</Sprite.Container>
                </div>
            </Sprite.Left>
            <Sprite.Right />
        </Sprite.Split>
    );
}
