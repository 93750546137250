import * as React from 'react';
import utils from "../../functions/utils";
import { BrowserRouter as Router, Route, Routes, useLocation, Link, useNavigate } from 'react-router-dom';
import "./Sprite.scss";


function SpriteRows(props){
    const {count, selection, onChange, onSame, onClick, abs, debug, style, id, } = props;
    const [items, setItems] = React.useState([]);
    // let items = [];


    const onClick2 = (i) => {
        if(selection === i) (onSame && onSame(i));
        else if(onChange) onChange(i);
        if(onClick) onClick(i);
    }

    React.useEffect(() => {
        let items2 = [];
        for(var i=0; i<count; i++) items2.push(<SpriteRow key={i} index={i} alt={!(i%2)} selected={selection == i} debug={debug} onClick={onClick2} />); 
        setItems(items2);
    }, [selection]);

    // render
    const css = utils.classNames('lds-spriterows', abs && 'is-abs', debug && 'is-debug');
    return (
        <div className={css} style={style} id={id}>{items}</div>
    );
}

function SpriteRow(props){
    const {gray, selected, onClick, index, alt, } = props;
    let props2 = {...props};
    delete props2.alt;

    const onClick2 = () => {
        if(onClick) onClick(index);
    }

    // render
    const css = utils.classNames('lds-spriterow', gray && 'is-gray', selected && 'is-selected', alt && 'is-alt');
    return <SpriteButton className={css} {...props2} onClick={onClick2} />;
}

function SpriteContainer(props){
    return <SpriteBase baseClass="lds-spritecontainer" {...props} />;
}

function SpriteSidenav(props){
    const {onCTA, children, debug} = props;

    // has cta?
    if(onCTA) return (
        <SpriteBase baseClass="lds-spritesidenav" {...props} >
            <SpriteButton className="is-sidenav" onClick={onCTA} debug={debug} />
            {children}
        </SpriteBase>
    );

    // no cta..
    return <SpriteBase baseClass="lds-spritesidenav" {...props} />
}
function SpriteSidenavButton(props){
    return <SpriteBase baseClass="lds-spritebutton lds-spritesidenav-button" {...props} />;
}

function SpriteButton(props){
    return <SpriteBase baseClass="lds-spritebutton" {...props} />
}

function SpriteLikeText(props){
    return <SpriteBase baseClass="lds-spriteliketext" {...props} />
}

function SpriteMiddle(props){
    return <SpriteBase baseClass="lds-spritemiddle" {...props} tag="td" />;
}

function SpriteSplit(props){
    return <SpriteBase baseClass="lds-spritesplit" {...props} />;
}
function SpriteLeft(props){
    return <SpriteBase baseClass="lds-spriteleft" {...props} />;
}
function SpriteRight(props){
    return <SpriteBase baseClass="lds-spriteright" {...props} />;
}


// function SpriteSplit222(props){
//     const {children} = props;
//     let props2 = {...props};
//     delete props2.children;
//     return <SpriteBase baseClass="lds-spritesplit" {...props} tag="table"><tbody><tr>{children}</tr></tbody></SpriteBase>
// }




// --- base --- //


function SpriteBase(props) {
    var props2 = {...props};
    const {baseClass, className, debug, tag, delay, to, abs, } = props;
    const [show, setShow] = React.useState(!delay);
    const nav = useNavigate();

    // to
    if(to) props2.onClick = () => utils.nav(nav, to);

    // init
    React.useEffect(() => {
        if(delay) setTimeout(() => setShow(true), delay);
    }, []);
    
    
    // remove
    delete props2['baseClass'];
    delete props2['type'];
    delete props2['debug'];
    delete props2['abs'];
    delete props2['className'];

    // css
    const css = utils.classNames(baseClass ? `${baseClass} lds-spritebase` : 'lds-sprite', debug && 'is-debug', abs && 'is-abs', tag && `is-tag-${tag}`, className);

    // render
    if(show === false) return;
    switch(tag){
      case "table":    return <table {...props2} className={css} />;
      case "tr":    return <tr {...props2} className={css} />;
      case "td":    return <td {...props2} className={css} />;
      case "span":    return <span {...props2} className={css} />;
      case "input":   return <input type="text" {...props2} className={css} />;
      case "link":    return <Link {...props2} className={css} />;
      default:        return <div {...props2} className={css} />;
    }
}


// exports

export default function Sprite (props) {
    return <SpriteBase {...props} />
}

Sprite.Base = SpriteBase;
Sprite.Container = SpriteContainer;
Sprite.Sidenav = SpriteSidenav;
Sprite.SidenavButton = SpriteSidenavButton;
Sprite.Button = SpriteButton;
Sprite.Middle = SpriteMiddle;
Sprite.LikeText = SpriteLikeText;
Sprite.Split = SpriteSplit;
Sprite.Left = SpriteLeft;
Sprite.Right = SpriteRight;
Sprite.Row = SpriteRow;
Sprite.Rows = SpriteRows;




// --- advanced --- //

function SpriteKeyboard(props){
    const {type, className} = props;
    delete props['type'];

    // render
    const css = utils.classNames("lds-sprite-keyboard", className, type && `is-type-${type}`);
    return <SpriteContainer className={css} {...props} />
}


Sprite.Keyboard = SpriteKeyboard;
