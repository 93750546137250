import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import TopNav from "../../components/nav/TopNav";


import {FilePickerModal} from "../ui/FilePickerModal";
import ColorPickerPopover from "../ui/ColorPickerPopover";
import DesignPage from "./Onboard1_DesignPage";
// import ChatModal from "./MessagesChatModal";
import "./Onboard1.scss";



const debug = 0;


const steps = ["start", "welcome", "end",  ];

let LOCALS = {
    step: undefined,
    stepName: undefined,
}


// functions

function getStepParam(location){
    const urlPath = location?.pathname;
    let step = urlPath?.split('/').pop() || 0;
    step = parseInt(step);
    return isNaN(step) ? 0 : step;
}

function loadLocals(location){
    if(LOCALS.step !== undefined) return LOCALS;

    const urlPath = location?.pathname;
    const stepName = LOCALS.stepName = urlPath?.split('/').pop() || "";
    const step = LOCALS.step = steps.indexOf(stepName);

    return LOCALS;
}


// --- components --- //


// function StepButton(props){
//     let {label, step, next, back, offset = 1, style, id, } = props;
//     const {onboard1, } = useGlobals();

//     if(isNaN(step)){
//         step = onboard1.step;
//         if(next) offset = 1;
//         else if(back) offset = -1;
//         step += offset;
//     }

//     const to = "/demos/onboard1/" + steps[step];
//     if(!label) label = (offset > 0) ? "Next" : "Back";

//     // render
//     return <Sprite.Button debug={debug} style={style} to={to} label={label} />;
// }

export function ProgressBar(props){
    const {step, } = props;
    const css = utils.classNames("lds-demos-progressbar", step && `is-step-${step}`);
    return <div className={css} />;
}

export function Container(props){
    const {name, children, title, description, icon, step,  } = props;
    // const {onboard1, } = useGlobals();
    // const {step, } = onboard1;
    // const dispatch = useDispatch();
    const location = useLocation();
    // const step = 2;

    React.useEffect(() => {

        const urlPath = location?.pathname;
        const stepName = LOCALS.stepName = urlPath?.split('/').pop() || "";
        const step = LOCALS.step = steps.indexOf(stepName);
        // const step = steps.find(stepName);

        console.log("========== STEP: stepName: " + stepName + ", step: " + step + ", urlPath: " + urlPath);

        // dispatch({call: "SET_ONBOARD1_VALUES", values: {step: 1, }, });
    }, []);

    // render
    const css = utils.classNames("lds-onboard1", `is-step-${LOCALS.step}`, `is-name-${name}`);
    return (
        <div className={css}>
            <TopNav.Basic />
            <div className="lds-onboard1-top">
                {!isNaN(step) && <ProgressBar step={step} />}
                {icon && <div className={`lds-onboard1-icon is-type-${icon}`} />}
                {title && <div className="lds-onboard1-title">{title}</div>}
                {description && <div className="lds-onboard1-description">{description}</div>}
            </div>
            <div className="lds-onboard1-content">{children}</div>
        </div>
    );
}


export function StepButton(props){
    const {style, step, debug, } = props;
    const dispatch = useDispatch();
    const onClick = () => dispatch({call: "SET_ONBOARD1_STEP", value: step, });
    // onClick333={onClick} 

    // render
    return <Sprite.Button to={`/demos/onboard1/${step}`} debug={debug} style={style} />;
}



export function ButtonGroup(props){
    const {style, nextOnly, debug, } = props;
    const location = useLocation();
    const step = getStepParam(location);
    // const {onboard1, } = useGlobals();
    // const {step, } = onboard1;

    const css = "lds-onboard1-buttons";

    if(nextOnly) return (
        <Sprite.Container debug={debug} style={style} className={css} >
            <StepButton step={step+1} debug={debug} style={{width: '100%', }} />
        </Sprite.Container>
    );

    // render
    return (
            <Sprite.Container debug={debug} style={style} className={css} >
                <StepButton step={step-1} debug={debug} style={{width: '50%', }} />
                <StepButton step={step+1} debug={debug} style={{width: '50%', left: '50%', }} />
            </Sprite.Container>
    );
}


export function StartLoader(props){
    const {height, duration, } = props;

    // render
    return  <Loader type="preload" color="white" duration={duration||1000} debug={debug} style={{top: 0, left: 0, width: '100%', height: height||800,  }} />;
}

export function EndLoader(props){
    let {next, ending, height, duration, } = props;
    const nav = useNavigate();
    const onComplete = () => nav(next);
    
    // render
    if(!ending) return;
    return <Loader type="preload" color="translucent" onComplete={onComplete} duration={duration||800} debug={debug} style={{top: 0, left: 0, width: '100%', height: height||800,  }} />;
}

