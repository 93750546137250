import { createContext, useContext, useReducer } from 'react';
import {STATE} from "./settings";
import reducers from './reducers';




function reducer(state, action){
  const {call, onComplete, onError, onSuccess} = action;
  let newState = {...state};

  // invalid call?
  if(!reducers[call]){ // yes
    console.warn("---- REDUCER NOT FOUND: ", call);
    return newState;
  }
  
  // try
  try {
    // call reducer func
    // console.warn("---- REDUCER 222: ", state, "ACTION: ", action);

    newState = reducers[call](state, action);
    if(onSuccess) onSuccess(state, action);
    
  } catch (e) {
    // error
    if(onError) onError(e, state, action);
    else {
      console.warn("==== UNCAUGHT REDUCER ERROR: ", e.getCode ? e.getCode() : e);
      throw e;
    };
  }

  // call handler
  if(onComplete) onComplete(state, action);
  
  // return
  return newState;
}


const GlobalContext = createContext(null);
const GlobalsDispatchContext = createContext(null);

// provider
export function GlobalsProvider({ children }) {
  const [tasks, dispatch] = useReducer(reducer, STATE);

  return (
    <GlobalContext.Provider value={tasks}>
      <GlobalsDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalsDispatchContext.Provider>
    </GlobalContext.Provider>
  );
}

export function useGlobals() {
  return useContext(GlobalContext);
}

export function useDispatch() {
  return useContext(GlobalsDispatchContext);
}
