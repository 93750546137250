import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, } from "./Onboard1_Comps";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import FilePickerModal from "../ui/FilePickerModal";
import ColorPickerPopover from "../ui/ColorPickerPopover";
import "./Onboard1.scss";




const debug = 0;



function PhoneTopShadow(props){
    let {style, id, } = props;
    return <div className="lds-onboard1-phonetop-shadow" id={id} style={style}></div>;
}



function PhoneTop(props){
    let {bgColor, textColor, title, style, id, className, type, loaded, } = props;
    if(!bgColor) bgColor = "#ccc";
    if(!textColor) bgColor = "#ccc";
    if(!title) title = "Pharmacy name";

    // render
    const css = utils.classNames("lds-onboard-phonetop", className, type && `is-type-${type}`, loaded && 'is-loaded');
    return (
        <div className={css} id={id} style={style}>

            <div className="lds-onboard-phonetop-bgcolor" style={{backgroundColor: bgColor, }} />
            <div className="lds-onboard-phonetop-tabline" style={{backgroundColor: bgColor, }} />
            <div className="lds-onboard-phonetop-tabtext" style={{color: textColor, }}  >Upcoming</div>
            <div className="lds-onboard-phonetop-name" />
            <div className="lds-onboard-phonetop-image" />
            <div className="lds-onboard-phonetop-title" >{title}</div>

        </div>
    );
}



// --- PAGE --- //

export default function Page(props){
    const dispatch = useDispatch();
    const globals = useGlobals();
    const {bgColor, textColor, hasLogo, hasLogoInverse, } = globals.onboard1;
    const {step, } = props;
    const [value, setName] = React.useState("Town Pharmacy");
    const [colorToggle, toggleColor] = React.useState(true); // blue
    const [showLogoLoader, setLogoLoader] = React.useState(false);
    const [showLogoLoader2, setLogoLoader2] = React.useState(false); // logo on dark
    const [carousel, setCarousel, reversed] = React.useState([0, 99, false]);
    const carouselCount = 3;


    // show logo loader
    React.useEffect(() => {
        if(!hasLogo) return;
        setLogoLoader(true);
        setTimeout(() => setLogoLoader(false), 2000);
    }, [hasLogo]);
    
    // show logo loader (inverse)
    React.useEffect(() => {
        if(!hasLogoInverse) return;
        setLogoLoader2(true);
        setTimeout(() => setLogoLoader2(false), 2000);
    }, [hasLogoInverse]);


    // POPOVER
    const [anchorEl, setAnchorEl] = React.useState(null); // must be before if statement
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const p = {ao: {vertical: 'bottom', horizontal: 'right', }, to: {vertical: 'top', horizontal: 'right', }}; 
    // /POPOVER


    // add/remove scroll handler
    React.useEffect(() => {
        const scrollHandler = () => setAnchorEl(null);
        document.addEventListener("scroll", scrollHandler);
        return () => {
            document.removeEventListener("scroll", scrollHandler);
        }
    }, []);

    // show logo loader
    React.useEffect(() => {
        if(!hasLogo) return;
        setLogoLoader(true);
        setTimeout(() => setLogoLoader(false), 2000);
    }, [hasLogo]);

    


    const onColorClick = (value) => {
        toggleColor(!colorToggle);
        const values = {bgColor: value, textColor: value, };
        dispatch({call: "SET_ONBOARD1_VALUES", values: values, });
        handleClose();
        // setAnchorEl(null); // close popover
    }

    const onChange = (e) => setName(e.target.value);

    // logo
    const onWhiteLogo = () => dispatch({call: "SHOW_MODAL", component: <FilePickerModal onSave={onSaveWhiteLogo} />});
    const onSaveWhiteLogo = () => {
        dispatch({call: "SET_ONBOARD1_VALUES", values: {hasLogo: true, }, });
        dispatch({call: "HIDE_MODAL", });
    }
    const onWhiteLogoDelete = () => {
        setLogoLoader(true);
        setTimeout(() => {
            setLogoLoader(false);
            dispatch({call: "SET_ONBOARD1_VALUES", values: {hasLogo: false, }, });
        }, 1000);
    }

    // logo inverse
    const onLogoInverse = () => dispatch({call: "SHOW_MODAL", component: <FilePickerModal onSave={onSaveLogoInverse} />});
    const onSaveLogoInverse = () => {
        dispatch({call: "SET_ONBOARD1_VALUES", values: {hasLogoInverse: true, }, });
        dispatch({call: "HIDE_MODAL", });
    }
    const onLogoInverseDelete = () => {
        setLogoLoader2(true);
        setTimeout(() => {
            setLogoLoader2(false);
            dispatch({call: "SET_ONBOARD1_VALUES", values: {hasLogoInverse: false, }, });
        }, 1000);
    }



    // buttons
    const onCarouselBack = () => {
        // onCarouselNext(true);
    }
    const onCarouselNext = (reversed = false) => {
        const dir = reversed ? -1 : 1;
        const cur = carousel[0];
        let sel = cur + dir;
        if(sel === carouselCount) sel = 0;
        if(sel < 0) sel = carouselCount - 1;
        setCarousel([sel, cur, reversed]);
    }




    // render
    const css2 = utils.classNames("lds-design-carousel is-in-" + carousel[0] + " is-out-" + carousel[1], carousel[2] && 'is-reversed');
    return (
        <Container step={step} icon="clock" title="Design">


            <Sprite.Container className={css2} style={{width: 710, height: 310, marginBottom: 30, }} debug={debug}>

                <Sprite.Button className="lds-design-carousel-button is-back" debug={debug} style={{top: 140, left: 10, zIndex: 2, }} onClick={() => onCarouselBack()} />
                <Sprite.Button className="lds-design-carousel-button is-next" debug={debug} style={{top: 140, left: 'auto', right: 10, zIndex: 2, }} onClick={() => onCarouselNext()} />

                <PhoneTop type="home" loaded={hasLogoInverse} className={"lds-design-carousel-item is-index-0"} debug={debug} bgColor={bgColor} textColor={textColor} title={value}  />
                <PhoneTop type="welcome" loaded={hasLogo} className="lds-design-carousel-item is-index-1" debug={debug} bgColor={bgColor} textColor={textColor} title={value}  />
                <PhoneTop type="internal" className="lds-design-carousel-item is-index-2" debug={debug} bgColor={bgColor} textColor={textColor} title={value}  />

                <PhoneTopShadow style={{top: 'auto', bottom: 0, left: -40, zIndex: 2, }} />

            </Sprite.Container>


            <Sprite.Container debug={debug} id="lds-onboard1-design-form">

                <Input sprite debug={debug} onChange={onChange} value={value} style={{top: 3, left: 9, width: 327, }} label="Display name" />

                <Sprite.Button debug={0} id="lds-onboard1-design-button"  onClick={handleClick} style={{top: 0, left: 370, backgroundColor: bgColor, }} >Select color</Sprite.Button>
                <ColorPickerPopover id={id} open={open} anchorEl={anchorEl} onClick={onColorClick} value={bgColor} onClose={handleClose} anchorOrigin={p.ao} transformOrigin={p.to} />

                {/* logo */}
                <Sprite.Button debug={debug} onClick={onWhiteLogo} style={{top: 110, left: 0, width: 340, height: 90, }} />
                {hasLogo && <Sprite.Container id="lds-onboard1-design-logo" style={{top: 74, left: 0, }} />}
                {hasLogo && <Sprite.Button debug={debug} onClick={onWhiteLogoDelete} style={{top: 65, left: 320, width: 40, height: 40, zIndex: 2, }} />}
                {showLogoLoader && <Loader type="preload" duration={2000} style={{top: 110, left: 3, width: 340, height: 84,  }} />}


                {/* logo inverse */}
                <Sprite.Button debug={0} onClick={onLogoInverse} style={{top: 250, left: 0, width: 340, height: 90, }} />
                {hasLogoInverse && <Sprite.Container id="lds-onboard1-design-logo-inverse" style={{top: 217, left: 0, }} />}
                {hasLogoInverse && <Sprite.Button debug={debug} onClick={onLogoInverseDelete} style={{top: 207, left: 320, width: 40, height: 40, zIndex: 2, }} />}
                {showLogoLoader2 && <Loader type="preload" color="dkgray" duration={2000} style={{top: 257, left: 3, width: 340, height: 84,  }} />}


                {/* logo inverse */}
                {/* <Sprite.Button debug={1} onClick={onLogoInverse} style={{top: 110, left: 370, width: 340, height: 90, }} />
                {showLogoLoader2 && <Loader type="preload" duration={2000} style={{top: 110, left: 200, width: 340, height: 84,  }} />} */}


                {/* bottom buttons */}
                <ButtonGroup style={{bottom: 0, left: 180, }}  />

            </Sprite.Container>
            
        

        </Container>
    );
}
// --- WIP

