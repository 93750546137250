import Sprite from "../../components/core/Sprite";
import utils from "../../functions/utils";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import "./Meds.scss";

const debug = 0;

function Tab(props){
    const {name, to} = props;
    return <Sprite.Button to={to} className={'is-name-' + name} debug={debug} />;
}

export default function Tabs(props){
    let {tab} = props;
    const location = useLocation();
    const parts = location?.pathname?.split('/');

    // tab
    if(!tab) tab = parts[3];
    if(!tab || tab === '') tab = 'home';

    // render
    const css = utils.classNames(tab && `is-tab-${tab}`);
    return (
        <Sprite.Container id="lds-app-meds-tabs" className={css}>
            <div id="lds-app-meds-tabs-inner">
                <Tab name="home" to="/app/meds" />
                <Tab name="orders" to="/app/meds/orders" />
                <Tab name="reminders" to="/app/meds/reminders" />
            </div>
        </Sprite.Container>

    );
}