import React from "react";
import utils from "../../functions/utils";
import {Sprite,  SpriteText, Icon} from "../../components/core";
// import {Layout} from "../components";
import "./Layout.scss";
import TopNav from "../nav/TopNav";


function Div(props){
    let {baseClass, className, children, id, noPadding, style, spriteId} = props;
    const css = utils.classNames(baseClass, className, noPadding && 'is-nopadding');
    if(spriteId) children = <Sprite.Container id={spriteId}>{children}</Sprite.Container>
    return (<div id={id} className={css} style={style}>{children}</div>);
}


// new
function LayoutWithPanel(props){
    const {className, children, id, showPanel} = props;
    const css = utils.classNames("lds-layout-withpanel", className, showPanel && 'is-showpanel');
    return (<div id={id} className={css}>{children}</div>);
}

function Content(props){
    return <Div baseClass="lds-layout-content" {...props} />;
}

function Panel(props){
    const {className, children, id, showPanel, onClickClose} = props;
    return (
        <Div baseClass="lds-layout-panel" {...props}>
            <div className="lds-layout-panel-closer" onClick={onClickClose} />
            {children}
        </Div>
    );
}

function Sidenav(props){
    const {settings, } = props;
    const css = utils.classNames("lds-layout-sidenav-outer", settings && 'is-settings');
    const css2 = utils.classNames("lds-layout-sidenav", settings && 'is-settings');
    return (
        <div className={css}>
            <Div baseClass={css2} {...props}>{props.children}</Div>
        </div>
    );
}

function CTA(props){
    return <Sprite.Button className="lds-layout-sidenav-cta" {...props} />
}

function Selection(props){
    const {label, } = props;
    return <Sprite.Button className="lds-layout-sidenav-selection" {...props}><span>{label}</span></Sprite.Button>;
}
function Item(props){
    const {selected, label, style, to, } = props;
    if(selected) return <Selection label={label} to={to} style={style} />
    return <Sprite.Button to={to} className="lds-layout-sidenav-item" {...props} />;
}

function Hotspot(props){
    return <Sprite.Button className="lds-layout-sidenav-hotspot" {...props} />;
    // const {top = 100, height = 100 } = props;
    // return <Sprite.Button className="lds-layout-sidenav-hotspot" {...props}  style={{top: top, height: height, }}/>
}


// old

function Cell(props){
    const {baseClass, className, children} = props;
    // const css = utils.classNames(baseClass + "lds-layout-left is-size-" + size + " is-type-" + type, primary && "is-primary", disabled && "is-disabled", className);
    const css = utils.classNames(baseClass, className);
    return (<td className={css}>{children}</td>);
}

function Left(props){
    return <Cell baseClass="lds-layout-left" {...props} />
}
function Main(props){
    return <Cell baseClass="lds-layout-main" {...props} />
}
function Right(props){
    return <Cell baseClass="lds-layout-right" {...props} />
}

// --- exports --- //

function ThreeColumns(props){
    const {baseClass, className, children, tab, sidenav, panel} = props;
    const css = utils.classNames("lds-layout-3col", className);
    const colspn = panel ? 3 : 2;
    return (
        <div className={css}>
            <table>
                <tbody>
                    {/* <tr className="lds-layout-top">
                        <td colSpan={colspn}><TopNav tab="messages" /></td>
                    </tr> */}
                    <tr>
                        <Left>{sidenav}</Left>
                        <Main>{children}</Main>
                        {panel && <Right>{panel}</Right>}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default function Layout(props) {
    switch(props.type){
        default:
        case "threecolumns": return <Layout.ThreeColumns {...props} />;
    }
}

Layout.ThreeColumns = ThreeColumns;
Layout.Left = Left;
Layout.Right = Right;
Layout.Main = Main;

// new
Layout.WithPanel = LayoutWithPanel;
Layout.Content = Content;
Layout.Panel = Panel;
Layout.Sidenav = Sidenav;
Layout.CTA = CTA;
Layout.Selection = Selection;
Layout.Item = Item;
Layout.Hotspot = Hotspot;