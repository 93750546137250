import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, } from "./Onboard1_Comps";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import FilePickerModal from "../ui/FilePickerModal";
import ColorPickerPopover from "../ui/ColorPickerPopover";
import "./Onboard1.scss";




const debug = 0;



function PhoneTopShadow(props){
    let {style, id, } = props;
    return <div className="lds-onboard1-phonetop-shadow" id={id} style={style}></div>;
}



function PhoneTop(props){
    let {bgColor, textColor, title, style, id, className, type, loaded, } = props;
    if(!bgColor) bgColor = "#ccc";
    if(!textColor) bgColor = "#ccc";
    if(!title) title = "Pharmacy name";

    // render
    const css = utils.classNames("lds-onboard-phonetop", className, type && `is-type-${type}`, loaded && 'is-loaded');
    return (
        <div className={css} id={id} style={style}>

            <div className="lds-onboard-phonetop-bgcolor" style={{backgroundColor: bgColor, }} />
            <div className="lds-onboard-phonetop-tabline" style={{backgroundColor: bgColor, }} />
            <div className="lds-onboard-phonetop-tabtext" style={{color: textColor, }}  >Upcoming</div>
            <div className="lds-onboard-phonetop-name" />
            <div className="lds-onboard-phonetop-image" />
            <div className="lds-onboard-phonetop-title" >{title}</div>

        </div>
    );
}


function Graphic(props){
    const {style, debug, className, type, loaded, } = props;

    // render
    const css = utils.classNames("lds-onboard1-design-graphics", className, type && `is-type-${type}`, loaded && 'is-loaded');
    return <Sprite.Container className={css} debug={debug} style={style} />;
}


// --- PAGE --- //

export default function Page(props){
    const dispatch = useDispatch();
    const globals = useGlobals();
    const {bgColor, textColor, hasLogo, hasLogo2, hasLogoInverse, } = globals.onboard1;
    const {step, } = props;
    const [value, setName] = React.useState("Town Pharmacy");
    const [colorToggle, toggleColor] = React.useState(true); // blue
    const [showLogoLoader, setLogoLoader] = React.useState(false);
    const [showLogoLoader2, setLogoLoader2] = React.useState(false); // logo on dark
    const [carousel, setCarousel, reversed] = React.useState([0, 99, false]);
    const carouselCount = 3;
    const offset = -24;

    // POPOVER
    const [anchorEl, setAnchorEl] = React.useState(null); // must be before if statement
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const p = {ao: {vertical: 'bottom', horizontal: 'right', }, to: {vertical: 'top', horizontal: 'right', }}; 
    // /POPOVER

    // name / color
    const onChange = (e) => setName(e.target.value);
    const onColorClick = (value) => {
        toggleColor(!colorToggle);
        const values = {bgColor: value, textColor: value, };
        dispatch({call: "SET_ONBOARD1_VALUES", values: values, });
        handleClose();
        // setAnchorEl(null); // close popover
    }

    // logo
    const onLogoDropzone = () => {
        dispatch({call: "SHOW_MODAL", component: <FilePickerModal onSave={onSaveLogo} />});
    }
    const onSaveLogo = () => {
        setLogoLoader(true);
        setTimeout(() => {
            setLogoLoader(false);
            dispatch({call: "SET_ONBOARD1_VALUES", values: {hasLogo: true, }, });
        }, 1000);
        dispatch({call: "HIDE_MODAL", });
    }

    // logo 2
    const onLogoDropzone2 = () => {
        dispatch({call: "SHOW_MODAL", component: <FilePickerModal onSave={onSaveLogo2} />});
    }
    const onSaveLogo2 = () => {
        dispatch({call: "SET_ONBOARD1_VALUES", values: {hasLogo2: true, }, });
        // setLogoLoader2(true);
        // setTimeout(() => {
        //     setLogoLoader2(false);
        //     dispatch({call: "SET_ONBOARD1_VALUES", values: {hasLogo2: true, }, });
        // }, 1000);
        dispatch({call: "HIDE_MODAL", });
    }
    


    // const onLogoDropzone = () => {};
    const onInvLogoDropzone = () => {};
    const onIconDropzone = () => {};

    const dropstyle = {top: 24, left: 20, width: 590, height: 90, };

    // render
    return (
        <Container step={step} icon="clock" title="Design">


            <Sprite.Container debug={debug} id="lds-onboard1-design-form2">


                {/* NAME */}
                <Sprite.Container debug={1} style={{top: 77, height: 390, width: '100%', }}>

                    <Input sprite debug={debug} onChange={onChange} value={value} style={{top: 40, left: 46, width: 327, }} label="Display name" />
                    
                    <Sprite.Button debug={0} id="lds-onboard1-design-button"  onClick={handleClick} style={{top: 38, left: 454, width: 139, backgroundColor: bgColor, }} >Select color</Sprite.Button>
                    <ColorPickerPopover id={id} open={open} anchorEl={anchorEl} onClick={onColorClick} value={bgColor} onClose={handleClose} anchorOrigin={p.ao} transformOrigin={p.to} />

                    <PhoneTop type="internal" className="lds-design-carousel-item1" debug={debug} style={{top: 130, left: 107, height: 259, }} bgColor={bgColor} textColor={textColor} title={value} />

                </Sprite.Container>


                {/* LOGO */}
                <Sprite.Container debug={1} style={{top: 989 + offset, height: 427, width: '100%', overflow: 'hidden', }}>
                    
                    <Sprite.Button debug={debug} onClick={onLogoDropzone} style={dropstyle} />

                    {showLogoLoader && <Loader type="preload" color="logobox1" duration={20000} style={{top: 216, left: 183, width: 260, height: 84,  }} />}
                    <Graphic type={hasLogo ? "logo" : "box"} debug={debug} style={{top: 193, left: 155, zIndex: 5, }} />
                    
                    <PhoneTop type="welcome" className="lds-design-carousel-item1" debug={debug} style={{top: 139, left: 107, height: 299, }} bgColor={bgColor} textColor={textColor} title={value} />

                </Sprite.Container>


                {/* LOGO 2 */}
                <Sprite.Container debug={debug} style={{top: 1574 + offset, height: 434, width: '100%', overflow: 'hidden',}}>
                    
                    <Sprite.Button debug={debug} onClick={onLogoDropzone2} style={dropstyle} />

                    {/* {showLogoLoader2 && <Loader type="preload" color="logobox2" duration={200000} style={{top: 266, left: 183, width: 260, height: 84, }} />} */}
                    <Graphic type={hasLogo2 ? "invlogo" : "invbox"} debug={debug} style={{top: 243, left: 155, zIndex: 5, }} />

                    <PhoneTop type="home" className="lds-design-carousel-item1" debug={debug} style={{top: 139, left: 107, height: 299, }} bgColor={bgColor} textColor={textColor} title={value} />
                    {/* <Sprite.Container className="lds-onboard1-design-graphics is-type-invbox" debug={debug} style={{top: 263, left: 155, }} /> */}

                    {/* <Graphic type="invlogo" debug={1} style={{top: 263, left: 155, }} /> */}


                </Sprite.Container>


                {/* ICON */}
                <Sprite.Container debug={debug} style={{top: 2166 + offset, height: 340, width: '100%', }}>
                    
                    <Sprite.Button debug={debug} onClick={onIconDropzone} style={dropstyle} />

                    <Sprite.Container className="lds-onboard1-design-graphics is-type-icon" debug={debug} style={{top: 163, left: 155, }} />
                    <Sprite.Container className="lds-onboard1-design-iconbg is-light" debug={debug} style={{top: 154, left: 245, }} />

                </Sprite.Container>


                {/* ICON ON DARK */}
                <Sprite.Container debug={debug} style={{top: 2667 + offset, height: 340, width: '100%', }}>
                    
                    <Sprite.Button debug={debug} onClick={onIconDropzone} style={dropstyle} />

                    <Sprite.Container className="lds-onboard1-design-graphics is-type-invicon" debug={debug} style={{top: 163, left: 155, }} />
                    <Sprite.Container className="lds-onboard1-design-iconbg is-dark " debug={debug} style={{top: 144, left: 235, backgroundColor: bgColor, }} />

                </Sprite.Container>



            </Sprite.Container>
            
        </Container>
    );
}
// --- WIP

