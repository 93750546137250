import React from "react";
import Icon from "./Icon";
import utils from "../../functions/utils";
import "./Alert.scss";



export default function Alert(props) {
    let {type="primary", icon, size, className, style, title, description, link, message} = props;

    // has message?
    if(message){
        type = message.getPrefix();
        title = message.getMessage();
    }

    // icon
    if(!icon) icon = "alert-" + type;

    // render
    const css = utils.classNames("lds-alert is-text-sm-m is-type-" + type, size && `is-size-${size}`, className);
    return (
        <div className={css} style={style}>
            <div><Icon type={icon} size="md" /></div>
            <div>
                {title && <div className="lds-alert-title">{title}</div>}
                {description && <div className="lds-alert-description">{description}</div>}
                {link && <div className="lds-alert-link">{link}</div>}
            </div>
        </div>
    );
}

// Avatar.Initails = Initails;

