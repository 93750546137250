

const types = {


    // --- standard --- //
    
    mixed: (value) => (value),

    string: (value) => {
        if(typeof value === 'string') return value;
        if(typeof value === 'number' || typeof value === 'boolean') return value + '';
    },

    number: (value) => {
        if(typeof value === 'number') return value;
        value = parseFloat(value);
        if(!isNaN(value)) return value;
    },

    int: (value) => {
        value = parseInt(value);
        if(!isNaN(value)) return value;
    },

    array: (value) => {
        if(Array.isArray(value)) return value;
    },

    object: (value) => {
        if(typeof value === 'object' && !Array.isArray(value) && value !== null) return value;
    },

    boolean: (value) => {
        if(!value || value === null || value === 0 || value === '') return false;
        return true;
    },

    "null": (value) => (null),

    "function": (value) => {
        if(typeof value === 'function') return value;
    },

    "class": (value) => {
        if(typeof value === 'function') return value; // --- WIP
    },

    // --- advanced --- //

    text: (value) => {
        value = types.string(value);
        if(value !== undefined && value !== "") return value;
    },

    map: (value) => {
        if(typeof value === 'object' && !Array.isArray(value)) return value;
    },

    fields: (value) => {
        if(typeof value === 'object') return value;
        // if(typeof value === 'function') return value.FIELDS;
    },

    idMap: (value) => {
        value = types.object(value);
        if(value !== undefined) return value;
        if(!Array.isArray(value)) return; // no
        var map = {};
        value.forEach(obj => {map[obj.id] = obj});
        return map;
    },

    nameMap: (value) => {
        value = types.object(value);
        if(value !== undefined) return value;
        if(!Array.isArray(value)) return; // no
        var map = {};
        value.forEach(obj => {map[obj.name] = obj});
        return map;
    },



    // END OBJECT
}



export default types;