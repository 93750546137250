

import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Layout from "../../components/containers/Layout";
import Loader from "../../components/core/Loader";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import "./Patients.scss";

const debug = 0;

function Title({label}){
    return (label && <div className="lds-patients-panel-title">{label}</div>);
}

export default function Panel(props){
    const {title, noLoader} = props;
    const dispatch = useDispatch();
    const [showActivity, setActivity] = React.useState(false);


    // render
    return (
        <div className="lds-patients-activity-panel">


            {!showActivity && (
            <Sprite.Container id="lds-patients-panel">
                
                <Loader type="preload" color="translucent" duration={2000} style={{height: 900, }} />
                <Sprite.Button debug={debug} onClick={() => setActivity(true)} style={{left: 180, top: 10, }} />
                <Title label={title} />

            </Sprite.Container>
            )}
            
            {showActivity && (
            <Sprite.Container id="lds-patients-activity-panel">
                
                <Loader type="preload" color="translucent" duration={2000} style={{height: 900, }} />
                <Sprite.Button debug={debug} onClick={() => setActivity(false)} style={{left: 180, top: 10, }} />
                <Sprite.Button debug={debug} to="/patients/activity"  style={{top: 230, width: '100%', height: 400, }} />
                <Title label={title} />

            </Sprite.Container>
            )}
        </div>
    )
}