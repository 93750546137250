import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, Progress, } from "../../components/core";
import Email from "../../components/containers/Email";
import {useStepper, } from "./Onboard2.config";
import "./Onboard2.scss";


const DEBUG = 1; // config



// --- main --- //

export default function Page(props){
    const {onboard2, } = useGlobals();
    const dispatch = useDispatch();
    const {steps, stepName, location, pages, stepIndex, } = useStepper();
    const pathname = location?.pathname;


    // on step change
    React.useEffect(() => {
        // console.log('---------- ON PATH CHANGE: ', pathname);
        window.scrollTo(0, 0);
        // dispatch({call: 'SET_ONBOARD2', values: {stepIndex: , }, });
    }, [pathname]);


    // return
    const Page = pages[stepName];
    return Page ? <Page.Component /> : <ErrorPage />;
}


// error page
function ErrorPage({step}){
    const dispatch = useDispatch();
    const {onboard2, } = useGlobals();
    const {startUrl, } = useStepper(step);

    return (
        <div>
            <h1>Error</h1>
            <a href={startUrl}>Start</a>
        </div>
    );
}



