import Mobile from "../../components/containers/Mobile";
import { Sprite } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import MedsDetailsModal from "./MedsDetailsModal"
import Loader from "../../components/core/Loader";

const debug = 0;

export default function Modal(){
    const dispatch = useDispatch();
    const onClickVideo = () => {
        console.log("====== yooooo onClickVideo");
    };

    const onClose = () => dispatch({call: "SHOW_MODAL", component: <MedsDetailsModal />});

    // const onClose = () => {
    //     // --- WIP
    //     console.log("==== IT WORKS");
    // }

    // const Footer = () => (
    //     <Sprite.Button id="lds-app-notifications-modal-footer" onClick={() => dispatch({call: "HIDE_MODAL"})} />
    // );

    return (
        <Mobile.Modal onClose={onClose} spriteId="lds-app-meds-edsheet-modal" label="Humalog KwikPen U-100" onClick={onClickVideo} topHeight222={400}>




        </Mobile.Modal>
    );
}
