import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import ApptModal from "./AppointmentModal";
import "./Schedule.scss";

const debug = 0; // debug


export default function Page(){
    const dispatch = useDispatch();
    // const nav = useNavigate();
    const [showPanel, setPanel] = React.useState(false);
    const {state} = useLocation();

    const serviceOptions = ["Influenza Testing", "Influenza Vaccination"]; // --- WIP: need more

    const onCTA = () => dispatch({call: "SHOW_MODAL", component: <ApptModal />, }); // ApptModal
    //const onCTA = () => {};


    // const onClickToggle = (value) => dispatch({call: "TOGGLE_SCHEDULE_PANEL", value: value, });
    const onClickToggle = () => setPanel(!showPanel);
    const onLoaderComplete = () => {
        if(state?.showPanel) setPanel(true);
    }


    return (

        <React.Fragment>

            <Loader type="preload" color="translucent" onComplete={onLoaderComplete} debug={debug} duration={2000} style={{top: 70, left: 280, width: 1050, height: 800, }} />


            <Layout.WithPanel showPanel={false}>
                <Layout.Sidenav spriteId="lds-schedule-sidenav">

                    <Layout.CTA debug={debug} onClick={onCTA} />
                    <Layout.Selection  debug={debug} label="Upcoming" style={{top: 91}} />
                    <Layout.Hotspot debug={debug} style={{top: 90, height: 330, }} />

                </Layout.Sidenav>
                <Layout.Content>

                    <Sprite.Split id="lds-schedule-add">
                        <Sprite.Left>


                            <Input.Select abs debug={debug}  label="Service name *" options={serviceOptions} style={{top: 100, left: 0, width: 403, }} />
                            {/* <Sprite.LikeText abs debug={1} style={{top: 140, left: 0, width: 300, }}/> */}
                            <Input abs sprite debug={debug}  label="Patient *" value="Martha Smith" style={{top: 118, left: 10, width: 390, }} />
                            <Sprite.LikeText abs debug={debug} style={{top: 250, left: 0, width: 600, height: 100, }}/>

                        </Sprite.Left>
                        <Sprite.Right>

                            <Sprite.Button debug={debug} abs to="/schedule/" style={{top: 20, left: 'auto', right: 0, width: 100, }} />
                            <Sprite.Button debug={debug} abs to="/schedule/" style={{top: 20, left: 'auto', right: 100, width: 100, }} />

                        </Sprite.Right>
                    </Sprite.Split>


                </Layout.Content>
            </Layout.WithPanel>


           

        </React.Fragment>


    )
}