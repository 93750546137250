import Mobile from "../../components/containers/Mobile";
import { Sprite } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
// import MedsDetailsModal from "./MedsDetailsModal";
import MedsDetailsModal from "../meds/MedsDetailsModal";
import Loader from "../../components/core/Loader";


const debug = 0;

export default function NotificationModal(){
    const dispatch = useDispatch();

    // const onClickDetails = () => dispatch({call: "SHOW_MODAL", component: <MedsDetailsModal />});

    const Footer = () => (
        <Sprite.Button id="lds-app-notifications-modal-footer" onClick={() => dispatch({call: "HIDE_MODAL"})} />
    );

    return (
        <Mobile.Modal spriteId="lds-app-notifications-modal" label="Notifications" topHeight222={400} footer={<Footer />}>

            <Sprite.Button debug={debug} to="/app/meds/ready" style={{top: 60, width: '100%', height: 110, }} />


            {/* <Sprite.Button debug={debug} style={{top: 360, left: 210, width: 40, }} onClick={onClickDetails} /> */}

        </Mobile.Modal>
    );
}
