import utils from "../../functions/utils";
import {Icon, Button, Input} from "../core";
import "./SideNav.scss";

function Tab({label, value, tab, number, icon, selected}){
    const css = utils.classNames("lds-sidenav-tab is-text-sm", selected && "is-selected");

    return (
        <div className={css}>
            <Icon size="medium" type={icon} selected={selected} />
            {label}{number && <div className="lds-sidenav-number">{number}</div>}
        </div>
    );
}

const CTA = (props) => {
    return <Top><Button.Primary {...props} /></Top>
}
const Top = ({children}) => {
    return <div className="lds-sidenav-top">{children}</div>
}
const TabGroup = ({children}) => {
    return <div className="lds-sidenav-tabs">{children}</div>
}

const Filter = ({label, selected}) => {
    return <div className="lds-sidenav-filter"><Input.Checkbox label={label} selected={selected} /></div>
}

const FilterGroup = ({children, label}) => {
    return (
        <div className="lds-sidenav-filtergroup">
            {label && <div className="lds-sidenav-filterlaabel is-text-xs is-upper is-gray5 is-leading">{label}</div>}
            {children}
        </div>
    );
}

export default function SideNav(props){
    const {children} = props;
    const css = utils.classNames("lds-sidenav");
    return (
        <div className={css}>{children}</div>
    );
}



SideNav.CTA = CTA;
SideNav.Top = Top;
SideNav.Tab = Tab;
SideNav.TabGroup = TabGroup;
SideNav.Filter = Filter;
SideNav.FilterGroup = FilterGroup;