import Mobile from "../../components/containers/Mobile";
import { Sprite } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
// import MedsEdsheetModal from "./MedsEdsheetModal";
import Loader from "../../components/core/Loader";

const debug = 0;

// step 5
function Modal5(){
    const dispatch = useDispatch();
    const onClickClose = () => {
        dispatch({call: "SET_APPT_ADDED", });
        dispatch({call: "HIDE_MODAL", });
    }
    // const onClickNext = () => dispatch({call: "SHOW_MODAL", component: <Modal2 />, });

    // render
    return (
        <Mobile.Modal noScroll spriteId="lds-app-appts-new-5" label="New appointment">

            <Sprite.Button debug={debug} onClick={onClickClose} abs style={{top: 590, left: 0, width: '100%', height: 110, }} />

        </Mobile.Modal>
    );
}

// step 4
function Modal4(){
    const dispatch = useDispatch();
    const onClickBack = () => dispatch({call: "SHOW_MODAL", component: <Modal3 />, });
    const onClickNext = () => dispatch({call: "SHOW_MODAL", component: <Modal5 />, });

    // render
    return (
        <Mobile.Modal noScroll onClose={onClickNext} spriteId="lds-app-appts-new-4" label="New appointment">

            <Sprite.Button debug={debug} onClick={onClickNext} className="is-cursor-text" abs style={{width: '100%', height: '100%', }} />
            <Sprite.Button debug={debug} onClick={onClickNext} abs style={{top: 630, height: 50, width: '100%', }} />
            {/* <Sprite.Button debug={debug} onClick={onClickNext} abs style={{top: 470, left: 0, width: '100%', height: 60, }} /> */}
            {/* <Sprite.Button debug={debug} onClick={onClickBack} abs style={{top: 640, left: 0, width: '50%', height: 60, }} />
            <Sprite.Button debug={debug} onClick={onClickNext} abs style={{top: 640, left: '50%', width: '50%', height: 60, }} /> */}

        </Mobile.Modal>
    );
}

// step 3
function Modal3(){
    const dispatch = useDispatch();
    const onClickBack = () => dispatch({call: "SHOW_MODAL", component: <Modal2 />, });
    const onClickNext = () => dispatch({call: "SHOW_MODAL", component: <Modal4 />, });

    // render
    return (
        <Mobile.Modal noScroll spriteId="lds-app-appts-new-3" label="New appointment">

            {/* <Sprite.Button debug={debug} onClick={onClickNext} abs style={{top: 470, left: 0, width: '100%', height: 60, }} /> */}
            <Sprite.Button debug={debug} onClick={onClickBack} abs style={{top: 640, left: 0, width: '50%', height: 60, }} />
            <Sprite.Button debug={debug} onClick={onClickNext} abs style={{top: 640, left: '50%', width: '50%', height: 60, }} />

        </Mobile.Modal>
    );
}


// step 2
function Modal2(){
    const dispatch = useDispatch();
    const onClickNext = () => dispatch({call: "SHOW_MODAL", component: <Modal3 />, });

    // render
    return (
        <Mobile.Modal spriteId="lds-app-appts-new-2" label="New appointment">

            <Sprite.Button debug={debug} onClick={onClickNext} abs style={{top: 770, left: 0, width: '100%', height: 60, }} />

        </Mobile.Modal>
    );
}




// main
function Modal1(){
    const dispatch = useDispatch();

    const onClickClose = () => dispatch({call: "HIDE_MODAL", });
    const onClickNext = () => dispatch({call: "SHOW_MODAL", component: <Modal2 />, });

    // render
    return (
        <Mobile.Modal noScroll spriteId="lds-app-appts-new-1" label="New appointment">

            <Sprite.Button debug={debug} onClick={onClickClose} abs style={{top: 630, left: 0, width: '50%', height: 60, }} />
            <Sprite.Button debug={debug} onClick={onClickNext} abs style={{top: 630, left: '50%', width: '50%', height: 60, }} />

        </Mobile.Modal>
    );
}

// main
export default function Modal(){
    return <Modal2 />; // NOTE: ship step 1 for now
}
