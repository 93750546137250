import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import {Sidenav} from "./VideosPage";
import "./Videos.scss";

const debug = 0;


export default function Screen(){
    return (
        <Sprite.Container id="lds-videos-details">
            <Sidenav />
            <Sprite.Button debug={debug} to="/videos" style={{top: 10, left: 300, width: 100, height: 40, }} />
        </Sprite.Container>
    );
}
