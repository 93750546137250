import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import ChatModal from "../messages/MessagesChatModal";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";

import TasksEditModal from "./TasksEditModal";
import "../messages/Messages.scss";
import "./Tasks.scss";


const debug = 0; // debug

export default function Page(){
    const dispatch = useDispatch();
    // const {showTasksPanel} = useGlobals();
    const [showPanel, setPanel] = React.useState(false);
    const {state} = useLocation();

    const onCTA = () => dispatch({call: "SHOW_MODAL", component: <TasksEditModal />});
    // const onClickToggle = (value) => dispatch({call: "TOGGLE_TASKS_PANEL", value: value, });
    const onClickToggle = () => setPanel(!showPanel);
    const onLoaderComplete = () => {
        if(state?.showPanel) setPanel(true);
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         if(showTasksPanel) setPanel(true);
    //     }, 2000);
        
    // }, []);


    // render
    return (
        <React.Fragment>

            <Loader type="preload" color="translucent" duration={2000} debug={debug} style={{top: 70, left: 280, width: 1020, height: 580,  }} />

            <Layout.WithPanel showPanel={showPanel}>
                <Layout.Sidenav spriteId="lds-tasks-sidenav-grid">

                    <Sprite.Button debug={debug} to="/tasks" style={{top: 20, left: 10, width: 80, }} />
                    <Sprite.Button debug={debug} to="/tasks/auto" style={{top: 20, left: 180, width: 80, }} />

                    <Layout.CTA debug={debug} onClick={onCTA} style={{top: 80, }} />
                    <Input.Checkbox debug={debug} style={{top: 150, left: 24, width: 100, }} />
                    <Layout.Selection  debug={debug} label="All tasks" style={{top: 188, }} />
                    <Layout.Hotspot debug={debug} style={{top: 190, height: 450, }} />

                </Layout.Sidenav>
                <Layout.Content noPadding style={{paddingTop: 0, paddingLeft: 24, paddingRight: 24, minWidth2222: 830, overflow: 'hidden', }}>


                <Sprite.Container id="lds-tasks-grid">

</Sprite.Container>

                </Layout.Content>
                <Layout.Panel noPadding onClickClose={onClickToggle}>
                    <Sprite.Container id="lds-tasks-panel">
                        
                        {/* PANEL CONTENT */}

                    </Sprite.Container>
                </Layout.Panel>
            </Layout.WithPanel>




        </React.Fragment>

    )
}