import React from "react";
import Mobile from "../../components/containers/Mobile";
import { Sprite, Input, } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import Loader from "../../components/core/Loader";
import { useNavigate } from "react-router";
import utils from "../../functions/utils";

const debug = 0;

export default function Sheet(){
    const dispatch = useDispatch();
    const nav = useNavigate();

    // radios
    const [status, setStatus] = React.useState(false);
    const onStatusClick = (item) => setStatus(item.value);

    const onCTA = () => {
        if(!status) return;
        const next = (status === "yes") ? "/app/meds/inscards" : "/app/meds/refill/review";
        utils.nav(nav, next);
    }

    // const Footer = () => (
    //     <Sprite.Button id="lds-app-notifications-modal-footer" onClick={() => dispatch({call: "HIDE_MODAL"})} />
    // );

    return (
        <Mobile.Modal noLoader topHeight={443} label="Insurance Card" spriteId="lds-app-meds-refillagain-sheet">

            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="lg" value="yes" style={{top: 60, left: 32, width: 300, }} />
            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="lg" value="no" style={{top: 124, left: 32, width: 300, }} />

            <Sprite.Button debug={debug} onClick={onCTA} to222="/app/meds/refill/review" abs style={{top: 190, width: '100%', height: 50, }} />

            {/* <Sprite.Button debug={debug} to="/app/messages/chat" abs style={{top: 510, left: 20, }} /> */}


            {/* <video id="bc-player_html5_api" data-video-id="2917194705001" data-account="1809147066001" data-player="B1L1KtC5" data-embed="default" class="vjs-tech"  style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;" tabindex="-1" preload="auto" autoplay="" src="blob:https://ondemand.medsoncue.com/4013e4b8-9c9b-42f1-ba4f-c52c31a7578b"></video> */}

        </Mobile.Modal>
    );
}
