import React from "react";
import Icon from "./Icon";
import utils from "../../functions/utils";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useGlobals, useDispatch } from '../../app/context';
import "./Loader.scss";


function Reloader(props){
    const {index} = props;
    const [current, setCurrent] = React.useState(null);

    React.useEffect(() => {
        setCurrent(null);
        setTimeout(() => setCurrent(index), 1);
    }, [index]);

    // render
    if(current === index) return <Loader {...props} />
}

export default function Loader(props) {
    let {open, id, duration = 1000, zIndex, onComplete, type, className, style, fadeIn, color, debug, } = props;
    const {showMobile} = useGlobals();

    // is mobile?
    if(showMobile){



        if(!style) style = {};


       //  if(!style.width) style.width = '100%'; // --- NOTE: This is causing an error


        if(!style.height) style.height = '100%';
    }

    if(type === "preload"){
        open = true;
        fadeIn = false;
    }
    const [open2, setOpen] = React.useState(open);

    React.useEffect(() => {
        if(!open2) return;
        setTimeout(() => {
            setOpen(false);
            if(onComplete) onComplete();
        }, duration);
    }, [open2]);
    
    
    // render
    const css = utils.classNames("lds-loader", type && `is-type-${type}`, color && `is-color-${color}`, className, debug && 'is-debug');
    const css2 = utils.classNames("lds-loader-bg", color && `is-color-${color}`);
    return  (
        <React.Fragment>
            <Backdrop className={css} style={style} id={id} open={open2} sx={{ color: '#fff', zIndex: zIndex || ((theme) => theme.zIndex.drawer + 1) }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {fadeIn === false && open2 && <div className={css2} style={style} />}
        </React.Fragment>

    );
}

Loader.Reloader = Reloader;

// Avatar.Initails = Initails;

