import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
// import "./Marketing.scss";
import "./Dashboard.scss";



// sean healy 908-470-6277
// andrawes@gmail.com

export default function Page(){

    return (
        <Layout.WithPanel>
            <Layout.Content noPadding222 className="is-debug222">

                <Sprite.Container  id="lds-dashboard4-side" abs debug={0}>

                    <Sprite.Button abs to="/tasks" debug={0} style={{top: 20, left: 20, width: 80, height: 60, zIndex: 2, }} />
                    <Sprite.Button abs to="/schedule/add" debug={0} style={{top: 20, left: 110, width: 80, height: 60, zIndex: 2, }} />
                    <Sprite.Button abs to="/messages" debug={0} style={{top: 20, left: 200, width: 80, height: 60, zIndex: 2, }} />
                    <Sprite.Button abs to="/messages/send" debug={0} style={{top: 20, left: 280, width: 80, height: 60, zIndex: 2, }} />
                    <Sprite.Button abs to="/schedule" debug={0} style={{top: 100, left: 30, width: 330, height: 440, zIndex: 2, }} />
                    
                </Sprite.Container>





                <Sprite.Split debug={0} id="lds-dashboard4">



                <div style={{top: 116, left: 0, padding: '0 30px 0 390px', width: '100%', position: 'absolute', }}>

                        <div style={{top: 0, left: 0, height: 150,  width: '100%', position: 'relative', }}>
                            <Sprite.Button abs to="/refills/" debug={0} style={{top: -90, left: 0, width: 300,  }} />
                            <Sprite.Button abs to="/refills" debug={0} style={{width: '100%', height: '100%', }} />
                            <Loader type="preload" duration={1200}  debug={0} />
                        </div>

                        <div style={{top: 90, left: 0, height: 320,  width: '100%', position: 'relative', }}>
                            <Sprite.Button abs to="/messages/" debug={0} style={{top: -50, left: 0, width: 220,  }} />
                            <Sprite.Button abs to="/messages" debug={0} style={{width: '100%', height: '100%', }} />
                            <Loader type="preload" duration={600}  debug={0} />
                        </div>

                        <div style={{top: 220, left: 0, height: 100,  width: '100%', position: 'relative', }}>
                            <Sprite.Button abs to="/forms/" debug={0} style={{top: -90, left: 0, width: 280,  }} />
                            <Sprite.Button abs to="/forms/details/" debug={0} style={{width: '100%', height: '100%', }} />
                            <Loader type="preload" duration={2000}  debug={0} />
                        </div>

                        <div style={{top: 333, left: 0, height: 450,  width: '100%', position: 'relative', }}>
                            <Sprite.Button abs to="/tasks" debug={0} style={{top: -80, left: 0, width: 150,  }} />
                            <Sprite.Button abs to="/tasks" debug={0} style={{width: '100%', height: '100%', }} />
                            <Loader type="preload" duration={2500}  debug={0} />
                        </div>


                        {/* <Sprite.Button abs to="/messages" debug={0} style={{width: '100%', top: 10, height: 400, position: 'relative', }} /> */}

                </div>
{/* 
                    <div style={{top: 116, left: 0, height: 1500, padding: '0 30px 0 390px', width: '100%', position: 'absolute', }}>


                        <div style={{top: 0, left: 0, height: 150,  width: '100%', position: 'relative', }}>
                            <Loader type="preload" duration={1200}  debug={0} />
                        </div>

                        <div style={{top: 90, left: 0, height: 320,  width: '100%', position: 'relative', }}>
                            <Loader type="preload" duration={600}  debug={0} />
                        </div>


                        <div style={{top: 220, left: 0, height: 100,  width: '100%', position: 'relative', }}>
                            <Loader type="preload" duration={1500}  debug={0} />
                        </div>

                        <div style={{top: 320, left: 0, height: 450,  width: '100%', position: 'relative', }}>
                            <Loader type="preload" duration={2000}  debug={0} />
                        </div>
                    </div> */}


                    {/* style={{top: 200, height: 200, }}  */}
                    {/* <Loader type="preload" duration={200000} style={{top: 200, height: 200, }}  debug={0} /> */}
{/*                 

                    <Sprite.Button abs to="/messages" debug={debug} style={{width: 200, top: 10, height: 40, }} />
                    <Sprite.Button abs to="/messages" debug={debug} style={{width: '100%', top: 60, height: '100%', }} /> */}


                    <Sprite.Left />
                    <Sprite.Right />
                </Sprite.Split>

            </Layout.Content>
        </Layout.WithPanel>

    )
}