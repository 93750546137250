import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import ChatModal from "./MessagesChatModal";
import MessagesNewModal from "./MessagesNewModal";
import PatientsPanel from "../patients/PatientsPanel";
import { useGlobals, useDispatch } from '../../app/context';



const debug = 0;


export default function Page(){
    const dispatch = useDispatch();
    const {messageAdded, } = useGlobals();
    // const [showPanel, setPanel] = React.useState(false);
    const [selection, setSelection] = React.useState(null);
    const onCTA = () => dispatch({call: "SHOW_MODAL", component: <MessagesNewModal />});
    // const onClickToggle = (value) => dispatch({call: "TOGGLE_MESSAGES_PANEL", value: value, });
    const onLoaderComplete = () => {setSelection(0)};

    // reset added message
    React.useEffect(() => {
        return () => {
            dispatch({call: "SET_MESSAGE_ADDED", value: false, });
        }
        
    }, []);


    // render
    return (
        <React.Fragment>

            {false && <Loader type="preload" duration={2000} onComplete={onLoaderComplete} style={{top: 70, left: 280, width: 1020, height: 680,  }} />}

            <Layout.WithPanel showPanel={utils.showPanel(selection)}>
                <Layout.Sidenav spriteId="lds-messages-sidenav-send">

                    <Sprite.Button debug={debug} to="/messages" style={{top: 20, left: 10, width: 80, }} />
                    <Sprite.Button debug={debug} to="/messages/auto" style={{top: 20, left: 180, width: 80, }} />

                    <Layout.CTA debug={debug} onClick={onCTA} style={{top: 80, }} />

                    {/* <Sprite.Button debug={debug} to="/messages" style={{top: 20, left: 10, width: 80, }} />
                    <Sprite.Button debug={debug} style={{top: 20, left: 180, width: 80, }} />

                    <Layout.CTA debug={debug} onClick={onCTA} style={{top: 80, }} />
                    <Layout.Selection  debug={debug} label="All" style={{top: 143, }} />
                    <Layout.Hotspot debug={debug} style={{top: 140, height: 340, }} /> */}

                </Layout.Sidenav>
                <Layout.Content>

                    <Sprite.Split debug={debug} id="lds-messages-send-top">
                        <Sprite.Left>
                        </Sprite.Left>
                        <Sprite.Right />
                    </Sprite.Split>
                    
                    <div id="lds-messages-send-bottom" className={messageAdded && 'is-added'} >
                        <Sprite.Split debug={debug} id="lds-messages-send">
                            
                            {true && <Sprite.Button abs debug={debug} to="/messages/details" style={{zIndex: 1, width: '100%', height: '100%', }} />}

                            {/* {false && <Sprite.Rows abs debug={debug} count={7} selection={selection} onChange={(i) => setSelection(i)} style={{zIndex: 1, top: 0, }} />} */}
                            {false && <Sprite.Rows abs debug={debug} count={7} selection={selection} onChange={(i) => setSelection(i)} style={{zIndex: 1, top: 'auto', bottom: 0, }} />}
                            <Sprite.Left>
                            </Sprite.Left>
                            <Sprite.Right />
                        </Sprite.Split>
                    </div>


                </Layout.Content>
                <Layout.Panel noPadding onClickClose={() => setSelection(null)}>


                    <PatientsPanel title={utils.names[selection]} />
                    <Loader.Reloader type="preload" color="translucent" index={selection} duration={1000}  />

                    {/* <Sprite.Container id="lds-messages-panel">
                        <Loader.Reloader type="preload" color="translucent" index={selection} duration={1000}  />
                    </Sprite.Container> */}


                </Layout.Panel>
            </Layout.WithPanel>




        </React.Fragment>

    )
}