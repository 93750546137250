import Mobile from "../../components/containers/Mobile";
import { Sprite } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import MedsEdsheetModal from "./MedsEdsheetModal";
import Loader from "../../components/core/Loader";

const debug = 0;

export default function Modal(){
    const dispatch = useDispatch();
    const onClickVideo = () => {
        console.log("====== yooooo onClickVideo");
    };



    const onClickEdsheet = () => dispatch({call: "SHOW_MODAL", component: <MedsEdsheetModal />});

    // const Footer = () => (
    //     <Sprite.Button id="lds-app-notifications-modal-footer" onClick={() => dispatch({call: "HIDE_MODAL"})} />
    // );
        
        
    // <Mobile.Modal spriteId="lds-app-meds-details-modal" label="Atorvastatin 20 mg tablet" onClick={onClickVideo} topHeight222={400}></Mobile.Modal>

    return (
        <Mobile.Modal spriteId="lds-app-meds-details-modal" label="Humalog KwikPen U-100" onClick={onClickVideo}>


            <Sprite.Button debug={debug} to="/app/messages/chat" abs style={{top: 480, left: 20, }} />


            <Sprite.Button debug={debug} onClick={onClickEdsheet} abs style={{top: 1350, left: 0, width: '100%', height: 60, }} />


            {/* <iframe style={{top: 636, left: 16, border: "gray 1px solid", opacity: 1, }} onClick={onClickVideo} width="343" height="192" src="https://www.youtube.com/embed/D-XxJ4fznLo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
 */}

        </Mobile.Modal>
    );
}
