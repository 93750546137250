import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Mobile from "../../components/containers/Mobile";
import MedsTabs from "./MedsTabs";

const debug = 0;

export default function Screen(){
    return (
        <Mobile.Wrapper type="tab" label="Medications" spriteId="lds-app-meds-reminders" name="meds" topContent={<MedsTabs />}>


            {/* <Sprite.LikeText debug={debug} style={{top: 40, width: '100%', height: 80, }} />
            <Sprite.Button debug={debug} style={{top: 230, width: '100%', height: 210, }} />
            <Sprite.Button debug={debug} style={{top: 530, width: '100%', height: 210, }} /> */}

        </Mobile.Wrapper>
    );
}
