import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import Mobile from "../../components/containers/Mobile";
import MedsDetailsModal from "../meds/MedsDetailsModal";
import MedsTabs from "./MedsTabs";

const debug = 0;

export default function Screen(){
    const dispatch = useDispatch();


    // const onClickDetails = () => dispatch({call: "SHOW_MODAL", component: <MedsDetailsModal />});
    // const toExit = () => {};


    // render
    return (
        <Mobile.Wrapper type="sub" label='Fill Your Prescription' toBack="/app/meds" toExit="/app/meds" spriteId="lds-app-meds-refillagain-cards">


            <Sprite.Button debug={debug} to="/app/meds/refill/review" style={{top: 700, width: '100%', height: 50, }} />

            {/* <Sprite.Button debug={debug} onClick={onClickDetails} style={{top: 80, left: 210, width: 40, height: 40, }} />
            <Sprite.Button debug={debug} onClick={onClickDetails} style={{top: 210, left: 240, width: 40, height: 40, }} />

            <Input.Checkbox debug={debug} size="lg" abs style={{top: 86, left: 16, width: 80, }} />
            <Input.Checkbox debug={debug} size="lg" abs style={{top: 219, left: 16, width: 80, }} />


            <Sprite.Button debug={debug} to="/app/meds/refill/method" style={{top: 640, width: '100%', height: 50, }} /> */}


            {/* <Sprite.LikeText debug={debug} style={{top: 40, width: '100%', height: 80, }} />
            <Sprite.Button debug={debug} style={{top: 230, width: '100%', height: 210, }} />
            <Sprite.Button debug={debug} style={{top: 530, width: '100%', height: 210, }} /> */}

        </Mobile.Wrapper>
    );
}
