import React from "react";
import utils from "../../functions/utils";


const sizes = {
    sm: 16, 
    md: 20, 
    lg: 24, 
    xl: 28, 
    xxl: 32, 
};

const paths = {
    "circle":           "M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6023 1.66667 9.99996 1.66667C5.39759 1.66667 1.66663 5.39763 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z",
    "chevron-right":    "M7.5 15L12.5 10L7.5 5",
    "chevron-left":     "M12.5 15L7.5 10L12.5 5",
    "x":                "M15 5L5 15M5 5L15 15",
}

export function Icon2(props){
    let {type, size, selected, color='#cccccc', stroke=1.5, className, style, onClick, } = props;

    // settings
    if(!style) style = {};
    style.color = color;
    const path = paths[type] || paths.circle;
    // const dim = sizes[size] || sizes.md;
    if(!size) size = 22;

    // render
    const css = utils.classNames("lds-icon-svg is-type-" + type + " is-size-" + size, className, selected && "is-selected");
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d={path} stroke-width={stroke} />
    </svg>
    );
}



/*
// EXAMPLE
<svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 125 125" fill="none">
    <path d="M62.4988 46.875V67.7083M62.4988 88.5416H62.5509M53.5925 20.1041L9.47796 93.75C8.56842 95.3251 8.08716 97.1109 8.08207 98.9298C8.07698 100.749 8.54823 102.537 9.44893 104.117C10.3496 105.697 11.6484 107.014 13.216 107.937C14.7836 108.859 16.5655 109.355 18.3842 109.375H106.613C108.432 109.355 110.214 108.859 111.782 107.937C113.349 107.014 114.648 105.697 115.549 104.117C116.449 102.537 116.921 100.749 116.916 98.9298C116.91 97.1109 116.429 95.3251 115.52 93.75L71.405 20.1041C70.4766 18.5734 69.1692 17.3079 67.6092 16.4296C66.0492 15.5513 64.2891 15.0898 62.4988 15.0898C60.7085 15.0898 58.9484 15.5513 57.3884 16.4296C55.8284 17.3079 54.521 18.5734 53.5925 20.1041Z" 
    stroke="#D00000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

*/

export default Icon2;