import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import TopNav from "../../components/nav/TopNav";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, } from "./Onboard1_Comps";

import {FilePickerModal} from "../ui/FilePickerModal";
import ColorPickerPopover from "../ui/ColorPickerPopover";
import DesignPage from "./Onboard1_DesignPage";
import DesignPage2 from "./Onboard1_DesignPage2";
// import ChatModal from "./MessagesChatModal";
import "./Onboard1.scss";



const debug = 0;


const steps = ["start", "welcome", "end",  ];

let LOCALS = {
    step: undefined,
    stepName: undefined,
}


// functions

function getStepParam(location){
    const urlPath = location?.pathname;
    let step = urlPath?.split('/').pop() || 0;
    step = parseInt(step);
    return isNaN(step) ? 0 : step;
}

function loadLocals(location){
    if(LOCALS.step !== undefined) return LOCALS;

    const urlPath = location?.pathname;
    const stepName = LOCALS.stepName = urlPath?.split('/').pop() || "";
    const step = LOCALS.step = steps.indexOf(stepName);

    return LOCALS;
}


// --- components --- //


// function StepButton(props){
//     let {label, step, next, back, offset = 1, style, id, } = props;
//     const {onboard1, } = useGlobals();

//     if(isNaN(step)){
//         step = onboard1.step;
//         if(next) offset = 1;
//         else if(back) offset = -1;
//         step += offset;
//     }

//     const to = "/demos/onboard1/" + steps[step];
//     if(!label) label = (offset > 0) ? "Next" : "Back";

//     // render
//     return <Sprite.Button debug={debug} style={style} to={to} label={label} />;
// }

// export function ProgressBar(props){
//     const {step, } = props;
//     const css = utils.classNames("lds-demos-progressbar", step && `is-step-${step}`);
//     return <div className={css} />;
// }

// function Container(props){
//     const {name, children, title, description, icon, step,  } = props;
//     // const {onboard1, } = useGlobals();
//     // const {step, } = onboard1;
//     // const dispatch = useDispatch();
//     const location = useLocation();
//     // const step = 2;

//     React.useEffect(() => {

//         const urlPath = location?.pathname;
//         const stepName = LOCALS.stepName = urlPath?.split('/').pop() || "";
//         const step = LOCALS.step = steps.indexOf(stepName);
//         // const step = steps.find(stepName);

//         console.log("========== STEP: stepName: " + stepName + ", step: " + step + ", urlPath: " + urlPath);

//         // dispatch({call: "SET_ONBOARD1_VALUES", values: {step: 1, }, });
//     }, []);

//     // render
//     const css = utils.classNames("lds-onboard1", `is-step-${LOCALS.step}`, `is-name-${name}`);
//     return (
//         <div className={css}>
//             <TopNav.Basic />
//             <div className="lds-onboard1-top">
//                 {!isNaN(step) && <ProgressBar step={step} />}
//                 {icon && <div className={`lds-onboard1-icon is-type-${icon}`} />}
//                 {title && <div className="lds-onboard1-title">{title}</div>}
//                 {description && <div className="lds-onboard1-description">{description}</div>}
//             </div>
//             <div className="lds-onboard1-content">{children}</div>
//         </div>
//     );
// }


// function StepButton(props){
//     const {style, step, debug, } = props;
//     const dispatch = useDispatch();
//     const onClick = () => dispatch({call: "SET_ONBOARD1_STEP", value: step, });
//     // onClick333={onClick} 

//     // render
//     return <Sprite.Button to={`/demos/onboard1/${step}`} debug={debug} style={style} />;
// }



// function ButtonGroup(props){
//     const {style, nextOnly, debug, } = props;
//     const location = useLocation();
//     const step = getStepParam(location);
//     // const {onboard1, } = useGlobals();
//     // const {step, } = onboard1;

//     const css = "lds-onboard1-buttons";

//     if(nextOnly) return (
//         <Sprite.Container debug={debug} style={style} className={css} >
//             <StepButton step={step+1} debug={debug} style={{width: '100%', }} />
//         </Sprite.Container>
//     );

//     // render
//     return (
//             <Sprite.Container debug={debug} style={style} className={css} >
//                 <StepButton step={step-1} debug={debug} style={{width: '50%', }} />
//                 <StepButton step={step+1} debug={debug} style={{width: '50%', left: '50%', }} />
//             </Sprite.Container>
//     );
// }


// function StartLoader(props){
//     const {height, duration, } = props;

//     // render
//     return  <Loader type="preload" color="white" duration={duration||1000} debug={debug} style={{top: 0, left: 0, width: '100%', height: height||800,  }} />;
// }

// function EndLoader(props){
//     let {next, ending, height, duration, } = props;
//     const nav = useNavigate();
//     const onComplete = () => nav(next);
    
//     // render
//     if(!ending) return;
//     return <Loader type="preload" color="translucent" onComplete={onComplete} duration={duration||800} debug={debug} style={{top: 0, left: 0, width: '100%', height: height||800,  }} />;
// }




// --- pages --- //

function SingleOwnerEmail(){
    const dispatch = useDispatch();
    const {onboard1, } = useGlobals();

    // return <div>EMAIL</div>;

    return (
        <Email title="Finish setting up Town Pharmacy" spriteId="lds-onboard1-email-singleowner">
            <StepButton step={1} debug={debug} style={{top: 350, left: 190, width: 140, height: 40, }} />
            {/* <Sprite.Button debug={debug} to="/demos/onboard1/welcome" style={{top: 350, left: 190, width: 140, height: 40, }} /> */}
        </Email>
    );
}


function PasswordPage(props){
    const {next, } = props;
    const [ending, setEnding] = React.useState(false);


    // render
    return (
        <Container icon="clock" title222="Create password">
            
            <StartLoader duration={3000} />

            <EndLoader ending={ending} next={next} />

            <Sprite.Container id="lds-onboard1-form-password">
                <Sprite.Button to222={next} onClick={() => setEnding(true)} debug={debug} style={{top: 440,  }} />
            </Sprite.Container>
        </Container>
    );
}

function LoginPage(props){
    const {next, } = props;
    const [ending, setEnding] = React.useState(false);

    // render
    return (
        <Container icon="clock" title222="Login" description222="These are your store’s general hours">
            
            <EndLoader ending={ending}  next={next} />

            <Sprite.Container id="lds-onboard1-form-login">
                <Sprite.Button to222={next} onClick={() => setEnding(true)}  debug={debug} style={{top: 260,  }} />
            </Sprite.Container>
        </Container>
    );
}


function WelcomePage(props){
    const {next, } = props;
    const [ending, setEnding] = React.useState(false);

    // render
    return (
        <Container>
            
            <EndLoader ending={ending}  next={next} />

            <Sprite.Container id="lds-onboard1-form-welcome">
                <Sprite.Button to222={next} onClick={() => setEnding(true)}  debug={debug} style={{top: 600, left: 140, width: 230,  }} />
            </Sprite.Container>
        </Container>
    );
}

function AccountPage(props){
    const {step, } = props;

    // render
    return (
        <Container step={step} icon="clock" title="Account info" description222="These are your store’s general hours">
            
            <Sprite.Container id="lds-onboard1-form-account">
                <ButtonGroup  />
            </Sprite.Container>
        </Container>
    );
}

function AgreePage(props){
    const {step, } = props;

    // render
    return (
        <Container step={step} icon="clock" title="Agreement" description222="These are your store’s general hours">
            
            <Sprite.Container id="lds-onboard1-form-agree">
                <ButtonGroup style={{width: 430, }} />
            </Sprite.Container>
        </Container>
    );
}


function HoursPage(props){
    const {step, } = props;

    // render
    return (
        <Container step={step} icon="clock" title="Store Hours" description="These are your store’s general hours">
            
            <Sprite.Container id="lds-onboard1-form-hours">
                <ButtonGroup style={{left: 110, }} />
            </Sprite.Container>
        </Container>
    );
}

function DepartmentsPage(props){
    const {step, } = props;

    // render
    return (
        <Container step={step} icon="clock" title="Departments" description="Departments allows for department specific settings. Departments are optional and may be added later.">
            
            <Sprite.Container id="lds-onboard1-form-departments">
                <ButtonGroup style={{bottom: 60, }}  />
            </Sprite.Container>
        </Container>
    );
}

function EmployeesPage(props){
    const {step, } = props;

    // render
    return (
        <Container step={step} icon="clock" title="Employees" description="All employees must be assigned a role. Roles determine what actions an employee has permission take.">
            
            <Sprite.Container id="lds-onboard1-form-employees">
                <ButtonGroup style={{left: 250, }} />
            </Sprite.Container>
        </Container>
    );
}

function PatientsPage(props){
    const {step, } = props;

    // render
    return (
        <Container step={step} icon="clock" title="Patients" description222="These are your store’s general hours">
            
            <Sprite.Container id="lds-onboard1-form-patients">
                <ButtonGroup style={{left: 250, bottom: 50, }} />
            </Sprite.Container>
        </Container>
    );
}

function CompletedPage(props){
    const {step, } = props;

    // render
    return (
        <Container>
            
            <Sprite.Container id="lds-onboard1-form-completed">
                <Sprite.Button to="/" debug={debug} style={{top: 560, left: 140, width: 230, }} />
                <Sprite.Button to="start" debug={debug} style={{top: 620, left: 140, width: 230, }} />
            </Sprite.Container>
        </Container>
    );
}



// --- main --- //



export default function Page(props){
    // const {onboard1, } = useGlobals();
    const location = useLocation();
    const step = getStepParam(location);
    const props2 = {steps, };

    // on stap change
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    // step
    const stepProp = (step >= 3) ? (step-3) : undefined;
    console.log("========== STEP: " + step + ", PROP: " + stepProp);

    // pages
    const Pages = [
        SingleOwnerEmail,
        PasswordPage,
        LoginPage,
        WelcomePage,
        AccountPage,
        AgreePage,
        DesignPage,
        DesignPage2,
        HoursPage,
        DepartmentsPage,
        EmployeesPage,
        PatientsPage,
        CompletedPage,
    ]

    // staps
    const Page = Pages[step] || Pages[0];
    return <Page {...props2} index={step} back={String(step-1)} next={String(step+1)} step={stepProp} />
}

