import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import ChatModal from "../messages/MessagesChatModal";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import utils from "../../functions/utils";
import TasksPanel from "./TasksPanel";
import TasksEditModal from "./TasksEditModal";
import "../messages/Messages.scss";
import "./Tasks.scss";


const debug = 0; // debug

export default function Page(){
    const dispatch = useDispatch();
    const {taskSelection, context, } = useGlobals();
    const isBrand = context?.isBrand;
    const isAltStore = context?.isAltStore;
    // const {showTasksPanel} = useGlobals();
    const {state} = useLocation();
    const onCTA = () => dispatch({call: "SHOW_MODAL", component: <TasksEditModal />});

    // new
    const [selection, setSelection] = React.useState(state?.showPanel || null);

    React.useEffect(() => {
        if(!isNaN(taskSelection)) setSelection(taskSelection);
    }, [taskSelection]);


    // render
    const listCss = utils.classNames(isBrand && 'is-brand', isAltStore && 'is-altstore');
    return (
        <React.Fragment>

            {/* <Loader type="preload" duration={2000} style={{top: 70, left: 280, width: 1020, height: 680,  }} /> */}

            <Layout.WithPanel showPanel={utils.showPanel(selection)}>
                <Layout.Sidenav spriteId="lds-tasks-sidenav">

                    <Sprite.Button debug={debug} to="/tasks/grid" style={{top: 20, left: 100, width: 80, }} />
                    <Sprite.Button debug={debug} to="/tasks/auto" style={{top: 20, left: 180, width: 80, }} />

                    <Layout.CTA debug={debug} onClick={onCTA} style={{top: 80, }} />
                    <Input.Checkbox debug={debug} style={{top: 150, left: 24, width: 100, }} />
                    <Layout.Selection  debug={debug} label="All tasks" style={{top: 188, }} />
                    <Layout.Hotspot debug={debug} style={{top: 190, height: 450, }} />

                </Layout.Sidenav>
                <Layout.Content noPadding style={{paddingTop: 0, paddingLeft: 24, paddingRight: 24, minWidth2222: 830, overflow: 'hidden', }}>

                    <Loader type="preload" color="translucent" duration={1000} debug={debug} style={{top: 147, left: 0, width: '100%', height: 630,  }} />

                    <Sprite.Split id="lds-tasks" className={listCss}>
                        {true && <Sprite.Rows abs debug={debug} count={14} selection={selection} onChange={(i) => setSelection(i)} style={{zIndex: 1, top: 145, }} />}
                        
                        {/* <Loader type="preload" duration={2000} onComplete={onLoaderComplete} style={{top: 70, left: 0, width: '100%', height: 800, }} />
                        <Sprite.Button abs debug={debug} onClick={onClickToggle} style={{top: 140, width: '100%', height: 620, }} /> */}


                        {/* {showPanel && <Sprite.Row onClick={onClickToggle} abs gray style={{top: 146, }} />} */}

                        {/* {showPanel && <Sprite.Row abs style={{top: 189, }} />} */}
                        

                        <Sprite.Left />
                        <Sprite.Right />
                    </Sprite.Split>

                </Layout.Content>
                <Layout.Panel noPadding onClickClose={() => setSelection(null)}>

                        <TasksPanel selection={selection} />


                </Layout.Panel>
            </Layout.WithPanel>




        </React.Fragment>

    )
}