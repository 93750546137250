

import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Layout from "../../components/containers/Layout";
import Loader from "../../components/core/Loader";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import "./Tasks.scss";

const debug = 0;

const summaries = [
    "Mail out thank you card",
    "Please review this order.",
    "Contact Dr. Lane about updating...",
    "Process priority Rx’s",
    "New patient transfer from..",
    "Monthly walk through",
    "Weekly deep cleaning",
    "Price check Rx’s ",
    "Follow up with Dr. Lanes office for Derek M.",
    "Virtual wellness class Tb12286",
    "Prioritize call back list ",
    "New tech training ",
    "Refrigerator technician replacing gaskets ",
    "Call back south location ",
];

const brandSummaries = [
    "Flu Shot Campaign",
    "Prescription Volume and Adherence Review with District Managers",
    "End of Year MTMs",
    "New Prescription Growth, review with district and regional leader",
    "Diabetic Patients Star Rating",
]
const altSummaries = [
    "Flu shot Prep",
    "September MTMs",
    "Price check Rx’s ",
    "New patient transfer from..",
    "September CMRs",
    "Remaining tasks mix from EAP Figma",
    "Process priority Rx’s",
    "CII inventory cycle check",
    "Problematic prescription– Incorrect dosing, contact Dr. Xane",
]

// function Title({label}){
//     return (label && <div className="lds-patients-panel-title">{label}</div>);
// }

export default function Panel(props){
    const {selection} = props;
    const {context, } = useGlobals();

    // summary
    let summary = summaries[selection] || summaries[0];
    if(context?.isBrand && brandSummaries[selection]) summary = brandSummaries[selection];
    else if(context?.isAltStore && altSummaries[selection]) summary = altSummaries[selection];

    // render
    return (
        <Sprite.Container id="lds-tasks-panel" className={`is-selection-${selection}`}>
            
            {/* PANEL CONTENT */}
            <Loader.Reloader type="preload" color="translucent" index={selection} duration={1000}  />
            <div id="lds-tasks-panel-summary">{summary}</div>
            <div id="lds-tasks-panel-title">{utils.names[selection]}</div>

        </Sprite.Container>
    )
}