import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
// import ChatModal from "./MessagesChatModal";
// import { useGlobals, useDispatch } from '../../app/context';
// import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout"
import "./Settings.scss";

const debug = 0; // debug
const startTop = 61;
const height = 36;

const navItems = [
    ["general", "General Settings"],
    ["corporate", "Corporate"],
];

function General(){
    return (
        <Wrapper type="general"><Sprite.Container id="lds-settings-general" /></Wrapper>
    );
}

function Corporate(){
    return (
        <Wrapper type="corporate"><Sprite.Container id="lds-settings-corporate" /></Wrapper>
    );
}



// --- main --- //

function Item({item, index, type}){
    return <Layout.Item debug={debug} to={"/settings/" + item[0]} selected={item[0] === type} label={item[1]} style={{top: startTop + (height * index)}} />;
}

function Wrapper(props){
    const {type, children, } = props;
    const showPanel = false;
    const onClickToggle = () => {};


    // render
    return (
        <React.Fragment>

            {false && <Loader type="preload" duration={2000} onComplete={onClickToggle} style={{top: 70, left: 280, width: 1020, height: 680,  }} />}

            <Layout.WithPanel showPanel={showPanel}>
                <Layout.Sidenav settings spriteId="lds-settings-sidenav">
                    {navItems.map((item, index) => <Item type={type} item={item} index={index} />)}
                </Layout.Sidenav>
                <Layout.Content noPadding style={{paddingTop: 0, paddingLeft: 24, paddingBottom: 100, }}>
                    {children}
                </Layout.Content>
            </Layout.WithPanel>

        </React.Fragment>
    )
}

export default function Settings(){}

Settings.General = General;
Settings.Corporate = Corporate;