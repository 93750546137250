import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Loader from "../../components/core/Loader";
import { useGlobals, useDispatch } from '../../app/context';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Menu from "../../components/lists/Menu";
import "./Dashboard.scss";



const debug = 0;



export default function Page(){
    const nav = useNavigate();
    const dispatch = useDispatch();
    
    // const onClickTasksItem = () => {
    //     dispatch({call: "TOGGLE_TASKS_PANEL", value: true, });
    //     nav("/tasks");
    // }

    // const onClickTodayAppts = () => {
    //     dispatch({call: "TOGGLE_SCHEDULE_PANEL", value: true, });
    //     nav("/schedule");
    // }


    // render
    return (
        <React.Fragment>

            <div id="lds-dashboard2">
                <div id="lds-dashboard2-left">

                    <Sprite.Split id="lds-dashboard-alert" className="is-card is-cursor-text">
                        <Sprite.Left />
                        <Sprite.Right />
                    </Sprite.Split>

                    <Sprite.Split id="lds-dashboard-tasks" className="is-card">
                        <Sprite.Button abs to="/tasks" debug={debug} style={{width: 100, top: 0, height: 40, }} />
                        <Sprite.Button abs to="/tasks?showPanel=3" debug={debug} style={{width: '100%', top: 80, height: '100%', }} />

                        <Loader type="preload" duration={500} debug={debug} />

                        <Sprite.Left />
                        <Sprite.Right />
                    </Sprite.Split>

                    <div id="lds-dashboard2-bottom">
                        <div id="lds-dashboard2-bottom-box" className="is-card">

                            <Loader type="preload" duration={1000} debug={debug} />

                            <Sprite.Split id="lds-dashboard-messages">
                                <Sprite.Button abs to="/messages" debug={debug} style={{width: 200, top: 10, height: 40, }} />
                                <Sprite.Button abs to="/messages" debug={debug} style={{width: '100%', top: 60, height: '100%', }} />
                                <Sprite.Left />
                                <Sprite.Right />
                            </Sprite.Split>

                        </div>
                        <div id="lds-dashboard2-bottom-box" className="is-card">

                            <Loader type="preload" duration={1300} debug={debug} />

                            <Sprite.Split id="lds-dashboard-forms">
                                <Sprite.Button abs to="/forms" debug={debug} style={{width: 250, top: 10, height: 40, }} />
                                <Sprite.Button abs to="/forms/submissions" debug={debug} style={{width: '100%', top: 90, height: '100%', }} />
                                <Sprite.Left />
                                <Sprite.Right />
                            </Sprite.Split>

                        </div>
                    </div>
                    
                </div>
                <div id="lds-dashboard2-right">
                    
                    <Sprite.Container>
                        <Sprite.LikeText debug={debug} style={{top: 40, width: '100%', height: 80, }} />
                        <Sprite.Button debug={debug} style={{top: 230, width: '100%', height: 210, }} />
                        <Sprite.Button to="/schedule?showPanel=1" debug={debug} style={{top: 530, width: '100%', height: 430, }} />
                    </Sprite.Container>

                </div>
            </div>



            {/* <Loader type="preload" color="ltgray" style={{left: 0, width: 1420, }} /> */}
{/* 
            <Sprite.Container id="lds-dashboard" delay={0}>
                <Sprite.LikeText debug={debug} style={{top: 30, left: 30, width: 790, height: 80, }} />
                <Sprite.Button debug={debug} style={{top: 220, left: 30, width: 1010, height: 250, }} to="/tasks" />
                <Sprite.Button debug={debug} style={{top: 540, left: 30, width: 510, height: 410, }} to="/messages" />
                <Sprite.Button debug={debug} style={{top: 540, left: 560, width: 490, height: 450, }} to="/forms" />


                <Sprite.LikeText debug={debug} style={{top: 60, left: 1090, width: 300, height: 80, }} />
                <Sprite.Button debug={debug} style={{top: 250, left: 1090, width: 240, height: 220, }} />
            </Sprite.Container> */}

        </React.Fragment>
    );
}

//     width: 790px;
// height: 80px;
// top: 30px;
// left: 30px;