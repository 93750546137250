import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, useStepper, steps, } from "./Onboard2.config";
import "./Onboard2.scss";

const DEBUG = 1;


export default function PasswordPage(props){
    const {nextUrl, changeHandler, submitHandler, } = useStepper();
    const {onboard2, } = useGlobals();
    const dispatch = useDispatch();
    const [locals, setLocals] = React.useState({...onboard2, });

    // handlers 
    const onChange = (e) => changeHandler(e, locals, setLocals);
    const onSubmit = (e) => submitHandler(dispatch, locals, {}, true, );


    // render
    return (
        <Container icon222="clock" title="Login">

            <Sprite.Container className="lds-onboard2-login">


                <Input sprite label="Username" name="username" value={locals.username} onChange={onChange} style={{top: 96, left: 10, width: 300, }} />
                <Input type="password" sprite label="Password" name="password" value={locals.password} onChange={onChange} style={{top: 178, left: 10, width: 300, }} />


                <Sprite.Button to={nextUrl} debug={DEBUG} style={{top: 260,  }} />

            </Sprite.Container>

        </Container>
    );
}
