import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, useStepper, steps, } from "./Onboard2.config";
import "./Onboard2.scss";

const DEBUG = 0;


export default function Page(props){
    const {nextUrl, backUrl, } = useStepper();


    // render
    return (
        <Container icon="clock" title="Store Hours" description="These are your store’s general hours.">

            <Sprite.Container className="lds-onboard2-hours" icon="circle">

                <Sprite.Container style={{top: 1180, left: 110, }}>
                    <Sprite.Button to={backUrl} debug={1} style={{top: 0, width: 170, }} />
                    <Sprite.Button to={nextUrl} debug={1} style={{left: 180, width: 170, }} />
                </Sprite.Container>
                
            </Sprite.Container>

        </Container>
    );
}
