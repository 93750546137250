import React from "react";
import {Sprite, Select, Alert} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Modal from "../../components/containers/Modal";
import { Button } from "@mui/material";
// import "./Internal.scss";



const debug = 0;


export default function FilePickerModal(props){
    let {onSave, } = props;
    const dispatch = useDispatch();
    const {demos, } = useGlobals();
    const [formError, setFormError] = React.useState(null);
    const [selected, setSelected] = React.useState(false);

    const onDemoClick = (item) => {
        // dispatch({call: "SET_DEMO", value: item.name, });
        console.log("==== yoooooo: ", item);
    }

    const onClose = () => {
        dispatch({call: "HIDE_MODAL"});
    }

    if(!onSave) onSave = () => {
        dispatch({call: "HIDE_MODAL"});
    }
    // const onSave2 = () => {
    //     dispatch({call: "SET_ONBOARD1_VALUES", values: {hasLogo: true, }, });
    //     dispatch({call: "HIDE_MODAL"});
    // }

    const onClick = () => {
        setSelected(!selected);
    }
    // const items = [];

    return (
        <Modal size="lg" id="asdf" noLoader noWrapper={true} message={formError}>

            <Sprite.Container onClick={onClick} id="lds-demos-filepicker" className222={selected && "is-selected"}>

                {selected && <Sprite.Container id="lds-demos-filepicker-selected" />}
                <Sprite.Button onClick={onClose} debug={debug} style={{top: 430, left: 615, width: 80, height: 30, zIndex: 1, cursor: "default", }} />
                {selected && <Sprite.Button onClick={onSave} debug={debug} style={{top: 430, left: 705, width: 80, height: 30, zIndex: 1, cursor: "default", }} />}
                
            </Sprite.Container>
            
            
        </Modal>
    );
}

