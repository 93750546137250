import React from "react";
import Mobile from "../../components/containers/Mobile";
import { Sprite } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
// import MedsEdsheetModal from "./MedsEdsheetModal";
import {Input, } from "../../components/core";
import { useNavigate } from "react-router";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import ChatNewModal from "./ChatScreen";

const debug = 0;

export default function Modal(){
    const dispatch = useDispatch();
    const nav = useNavigate();
    const [sending, setSending] = React.useState(false);
    const [status, setStatus] = React.useState("gen");
    const onStatusClick = (item) => setStatus(item.value);
    const onCancel = () => dispatch({call: "HIDE_MODAL"});
    const onSend = () => setSending(true);
    const onCompleteLoader = () => utils.nav(nav, "/app/messages");


    return (
        <Mobile.Modal spriteId="lds-messages-chat-new" label="New Message">

            {sending && <Loader type="preload" color="translucent" duration={1000} onComplete={onCompleteLoader} />}
            <Input label="Message..." onEnter={onSend} sprite debug={debug} abs style={{top: 94, left: 22, width: 332, }} />


            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="sm" value="gen" style={{top: 328, left: 16, width: 120, }} />
            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="sm" value="med" style={{top: 363, left: 16, width: 120, }} />
            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="sm" value="appt" style={{top: 398, left: 16, width: 120, }} />
            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="sm" value="bill" style={{top: 328, left: 198, width: 120, }} />
            <Input.Radio debug={debug} onClick={onStatusClick} groupValue={status} name="status" size="sm" value="side" style={{top: 363, left: 198, width: 120, }} />


            <Sprite.Button debug={debug} onClick={onCancel} abs style={{top: 620, left: 20, width: 160, height: 50, }} />
            <Sprite.Button debug={debug} onClick={onSend} abs style={{top: 620, left: 200, width: 160, height: 50, }} />


        </Mobile.Modal>
    );
}
