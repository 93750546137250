import React from "react";
import utils from "./utils";
import {Module} from "../app/classes";
import {ValidationError} from "../app/errors";
import { useGlobals, useDispatch } from '../app/context';



// hooks

const hooks = {


    useValid: (inputs, fields) => {
        const errors = utils.validateValues(inputs, fields);
        if(errors) throw new ValidationError(inputs, errors); // yes
    },

    useRequired: (inputs, reqs, opts=null) => {
        const errors = utils.validateRequireds(inputs, reqs, opts);
        if(errors) throw new ValidationError(inputs, errors); // yes
    },

    useFormModal: (props2) => {
        let {onLoad, onSubmit, onDelete, id, module, defaults, onError, onSuccess, } = props2;
        utils.checkParam(module, Module, true, 'useFormModal.module'); // debug
        // let {id, defaults: defaults2, } = props || {};
        const {name, table} = module; // module
        const {rowClass} = table;
        const dispatch = useDispatch();
        const globals = useGlobals();


        // set init values (call once)
        React.useEffect(() => {
            if(!onLoad) return; // no init handler
            let values = {};

            if(id) { 
                // select table row
                values = table.selectByID(globals, id);
                if(!values) return dispatch({call: "HIDE_MODAL"}); // record missing (close modal)
            } else { 
                values = (new rowClass()).getValues();
                // load defaults
                if(defaults) utils.setDefaults(values, defaults);
            }

            // call init handler
            // const model = modelClass ? new modelClass(values) : {};
            onLoad(values);
        }, []);

        onError = (error) => { // if(!onError) 
            utils.showError("######### FORM MODAL ERROR", error);
            dispatch({call: "HIDE_MODAL"});
        }

        if(!onSuccess) onSuccess = (state, action) => {
            dispatch({call: "HIDE_MODAL"});
        }

        if(!onSubmit) onSubmit = (event) => {
            const inputs = utils.formInputs(event);
            dispatch({call: `${name}.SAVE`, values: inputs, onError, onSuccess, });
        }

        // submit handler
        const onSubmit2 = (event) => {
            event.preventDefault(); // prevent refresh
            utils.scrollToModalTop(); // auto scroll to top
            if(onSubmit) onSubmit(event);
        }

        // delete handler
        if(!onDelete && id) onDelete = (e) => {
            e.preventDefault();
            dispatch({call: `${name}.REMOVE`, id: id});
            dispatch({call: "HIDE_MODAL"});
        }

        // return modal props
        if(id) return {onSubmit: onSubmit2, buttons: {ok: ()=>{}, delete: onDelete, }, }; // edit
        return {onSubmit: onSubmit2, buttons: {ok: ()=>{}}, }; // add
    },

    useSorting: (sorting, rows2, setter, onChange) => { // --- WIP

        // on rows/sorting change
        React.useEffect(() => {
            const {sortby, ascending} = sorting;
            let rows = [...rows2];
            if(!rows.length) return; // no rows

            // create sortable values
            if(onChange) onChange(sorting, rows);

            // sortable?
            if(sortby && rows[0][sortby]){
                console.log("=============== SORTING: " + sortby);
                rows.sort((a, b) => {
                    if ( a[sortby] < b[sortby]) return ascending ? -1 : 1;
                    if ( a[sortby] > b[sortby]) return ascending ? 1 : -1;
                    return 0;
                });
            } 

            rows.pageTotal = 25;

            // utils.removeByID(rows, 2); // --- TEST

            // set rows
            setter(rows);

        }, [sorting, rows2]);
        
    },

};

export default hooks;



