import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import "./Videos.scss";

const debug = 0;

export function Sidenav(){
    return (
        <Sprite.Container>
            <Sprite.Button debug={debug} to="/videos" style={{top: 10, left: 10, width: 270, height: 440, }} />
            <Sprite.Button debug={debug} to="/videos/list" style={{top: 10, left: 150, width: 110, height: 50, zIndex: 1, }} />
        </Sprite.Container>
    );
}


// --- main --- //

export default function Screen(){
    return (
        <Sprite.Container id="lds-videos">
            <Sidenav />
            <Sprite.Button debug={debug} to="/videos/details" style={{top: 100, left: 300, width: 1040, height: 440, }} />
        </Sprite.Container>
    );
}
