import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Loader from "../../components/core/Loader";
import "./Classes.scss";

const debug = 0;


// --- main --- //

export default function Screen(){
    return (
        <React.Fragment>

            <Loader type="preload" duration={2000} style={{top: 70, left: 0, width: "100%", height: 700, }} />

            <Sprite.Split id="lds-classes">
                <Sprite.Left />
                <Sprite.Right />
            </Sprite.Split>

        </React.Fragment>



    );
}
