import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import "./Patients.scss";

const debug = 0;


export default function Page(){

    const onClickCTA = () => {};


    // render
    return (
        <React.Fragment>

            {/* {false && <Loader type="preload" duration={2000} onComplete={onLoaderComplete} style={{top: 70, left: 280, width: 1020, height: 680,  }} />} */}

            <Layout.WithPanel showPanel={false}>
                <Layout.Content>

                    <Sprite.Container id="lds-patients-activity">

                        <Loader type="preload" color="translucent222" duration={3000} debug222 style={{top: 424, height: 230, }} />
                        <Sprite.Button to="/patients/" debug={debug} style={{top: 10, }} />


                    </Sprite.Container>

                </Layout.Content>
            </Layout.WithPanel>




        </React.Fragment>

    )
}