import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Sprite from '../core/Sprite';
import Icon from '../core/Icon';
import { useGlobals, useDispatch } from '../../app/context';
import TabNav from "../nav/TabNav";
import Menu from "../lists/Menu";
import NotificationModal from "../../screens/general/NotificationModal";
import StoreLocatorModal from "../../screens/general/StoreLocatorModal";
import Loader from "../../components/core/Loader";
import utils from "../../functions/utils";
// import {} from "../../app/context";
import "./Mobile.scss";



const debug = 0; // debug


function Button(props){
    const {onClick, isDesktop} = props;
    const css = isDesktop ? 'lds-mobile-button-desktop' : 'lds-mobile-button';
    return <div onClick={onClick} className={css} />;
}


function Fade(props){
    const {onClick, open} = props;
    return open && (
        <Backdrop className="lds-mobile-fade" open={open} onClick={onClick} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} />
    )
}




// wrappers
const Inner = {home: 1, meds: 1, messages: 1, appts: 1, account: 1, };

function Wrapper(props){
    let {type, spriteId, spriteClass, children, topContent, name, noScroll, } = props;
    let Inner;
    children = spriteId ? <Sprite.Container id={spriteId} children={children} /> : children;
    if(spriteClass) children = <Sprite.Container className={spriteClass} children={children} />;

    switch(type){
        default:
        case 'home': Inner = HomeInnerWrapper; break;
        case 'tab': Inner = TabInnerWrapper; break;
        case 'sub': Inner = SubInnerWrapper; break;
        case 'basic': Inner = BasicInnerWrapper; break;
    }

    // render
    const css = utils.classNames("lds-mobile-wrapper is-type-" + type, name && `is-name-${name}`, topContent && 'is-topcontent', noScroll && "is-noscrolling");
    return (
        <div className={css}>{topContent}<Inner {...props} children={children} /></div>
    );
}

function HomeInnerWrapper(props){
    let {children, label } = props;
    const dispatch = useDispatch();
    const {showLoader, showNotification, } = useGlobals();

    // if(showNotification) dispatch({call: "SHOW_MODAL", component: <NotificationModal />});

    const onClickBell = () => dispatch({call: "SHOW_MODAL", component: <NotificationModal />});
    const onClickMarker = () => dispatch({call: "SHOW_MODAL", component: <StoreLocatorModal />});

    // render
    const style = showLoader ? {overflowY: 'hidden', } : undefined;
    return (
        <React.Fragment>
            <Sprite.Container className='lds-mobile-header'>
                <Sprite.Button debug={debug} style={{top: 'auto', bottom: 10, left: 286, width: 40, height: 40, }} onClick={onClickBell} />
                <Sprite.Button debug={debug} style={{top: 'auto', bottom: 10, left: 330, width: 40, height: 40, }} onClick={onClickMarker} />
                <b>{label}</b>
            </Sprite.Container>
            <div className='lds-mobile-scroll' style={style}>
                {showLoader && <Loader open duration={20000} />}
                {children}
            </div>
            <TabNav tab2="home" />
        </React.Fragment>
    );
}

function TabInnerWrapper(props){
    let {children, label, } = props;
    const dispatch = useDispatch();
    const {showLoader, } = useGlobals();

    const onClickBell = () => dispatch({call: "SHOW_MODAL", component: <NotificationModal />});
    const onClickMarker = () => dispatch({call: "SHOW_MODAL", component: <StoreLocatorModal />});

    return (
        <React.Fragment>
            <Sprite.Container className='lds-mobile-header'>
                <Sprite.Button debug={debug} style={{top: 'auto', bottom: 10, left: 286, width: 40, height: 40, }} onClick={onClickBell} />
                <Sprite.Button debug={debug} style={{top: 'auto', bottom: 10, left: 330, width: 40, height: 40, }} onClick={onClickMarker} />
                <b>{label}</b>
            </Sprite.Container>
            <div className='lds-mobile-scroll'>
                {showLoader && <Loader open duration={20000} />}
                {children}
            </div>
            <TabNav />
        </React.Fragment>
    );
}

function SubInnerWrapper(props){
    let {children, label, toBack, onBack, toExit, onExit, exitLabel } = props;
    const {showLoader, } = useGlobals();

    if(onBack) toBack = undefined;
    if(onExit) toExit = undefined;

    // back button?
    if(toBack || onBack) toBack = (
        <Sprite.Button debug={0} to={toBack} onClick={onBack} style={{top: 'auto', bottom: 0, left: 0, width: 40, height: 40, padding: '1px 0 0 5px', }}>
            <Icon.Svg type="chevron-left" size="xxl" color="white" />
        </Sprite.Button>
    );

    if(toExit || onExit) toExit = (
        <Sprite.Button debug={0} to={toExit} onClick={onExit} style={{top: 'auto', bottom: 0, left: 'auto', right: 0, width: 80, height: 40, padding: '7px 16px 0 0', color: "white", textAlign: "right", }}>
            {exitLabel ? exitLabel : "Cancel"}
        </Sprite.Button>
    );

    // render
    return (
        <React.Fragment>
            <Sprite.Container className='lds-mobile-header'>
                {toBack}
                <b>{label}</b>
                {toExit}
            </Sprite.Container>
            <div className='lds-mobile-scroll'>
                {showLoader && <Loader open duration={20000} />}
                {children}
            </div>
            {/* <TabNav /> */}
        </React.Fragment>
    );
}

function BasicInnerWrapper(props){
    let {children, } = props;
    const {showLoader, } = useGlobals();

    return (
        <React.Fragment>
            <div className='lds-mobile-scroll'>
                {showLoader && <Loader color="clear" open duration={20000} />}
                {children}
            </div>
        </React.Fragment>
    );
}



// function HomeWrapper(props){
//     const {children, } = props;
//     return (
//         <div className="lds-home-wrapper">
//             <div className='lds-mobile-header is-type-home'>
//                 <span></span>
//             </div>
//             <div className='lds-mobile-scroll'>{children}</div>
//             <TabNav tab2="home" />
//         </div>
//     );
// }


// modal


function Modal(props){
    var {label, children, id, className, onClose, spriteId, footer, footerHeight, noLoader, noScroll, topHeight = 0, } = props;
    const dispatch = useDispatch();

    if(!footerHeight) footerHeight = 98; // default footer hright
    if(!footer) footerHeight = 0;
    
    let contentHeight = 720 - footerHeight; // --- WIP

    const onClickClose = () => {
        if(onClose) onClose();
        else dispatch({call: "HIDE_MODAL"});
    }

    const onClickNone = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    // footer...
    contentHeight -= topHeight;

    // render
    const css = utils.classNames("lds-mobile-modal", className, (topHeight > 0) ? "is-bottomsheet" : "", noScroll && 'is-noscroll');
    return (
        <div className={css} id={id} onClick={onClickClose}>

            {!noLoader && <Loader type="preload" style={{top: 92 + topHeight, height: contentHeight, }} />}
            
            <div className="lds-mobile-modal-header" style={{marginTop: topHeight, }}>
                <div onClick={onClickClose} className="lds-mobile-modal-header-verytop" />
                {/* <Sprite.Button debug={debug} className="lds-mobile-modal-header-left" ><Icon.Svg type="x" size="lg" /></Sprite.Button> */}
                <b>{label}</b>
                <Sprite.Button debug={debug} onClick={onClickClose} className="lds-mobile-modal-header-right"><Icon.Svg type="x" size="lg" /></Sprite.Button>
            </div>
            <div className="lds-mobile-modal-content" onClick={onClickNone}  style={{height: contentHeight, }}>
                <Sprite.Container id={spriteId}>{children}</Sprite.Container>
            </div>
            {footer && <div className="lds-mobile-modal-footer" onClick={onClickNone} style={{height: footerHeight, }}>{footer}</div>}
        </div>
    );
}


// global 




function GlobalWrapper(props){
    const {onClick, open, children, onClickDesktop222, handleCloseMobile, showMobile, } = props;
    const nav = useNavigate();
    const dispatch = useDispatch();
    const globals = useGlobals();

    // 
    const onClickDesktop = () => {
        dispatch({call: "HIDE_MODAL"}); // bug fix 7/13
        dispatch({call: "SHOW_MOBILE"});
        setTimeout(() => {
          nav("/");
          dispatch({call: "HIDE_MOBILE"});
        }, 300);
      }
    
    const onClickLogout = () => utils.delayRedirect(dispatch, nav, "/app/login");

    // menu
    const items3 = [
        {label: "Web portal", to: "/webapp", icon: "globe",  },
        {label: "Refill notificiation", to: "/app/push/refillready", icon: "bell",  },
        {label: "Login screen", to222: "/app/login", icon: "lock", onClick: onClickLogout, }
    ];


    return (
        <div className='lds-mobile-global-wrapper' >
            

            <div className='lds-mobile-global-wrapper-left'>
                <div className='lds-mobile-global-wrapper-left-actions'>
                    

                    <Menu items={items3} position222="bottom-right" className="is-debug" width={250} style={{top: 50, left: 24,  position: "absolute",  }}>
                        <Sprite.Button abs debug={debug} style={{width: 40, height: 50, top: -40, }} />
                    </Menu>

                </div>
                {/* <a href="/app/login">Login</a>
                <a href="/app/push/refillready">Notification: Refill ready</a> */}
            </div>
            <div className='lds-mobile-phone'>
                <div className='lds-mobile-phone-notch' />
                
                <div id="lds-mobile-content" className='lds-mobile-content'>
                    {children}
                </div>

                <div className='lds-mobile-phone-mic' />
            </div>
            <Button isDesktop onClick={onClickDesktop} />
            <Mobile.Fade onClick={handleCloseMobile} open={showMobile} />
        </div>
    )
}

export default function Mobile(){}
Mobile.Fade = Fade;
Mobile.Button = Button;
Mobile.GlobalWrapper = GlobalWrapper;
Mobile.Wrapper = Wrapper;
Mobile.Modal = Modal;