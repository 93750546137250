import React from "react";
import utils from "../../functions/utils";
import "./Misc.scss";


function ChatDots(props){
    const {style, color, id, duration = 3000, onComplete, phone, } = props;
    const [show, setShow] = React.useState(true);

    React.useEffect(() => {
        if(!duration) return; // no auto hide
        setTimeout(() => {
            setShow(false);
            if(onComplete) onComplete(); // callback
        }, duration);
    }, []);

    const css = utils.classNames("lds-chatdots", color && `is-color-${color}`, phone && 'is-phone');
    return (show && <div style={style} id={id} className={css}><div className="lds-chatdots-inner"><Dots /></div></div>);
}

function Dots(props){
    const {style, color, } = props;
    const css = utils.classNames("lds-dots", color && `is-color-${color}`);
    return (<div className={css} style={style}><div /> <div /> <div /></div>);
}


// --- main --- //

export default function Misc(){}

Misc.Dots = Dots;
Misc.ChatDots = ChatDots;

