import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import ChatModal from "../messages/MessagesChatModal";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";

import TasksEditModal from "./TasksEditModal";
import "../messages/Messages.scss";
import "./Tasks.scss";


const debug = 0;

export default function Page(){
    const dispatch = useDispatch();
    // const {showTasksPanel} = useGlobals();
    const [showPanel, setPanel] = React.useState(false);
    const {state} = useLocation();

    const onCTA = () => dispatch({call: "SHOW_MODAL", component: <TasksEditModal />});
    // const onClickToggle = (value) => dispatch({call: "TOGGLE_TASKS_PANEL", value: value, });
    const onClickToggle = () => setPanel(!showPanel);
    const onLoaderComplete = () => {
        if(state?.showPanel) setPanel(true);
    };

    
    // render
    return (
        <React.Fragment>

            {false && <Loader type="preload" color="translucent" duration={2000} debug={debug} style={{top: 70, left: 280, width: 1020, height: 430,  }} />}
            {/* <Loader type="preload" duration={2000} style={{top: 70, left: 280, width: 1020, height: 680,  }} /> */}

            <Layout.WithPanel showPanel={showPanel}>
                <Layout.Sidenav spriteId="lds-tasks-sidenav-auto">

                    <Sprite.Button debug={debug} to="/tasks" style={{top: 20, left: 10, width: 80, }} />
                    <Sprite.Button debug={debug} to="/tasks/grid" style={{top: 20, left: 90, width: 80, }} />

                    <Layout.CTA debug={debug} onClick={onCTA} style={{top: 80, }} />


                </Layout.Sidenav>
                <Layout.Content noPadding style={{paddingTop: 0, paddingLeft: 24, paddingRight: 24, minWidth2222: 830, overflow: 'hidden', }}>


                    <Sprite.Container id="lds-tasks-auto">
                        <Input.Toggle on sprite style={{top: 89, left: 441, }} />
                        <Input.Toggle on sprite style={{top: 229, left: 441, }} />
                        <Input.Toggle on sprite style={{top: 369, left: 441, }} />
                        <Input.Toggle on sprite style={{top: 89, left: 948, }} />
                        <Input.Toggle on sprite style={{top: 229, left: 948, }} />
                    </Sprite.Container>


                </Layout.Content>
            </Layout.WithPanel>




        </React.Fragment>

    )
}