import classNames from 'classnames';
import types from './types';
import formats from './formats';
import {ValueInvalidError, ValueRequiredError, ParamError} from "../app/errors";

const names = [
    "Arlene McCoy",
    "Kisten Watson",
    "Courtney Henry",
    "Theressa Web",
    "Marvin McKinney",
    "Savanna Smith",
    "Jason Paterson",
    "Courtney Henry",
    "Arlene McCoy",
    "Kisten Watson",
    "Courtney Henry",
    "Theressa Web",
    "Marvin McKinney",
    "Savanna Smith",
    "Jason Paterson",
    "Courtney Henry",
]

const utils = {
    classNames, // for convenience
    delayedRedirect: false,
    names,

    // changeLocals: (e, locals, setter, ) => {
    //     // console.log("VALUE: " + e.target.name + ": " + e.target.value);
    //     let obj = {};
    //     obj[`${e.target.name}`] = e.target.value;
    //     setter({...locals    , ...obj, });
    // },

    showPanel: (selection) => {
        return selection !== null;
    },

    count: 0, 
    typing: (e, setter, messages, count) => {
        const value = e.target.value;
        const str = messages[count];
        if(typeof str !== "string") setter(value);
        else setter(str.substring(0, value.length + 3));

        // if(messages[count]) setter(messages[count].substring(0, messages[count].length + 1));
        // else setter(value);

        // // setter(str.substring());
    },

    nav: (nav, to) => {
        if(typeof to !== 'string') return; // not string
        // parse
        const [url, uri] = to.split('?');

        // has params?
        const params = {};
        if(uri) uri.split('&').forEach(str => {
            const [key, value] = str.split('=');
            params[key] = value;
        });

        // go to url
        nav(url, {state: params});
    },

    logout: (dispatch, nav) => {
        utils.delayRedirect(dispatch, nav, "/logout", 1000);
    },

    delayRedirect: (dispatch, nav, url, duration = 1500) => {
        if(utils.delayedRedirect) return;
        utils.delayedRedirect = true;
        dispatch({call: "SHOW_LOADER", });
        setTimeout(() => {
            utils.delayedRedirect = false;
            dispatch({call: "HIDE_LOADER", });
            utils.nav(nav, url || "/");
        }, duration);
    },

    delayCall: (dispatch, func, duration = 1500) => {
        if(utils.delayedRedirect) return;
        utils.delayedRedirect = true;
        dispatch({call: "SHOW_LOADER", });
        setTimeout(() => {
            utils.delayedRedirect = false;
            dispatch({call: "HIDE_LOADER", });
            func(); // func
        }, duration);
    },

    showError: (note, error) => {
        if(error === undefined){
            error = note;
            note = "";
        }
        if(!types.object(error)) return;
        let obj = {};
        if(error.errors){
            Object.keys(error.errors).forEach(key => {
                obj[key] = error.errors[key].getCode();
            });
        }
        console.warn("========= SHOW ERROR: " + note, {code: error.getCode && error.getCode(), inputs: error.inputs, errors: obj});
    },

    scrollToModalTop: () => {
        var mydiv = document.getElementsByClassName('MuiPaper-root');
        if(mydiv.length) mydiv[0].scrollTo({top: 0, behavior: "smooth", }); // behavior222: "smooth"
    },

    msgPrefixes: {e: "error", n: "notice", a: "affirm", t: "text", i: "info", },
    getMessagePrefix: (code) => {
        if(typeof code === 'string') return utils.msgPrefixes[code[0]] || "";
    },

    validate: (value, type, required) => {
        if(required){
            if(value === undefined || value === '') return [undefined, new ValueRequiredError({type, value, required})];
        }
        if(value === undefined) return [undefined, undefined];
        const cast = utils.cast(value, type);
        if(cast === undefined) return [value, new ValueInvalidError({type, value, required})];
        return [cast, undefined];
    },

    checkParam: (value, type, required, param) => { // param example: 'useValid.fields'
        const [value2, e] = utils.validate(value, type, required);
        if(e) throw new ParamError({value, type, required, param});
    },

    validateValues: (values, fields) => {
        fields = utils.cast(fields, 'fields');
        utils.checkParam(values, 'object', true, 'validateValues.values'); // debug
        utils.checkParam(fields, 'object', true, 'validateValues.fields'); // debug

        // iterate fields
        let errors = {};
        Object.keys(fields).forEach(key => {
            const field = fields[key];
            const [value2, error] = utils.validate(values[key], field.type, field.required);
            if(error) errors[key] = error;
            values[key] = value2;
        })

        // has errors?
        if(Object.keys(errors).length) return errors; //  return new ValidationError(values, errors); // yes
    },

    validateRequireds: (values, reqs, opts=null) => {
        let errors = {};

        if(reqs){
            Object.keys(reqs).forEach(key => {
                const [cast, error] = utils.validate(values[key], reqs[key], true);
                if(error) errors[key] = error;
                values[key] = cast;
            })
        }

        if(opts){
            Object.keys(reqs).forEach(key => {
                const [cast, error] = utils.validate(values[key], opts[key], false);
                if(error) errors[key] = error;
                values[key] = cast;
            })
        }

        // has errors?
        if(Object.keys(errors).length) return errors; // new ValidationError(values, errors); // yes
    },

    formInputs: (e) => {
        let obj = {};
        const len = e.target?.length;
        if(len){
            for(var i=0; i<len; i++){
                const input = e.target[i];
                if(input.name) obj[input.name] = input.value;
            }
        }
        return obj;
    },

    format: (value, type) => {
        if(formats[type]) return formats[type](value);
        console.warn("Invalid format type: ", type);
    },


    // --- query --- //

    insertRow: (state, table, values) => {

        return {...state, };
    },


    // --- types --- //

    cast: (value, type, _else) => {

        // type is class
        if(typeof type === 'function'){
            // console.log("############## 555555: ", type);
            if(value instanceof type) return value;

            // if(typeof value === 'object') return new type(value);
            return _else;
        }

        // type is other
        if(!types[type]) return console.warn("Invalid cast type: ", type);
        value = types[type](value);
        return (value === undefined || value === null) ? _else : value;
    },

    mustCast: (value, type, _else) => {
        value = utils.cast(value, type);
        if(value === undefined) throw new Error("Must cast: ", {type, value});
        // if(value === undefined) utils.error('e-value-invalid', {value, type});
        return value;
    },

    isType: (value, type) => {
        if(!types[type]) return console.warn("Invalid type: ", type);
        value = types[type](value);
        return (value === undefined) ? false : true;
    },

    isString: (value) => utils.isType(value, 'string'),
    isObject: (value) => utils.isType(value, 'object'),
    isArray: (value) => utils.isType(value, 'array'),
    isNull: (value) => (value === null || value === undefined),
    isEmpty: (value) => (value === null || value === undefined || value === ''),
    isFalse: (value) => (value === null || value === undefined || value === '' || !value),




    // --- items --- //

    getItem: (obj, name, _else) => {
        const value = obj[name];
        return value === undefined ? _else : value;
    },

    getItemAs: (obj, name, type) => {
        return utils.cast(obj[name], type);
    },

    setItem: (obj, name, value) => {
        if(value !== undefined) obj[name] = value;
        return obj;
    },

    getItems: (map, names) => {
        let obj = {};
        names.forEach(name => {
            const value = map[name];
            if(value !== undefined) obj[name] = value;
        })
        return obj;
    },

    removeItems: (map, names) => {
        let removes = [];
        names.forEach(name => {
            const value = map[name];
            if(value !== undefined){
                delete map[name];
                removes[name] = value;
            }
        })
        return removes;
    },

    removeItem: (obj, name) => {
        const value = obj[name];
        if(value !== undefined) delete obj[name];
        return value;
    },

    setItems: (obj, values) => {
        Object.keys(values).forEach(key => {
            obj[key] = values[key];
        });
        return obj;
    },

    setDefaults: (obj, defaults) => {
        Object.keys(defaults).forEach(key => {
            if(obj[key] === undefined) obj[key] = defaults[key];
        });
        return obj;
    },

    // END OF OBJECT
};


export default utils;