import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, useStepper, steps, } from "./Onboard2.config";
import "./Onboard2.scss";

const DEBUG = 0;


export default function Page(props){
    const dispatch = useDispatch();
    const {onboard2, } = useGlobals();
    const {nextUrl, backUrl, changeHandler, submitHandler, } = useStepper();
    const [locals, setLocals] = React.useState({...onboard2, });

    const onChange = (e) => changeHandler(e, locals, setLocals);
    const onSubmit = (e) => submitHandler(dispatch, locals, );

    // render
    return (
        <Container icon="clock" title="Employees" description="Enter employees and assign them a role. Roles determine what actions an employee has permission to take. Use the following descriptions to assign the correct role.">

            <Sprite.Container className="lds-onboard2-employees">

                <Sprite.Button debug={DEBUG} style={{top: 0, width: 120, }} />
                
                <Sprite.Container debug={DEBUG} style={{top: 50, width: '100%', height: 290, cursor: 'text', }} />

                <Sprite.Button debug={DEBUG} style={{top: 340, left: 'auto', right: 0, width: 120, height: 30, }} />

                {/* <Input sprite debug={0} label="Username" name="username" value={locals.username} onChange={onChange} style={{top: 56, left: 10, width: 136, }} />
                <Input sprite debug={0} label="Username" name="username" value={locals.username} onChange={onChange} style={{top: 56, left: 158, width: 136, }} /> */}


                <Sprite.Container style={{top: 420, left: 170, }}>
                    <Sprite.Button debug={1} to={backUrl} style={{top: 0, width: 170, }} />
                    <Sprite.Button debug={1} onClick={onSubmit} style={{left: 180, width: 170, }} />
                </Sprite.Container>
                
            </Sprite.Container>

        </Container>
    );
}
