import {LABELS} from "./settings";
import utils from "../functions/utils";


export class BaseError extends Error // abstract
{

    constructor(inputs, errors){
        super();
        // const code = this.message = this.getCode();
        this.inputs = inputs;
        this.errors = errors;

        console.warn(this.getCode() + ', INPUTS: ', inputs, ', ERRORS: ', errors);
    }

    getClass(){throw new Error('Must override getClass()');}
    getFields(){return {};}

    getCode(){throw new Error('Must override getCode()');}

    getPrefix(){return utils.getMessagePrefix(this.getCode());}
    getMessage(){return this.message || LABELS[this.getCode()] || this.getCode();}

    // END CLASS
}




// --- critical --- //

export class CriticalError extends BaseError {} // abstract

export class ParamError extends CriticalError {
    getCode(){return 'e-param-error';}
    getClass(){return ParamError;}
}


// --- non-critical --- //

export class NonCriticalError extends BaseError {} // abstract

export class ValidationError extends NonCriticalError {
    getCode(){return 'e-validation-error';}
    getClass(){return ValidationError;}
}

// value errors
export class ValueError extends NonCriticalError {} // abstract

export class ValueInvalidError extends ValueError {
    getCode(){return 'e-value-invalid';}
    getClass(){return ValueInvalidError;}
}

export class ValueRequiredError extends ValueError {
    getCode(){return 'e-value-required';}
    getClass(){return ValueRequiredError;}
}


