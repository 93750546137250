import utils from "../functions/utils"; 
import hooks from "../functions/hooks"; 
import {LABELS, CONFIGS} from "./settings";




export function classFields(newClass, baseClass, fields){
    const basefields = baseClass?.FIELDS || {};
    var newfields = newClass.FIELDS = {};

    if(basefields){
        Object.keys(basefields).forEach(name => {
            const field = basefields[name];
            newfields[name] = {...basefields[name]};
        });
    }

    if(fields){
        Object.keys(fields).forEach(name => newfields[name] = fields[name]);
    }
}


// --- base class --- //


export class BaseClass // abstract
{
    constructor(props){

        // load field defaults
        const fields = this.getFields();
        if(fields) Object.keys(fields).forEach(key => this[key] = fields[key].value);
        

        // load init values
        this.init();

        // set props
        if(props){
            let that = this;
            Object.keys(props).forEach(key => that[key] = props[key]);
            this.normalize();
        }
        
        // debug
        if(CONFIGS.DEBUG) this.getClass();
    }
    
    init(){}

    getFields(){return this.getClass().FIELDS;}

    getClass(){throw new Error('Must override getClass()');} // MUST OVERRIDE


    set(name, value){
        this[name] = value;
        return this;
    }

    get(name, _else){
        return this[name] || _else;
    }

    setValues(values){
        utils.setItems(this, values);
        return this.normalize();
    }

    getValues(names){
        if(names) return utils.getItems(this, names);
        let obj = {};
        Object.keys(this).forEach(key => {
            obj[key] = this[key];
        });
        return obj;
    }
    
    copy(){
        const c = this.getClass();
        return new c(this);
    }

    normalize(){
        let that = this;
        const fields = this.getFields();
        if(fields){
            Object.keys(fields).forEach(key => {
                that[key] = utils.cast(that[key], fields[key].type);
            })
        }
        return this;
    }

    validate(){ // override
        
    }

    check(){ // override
        // const e = this.validate();
        // --- WIP
        // if(e) throw new Error('asdf');
        return this;
    }

}


export class Model extends BaseClass // abstract
{
}

export class Controller extends BaseClass // abstract
{

    init(){
        super.init();
        this.handlers = {};
    }

    on(name, func){
        if(this.handlers[name]) this.handlers[name].push(func);
        else this.handlers[name] = this.handlers[name] = [func];
        return this;
    }

    trigger(name, props){
        if(this.handlers[name]) this.handlers[name].forEach(func => func(props)); // (props);
        return this;
    }

    // END CLASS
}

export class Module extends Controller
{
    getClass(){return Module;}

    init(){
        super.init();
        this.app = undefined; // see load()
        this.name = "--NAME--";
        this.table = undefined;
        this.reducers = ['SAVE', 'ADD', 'EDIT', 'REMOVE', 'FIND'];
    }

    load(state){}

    on(name, func){
        if(this.app) this.app.on(name, func)
        else super.on(name, func);
        return this;
    }

    trigger(name, props){
        if(this.app) this.app.trigger(name, props)
        else super.trigger(name, props);
        return this;
    }

    // reducers


    SAVE(state, action){
        const {values} = action;
        return values?.id ? this.EDIT(state, action) : this.ADD(state, action);
    }

    ADD(state, action){
        this.table.insert(state, action.values);
        return {...state};
    }

    EDIT(state, action){
        utils.checkParam(action?.values, "map", true, "EDIT() action.values"); // debug
        hooks.useValid(action.values, this.table.rowClass.FIELDS);
        console.log("==== EDIT 222", action.values);
        // this.table.update(state, action.values);
        return {...state};
    }

    REMOVE(state, action){
        this.table.removeByID(state, action.id);
        return {...state};
    }

    FIND(state, action){
        this.table.select(state, action.id);
        return {...state};
    }

    // END CLASS
}



export class App extends Module
{
    getClass(){return App;}

    init(){
        super.init();
        this.app = undefined;
        this.name = "app";
    }


    // END CLASS
}





// --- table --- //

export class Table extends Controller {

    getClass(){return Table;}


    init(){
        super.init();
        this.name = "--NAME--"; // required
        this.nextid = 1;
        this.rowClass = undefined; // required
    }

    loadState(state){
        const rows = state[`${this.name}_rows`];
        if(!rows) return;

        // create model
        const c = this.rowClass;
        rows.map(row => new c(row));
        this.nextid = rows.length;
    }


    // rows

    selectByID(state, id, is_ref){
        return this.select(state, row => (row.id == id), is_ref);
    }

    select(state, func, is_ref){
        const rows = state[`${this.name}_rows`];
        if(!rows) return;
        const item = rows.find(func);
        if(is_ref) return item; // return a reference
        const c = this.rowClass; 
        return new c(item); // return a copy (default)
    }

    selects(state, func, get_index){
        // --- WIP
    }

    insert(state, values){
        values = utils.mustCast(values, "object");
        // hooks.useValidation(values, this.table.rowClass);
        const rows = state[`${this.name}_rows`];
        const c = this.rowClass;
        const model = new c(values);
        model.id = this.nextid++; // --- WIP
        rows.push(model);
        console.log("++++++ INSERT 222: ", values, " ROWS: ", rows);
        return model.id;
    }

    update(state, values){

        values = utils.mustCast(values, "object");
        const id = utils.mustCast(values.id, "int");
        delete values.id;
        const row = this.selectByID(state, id);
        utils.setItems(row, values);
        return row;
    }

    // remove(state, func){
    //     const rows = state[`${this.name}_rows`];
    //     const index = this.findByID(state, id, true);
    //     rows.splice(index, 1);
    // }

    removeByID(state, id){
        id = utils.mustCast(id, "int");
        const rows = state[`${this.name}_rows`];
        const ref = this.selectByID(state, id, true);
        const index = rows.indexOf(ref);
        rows.splice(index, 1);
    }

    // END OF CLASS
}


classFields(Table, Controller, {
    name: {
        type: "string", 
        required: true,
    },
    rowClass: {
        type: "class", 
        required: true,
    },
})



// --- message --- //


export class Message extends BaseClass
{
    getClass(){return Message;}


    init(){
        super.init();
        this.code = '--CODE--';
        this.message = '--CODE--';
        this.items = {};
    }

    getCode(){return this.code;}
    getPrefix(){return utils.getMessagePrefix(this.code);}
    getMessage(){return this.message || LABELS[this.getCode()] || this.getCode();}

    // END CLASS
}




