import { GlobalsProvider } from './context.js';
import Main from './Main.js';
// import './app.scss';
// import './images.scss';
// import './variables.scss';

// console.log("############### 111");

export default function Root() {

  return (
    <GlobalsProvider>
      <Main />
    </GlobalsProvider>
  );
}

