import React from "react";
import utils from "../../functions/utils";
import Icon from "../core/Icon";
import "./List.scss";


function TopRow(props){
    const {children} = props;
    return <tr className="lds-list-row is-top">{children}</tr>;
}

function Row(props){
    const {children, index, name, className} = props;
    const css = utils.classNames("lds-list-row is-index-" + index, className, !(index%2) && "is-alt");
    return <tr className={css}>{children}</tr>;
}

function TopCell(props){
    const {item, onClick, sortby, ascending} = props;
    const {label, name, className, style, sortable} = item;
    const css = utils.classNames("lds-list-cell is-top", className, name && `is-name-${name}`, (sortable !== false) && 'is-sortable');
    const sorted = (sortby === name);
    let sortsymbol = sorted ? "*" : "";
    if(sorted) sortsymbol = ascending ? "*" : "**";
    return <th onClick={onClick} className={css} style={style}>{label}{sortsymbol}</th>;
}

function Cell(props){
    let {children, label, name, onClick, className, format} = props;
    const css = utils.classNames("lds-list-cell", className, name && `is-name-${name}`);
    if(format) label = utils.format(label, format);
    return <td onClick={onClick} className={css}>{label || children}</td>;
}


// --- list --- //

function List(props){
    let {children, name, headers, className, id, style, onClickHeader, sorting} = props;
    const {sortby, ascending} = (sorting || {}); // sorting

    // convert headers
    headers = headers.map(item => {
        if(typeof item === "string") item = {name: item, label: item};
        // item.sorted = (sorting.sortby === item.name);
        return item;
        // return (typeof item === "string" ? {name: item, label: item} : item)
    });

    // handler
    const onClickHeader2 = (item) => {
        console.log("=========: onClickHeader");
        if(onClickHeader && item.sortable !== false) onClickHeader(item);
        // if(onChange) onChange(selected);
    }

    // render
    const css = utils.classNames("lds-list", className, name && `is-name-${name}`);
    return (
        <table className={css} id={id} style={style}>
            <thead>
                <TopRow>{headers.map((item, key) => <TopCell onClick={() => onClickHeader2(item)} key={key} item={item} sortby={sortby} ascending={ascending} />)}</TopRow>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    );
}


// public
List.Row = Row;
List.Cell = Cell;
List.TopRow = TopRow;
List.TopCell = TopCell;

export default List;
