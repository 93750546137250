import React from "react";
import utils from "../../functions/utils";
import "./Icon.scss";


const colors = {
    white: "ffffff",
    gray5: "748995",
}

const sizes = {
    sm: 16, 
    md: 20, 
    lg: 24, 
    xl: 28, 
    xxl: 32, 
};

const paths = {
    "circle":           "M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6023 1.66667 9.99996 1.66667C5.39759 1.66667 1.66663 5.39763 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z",
    "chevron-right":    "M7.5 15L12.5 10L7.5 5",
    "chevron-left":     "M12.5 15L7.5 10L12.5 5",
    "x":                "M15 5L5 15M5 5L15 15",
}


// --- svg 

export function Svg(props){
    let {type, size, selected, color, className, style, onClick} = props;

    // paths
    if(!paths[type]) type = 'circle';
    const path = paths[type];

    // sizes
    if(!sizes[size]) size = 'md';
    const dim = sizes[size];

    // color
    if(!color) color = 'gray5';

    // render
    const css = utils.classNames("lds-icon-svg is-type-" + type + " is-size-" + size, className, selected && "is-selected"); // , color && `is-stroke-${color}`
    return (
        <div className={css} onClick={onClick} style={style}>
            <svg width={dim} height={dim} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d={path} className={`is-stroke-${color}`}/>
            </svg>
        </div>
    );
}


// --- basic --- //

export default function Icon(props) {
    const {type, size, selected, color, className, style, id} = props;
    const css = utils.classNames("lds-icon is-type-" + type, size && `is-size-${size}`, className, selected && "is-selected", color && `is-color-${color}`);
    return <i className={css} style={style} id={id} />
}

Icon.Svg = Svg;
