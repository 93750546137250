import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Mobile from "../../components/containers/Mobile";
import Loader from "../../components/core/Loader";

const debug = 0;




function People(){
    return (
        <Mobile.Wrapper type="sub" toBack="/app/account/" label="People Under Your Care" exitLabel="Add" toExit="/app/account/people/add" spriteId="lds-app-account-people">
            
            <Loader type="preload" color="translucent" debug={debug} duration={2000} style={{height: 712, }} />
            
        </Mobile.Wrapper>
    ); 
}

// main
export default function Account(){
    return (
        <Mobile.Wrapper type="tab" label="Your Account" spriteId="lds-app-account">

            {/* <Sprite.Button debug={debug} style={{width: '100%', height: 70, top: 12, }} to="medical" /> */}
            <Sprite.Button debug={debug} style={{width: '100%', height: 70, top: 82, }} to="personal" />
            <Sprite.Button debug={debug} style={{width: '100%', height: 70, top: 222, }} to="people" />
            {/* <Sprite.Button debug={debug} style={{width: '100%', height: 70, top: 152, }} to="insurance" />
            <Sprite.Button debug={debug} style={{width: '100%', height: 70, top: 222, }} to="people" />
            <Sprite.Button debug={debug} style={{width: '100%', height: 70, top: 292, }} to="forms" />
            <Sprite.Button debug={debug} style={{width: '100%', height: 70, top: 362, }} to="comms" /> */}

        </Mobile.Wrapper>
    );
}

Account.People = People;
