import users from "../users/users.data";

let i = 1;
let data = [
    {
        id: i++,
        status: 1,
        type: "teal",
        selected: true,
        bookmarked: false,
        dueDate: Number(new Date("4/3/2023 2:05 PM")),
        name222: "My first task 111",
        name: "Mail out thank you card",
        assignee: users[0],
    },
    {
        id: i++,
        status: "resolved",
        type: "teal",
        selected: true,
        bookmarked: true,
        dueDate: Number(new Date("4/4/2023 3:05 PM")),
        name222: "My first task 111",
        name: "Mail out thank you card",
        assignee: users[1],
    },
    {
        id: i++,
        status: 1,
        // type: "teal",
        selected: true,
        bookmarked: true,
        dueDate: Number(new Date("4/5/2023 4:05 PM")),
        name222: "My first task 111",
        name: "Maecenas eget condimentum velit, sit amet",
        assignee: users[2],
    },
    {
        id: i++,
        status: 1,
        // type: "teal",
        selected: true,
        bookmarked: false,
        dueDate: Number(new Date("4/6/2023 5:05 PM")),
        name222: "My first task 111",
        name: "Sed dignissim, metus nec fringilla accumsan, risus sem",
        assignee: users[3],
    },
    {
        id: i++,
        status: 1,
        // type: "teal",
        selected: true,
        bookmarked: false,
        dueDate: Number(new Date("4/7/2023 6:05 PM")),
        name222: "My first task 111",
        name: "ollicitudin lacus, ut interdum tellus elit sed risus",
        assignee: users[4],
    },
    {
        id: i++,
        status: 1,
        // type: "teal",
        selected: true,
        bookmarked: false,
        dueDate: Number(new Date("4/8/2023 7:05 PM")),
        name222: "My first task 111",
        name: "Praesent auctor purus luctus enim egestas",
        assignee: users[6],
    },
];



export default data;