import React from "react";
import utils from "../../functions/utils";
import "./Text.scss";


function Chain(props){
    let {children, gap, className, style, valign} = props;

    // gap
    if(gap !== undefined){
        if(!style) style = {};
        style.gap = gap;
    }

    // return
    const css = utils.classNames("lds-chain", className, valign && `is-valign-${valign}`);
    return <div className={css} style={style}>{children}</div>
}

function Badge(props){
    return <Text {...props} baseClass="lds-badge" />;
}

function Label(props){
    return <Text {...props} baseClass="lds-Label" />;
}

function Link(props){
    return <Text {...props} baseClass="lds-Label" />;
}





// --- main --- //

function Text(props){
    const {type, className, size, color, onClick, children, label, baseClass} = props;
    const css = utils.classNames(baseClass || "lds-text", size && `is-text-${size}`, color && `is-${color}`, type && `is-type-${type}`, className);
    return <div className={css} onClick={onClick}>{label || children}</div>
}


// public
Text.Badge = Badge;
Text.Label = Label;
Text.Chain = Chain;

export default Text;