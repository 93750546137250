import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
// import "./Marketing.scss";
import "./Dashboard.scss";

const debug = 0;


export default function Page(){

    return (
        <div id="lds-dashboard3">
            <div id="lds-dashboard3-left">


                <Sprite.Container id="lds-dashboard-sidenav2" />

            </div>
            <div id="lds-dashboard3-right">
                


                <Sprite.Split id="lds-dashboard-alert" className="is-card is-cursor-text">
                    <Sprite.Left />
                    <Sprite.Right />
                </Sprite.Split>


                <Sprite.Split id="lds-dashboard-tasks" className="is-card">
                    <Sprite.Button abs to="/tasks" debug={debug} style={{width: 100, top: 0, height: 40, }} />
                    <Sprite.Button abs to="/tasks?showPanel=3" debug={debug} style={{width: '100%', top: 80, height: '100%', }} />

                    <Loader type="preload" duration={500} debug={debug} />

                    <Sprite.Left />
                    <Sprite.Right />
                </Sprite.Split>


                <div id="lds-dashboard3-bottom-box" className="is-card">

                    <Loader type="preload" duration={1300} debug={debug} />

                    <Sprite.Split id="lds-dashboard-forms">
                        <Sprite.Button abs to="/forms" debug={debug} style={{width: 250, top: 10, height: 40, }} />
                        <Sprite.Button abs to="/forms/submissions" debug={debug} style={{width: '100%', top: 90, height: '100%', }} />
                        <Sprite.Left />
                        <Sprite.Right />
                    </Sprite.Split>

                </div>


                <div id="lds-dashboard4-bottom-box" className="is-card">

                    <Loader type="preload" duration={1300} debug={debug} />

                    <Sprite.Split id="lds-dashboard-messages">
                        <Sprite.Button abs to="/messages" debug={debug} style={{width: 200, top: 10, height: 40, }} />
                        <Sprite.Button abs to="/messages" debug={debug} style={{width: '100%', top: 60, height: '100%', }} />
                        <Sprite.Left />
                        <Sprite.Right />
                    </Sprite.Split>

                </div>





            </div>


        </div>
    );
}

export function Page222(){

    const onClickCTA = () => {};


    // render
    return (
        <div>

            {/* {false && <Loader type="preload" duration={2000} onComplete={onLoaderComplete} style={{top: 70, left: 280, width: 1020, height: 680,  }} />} */}

            <Layout.WithPanel id="lds-dashboard3" showPanel={false}>
                <Layout.Sidenav spriteId="lds-dashboard3-sidenav">

                    {/* <Layout.CTA onClick={onClickCTA} debug={debug} /> */}

                </Layout.Sidenav>
                <Layout.Content>

                    
{/* 
                    <h1>TESTING</h1>

                    <Sprite.Container id="lds-marketing-library">

                        <Input.Toggle on={true} sprite style={{top: 167, left: 363, }} />
                        <Input.Toggle on={true} sprite style={{top: 307, left: 363, }} />
                        <Input.Toggle on={true} sprite style={{top: 167, left: 871, }} />

                    </Sprite.Container> */}

                </Layout.Content>
            </Layout.WithPanel>




        </div>

    )
}