import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
// import Sidenav from "../../components/containers/Sidenav";
// import Mobile from "../../components/containers/Mobile";
import "./Refills.scss";

const debug = 0;

export default function Screen(){
    return (
        <Sprite.Split id="lds-refills">
            <Sprite.Left />
            <Sprite.Right />
        </Sprite.Split>
    );
}
