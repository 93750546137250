import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Button} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import Mobile from "../../components/containers/Mobile";
import NotificationModal from "../../screens/general/NotificationModal";
import Loader from "../../components/core/Loader";
import ChatNewModal from "./ChatNewModal";

const debug = 0;

// const onClickUpload = () => {

// }

export default function Page(){
    const dispatch = useDispatch();
    const onCTA = () => dispatch({call: "SHOW_MODAL", component: <ChatNewModal />});

    // render
    return (
        <React.Fragment>

            <Loader type="preload" color="translucent" debug={debug} duration={2000} style={{height: 812, }} />

            <Button to222="/app/meds/refill" onClick={onCTA} type="primary" mobileBottom icon="editor-white" shadow label="New message" />
            <Mobile.Wrapper type="tab" label="Messages" spriteId="lds-app-messages">

                <Sprite.Button to="/app/messages/chat" onClick={onCTA} debug={debug} style={{top: 60, width: '100%', height: 320, }} />

            </Mobile.Wrapper>

        </React.Fragment>
    );
}
