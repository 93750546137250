import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import utils from "../../functions/utils";
import {Container, useStepper, } from "./Onboard2.config";
import "./Onboard2.scss";

const DEBUG = 0;


export default function PasswordPage(props){
    const dispatch = useDispatch();
    const {onboard2, } = useGlobals();
    const {nextUrl, changeHandler, submitHandler, } = useStepper();
    const [locals, setLocals] = React.useState({...onboard2, });

    // handlers 
    const onChange = (e) => changeHandler(e, locals, setLocals);
    const onSubmit = (e) => submitHandler(dispatch, locals, );

    // render
    return (
        <Container icon222="clock" title="Create password">

            <Sprite.Container className="lds-onboard2-password">

                <Input type="password" sprite label="Password" name="password" value={locals.password} onChange={onChange} style={{top: 94, left: 10, width: 300, }} />
                <Input type="password" sprite label="Confirm password" name="password_confirm" value={locals.password_confirm} onChange={onChange} style={{top: 176, left: 10, width: 300, }} />

                <Sprite.Button onClick={onSubmit} debug={DEBUG} style={{top: 440, }} />

            </Sprite.Container>

        </Container>
    );
}

/* NOTES FOR PRO TAX
Market $345
James redirect,
*/