import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import "./Forms.scss";

const debug = 0;

export default function Page(){



    // render
    return (
        <React.Fragment>



            <Layout.WithPanel showPanel={false}>
                <Layout.Sidenav spriteId="lds-forms-sidenav">

                    <Layout.CTA to="/forms/editor" debug={debug} />
                    <Layout.Selection label="Submission" style={{top: 91, }} />
                    <Layout.Item to="/forms/forms/" style={{top: 130, }} />

                </Layout.Sidenav>
                <Layout.Content>

                    <Sprite.Split id="lds-forms-submissions">
                        <Loader abs debug={debug} type="preload" color="translucent" duration={1000} style={{top: 116, height: 710,  }} />
                        <Sprite.Button abs debug={debug} to="/forms/details/" style={{top: 110, width: '100%', height: 230, }} />

                        <Sprite.Left />
                        <Sprite.Right />
                    </Sprite.Split>

                </Layout.Content>
            </Layout.WithPanel>
        </React.Fragment>

    )

    // return (
    //     <React.Fragment>
    //         <Sprite.Sidenav onCTA={() => {}} debug={debug} id="lds-forms-subs-sidenav">
    //             <Sprite.Button debug={debug} style={{top: 90, width: "100%", height: 120, }} />
    //         </Sprite.Sidenav>


    //         <Sprite.Split id="lds-forms-submissions">
    //             <Sprite.Button abs debug={debug} to="/forms/details/" style={{top: 110, width: '100%', height: 270, }} />
    //             <Sprite.Left />
    //             <Sprite.Right />
    //         </Sprite.Split>

    //     </React.Fragment>
    // );
}