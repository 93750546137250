import React from "react";
import {Sprite, Select, Alert} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Modal from "../../components/containers/Modal";
import Popover from '@mui/material/Popover';
import { Button } from "@mui/material";
import "./ColorPickerPopover.scss";



const debug = 0;

// "#0063B2", 175CD3
const colors2 = [
    "#869FCA", "#D00000", "#E04F16", "#D67300", "#CA8504", "#4CA30D", "#099250", "#0E9384", "#088AB2", "#0086C9", "#1570EF", "#6938EF", "#BA24D5", "#DD2590",
    "#6B82A9", "#AE0000", "#B93815", "#AC5D00", "#A15C07", "#3B7C0F", "#087443", "#107569", "#0E7090", "#026AA2",    "#0063B2",     "#5925DC", "#9F1AB1", "#C11574",
    "#475B7C", "#9C0000", "#932F19", "#8E4D00", "#854A0E", "#326212", "#095C37", "#125D56", "#155B75", "#065986", "#1849A9", "#4A1FB8", "#821890", "#9E165F",
    "#384966", "#870000", "#772917", "#743F00", "#713B12", "#2B5314", "#084C2E", "#134E48", "#164C63", "#0B4A6F", "#194185", "#3E1C96", "#6F1877", "#851651",
    // "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx", "#xxx",
];

function Item(props){
    const {color, key, value, onClick, } = props;

    // const css = "lds-demos-colorpicker-item" + (key === 0 ? " is-selected" : ""); // (value === color ? " is-selected" : "");
    let css = "lds-demos-colorpicker-item";
    if(value === color) css += " is-selected";
    return (
        <div className={css} key={key} onClick={() => onClick(color)}>
            <div style={{backgroundColor: color, }} />
        </div>
    );
}




export default function ColorPickerPopover(props){
    const {id, open, anchorEl, onClose, anchorOrigin, transformOrigin, onClick, value, } = props;
    const [colors, setColors] = React.useState([...colors2]);
    const changed = (value === "#AE0000");

    React.useEffect(() => {
        setColors([...colors2]);
        // setChanged((value === "#AE0000"));
        // console.log("============== COLOR PICKER: " + value);
    }, [value]);


    // render
    const css = utils.classNames("lds-demos-colorpicker", changed && "is-changed");
    return (
        <Popover id={id} className="lds-demos-colorpicker-popover" open={open} anchorEl={anchorEl} onClose={onClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
            
            <Sprite.Container className={css}>
                <div className="lds-demos-colorpicker-list">
                {colors.map((color, key) => <Item key={key} color={color} value={value} onClick={onClick} />)}

                </div>
            </Sprite.Container>
            
        </Popover>
    );
}

