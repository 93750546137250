import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Layout from "../../components/containers/Layout";
import Loader from "../../components/core/Loader";
import { useGlobals, useDispatch } from '../../app/context';
import PatientsPanel from "./PatientsPanel";
import utils from "../../functions/utils";
import "./Patients.scss";

const debug = 0;


export default function Page(){
    const dispatch = useDispatch();
    // const {showPatientsPanel} = useGlobals();

    // new
    const [selection, setSelection] = React.useState(null);
    const onClickPanelClose = (value) =>  setSelection(null);
    const onChangeRow = (i) => setSelection(i);


    // render
    return (
        <React.Fragment>


            <Layout.WithPanel showPanel={utils.showPanel(selection)}>
                <Layout.Content noPadding222 className="is-debug222">

                    <Loader type="preload" debug222 duration={2000} style={{top: 120, height: 700, }}  />

                    <Sprite.Split debug={debug} id="lds-patients">

                        <Sprite.Rows abs debug={debug} count={16} selection={selection} onChange={onChangeRow} style={{zIndex: 1, top: 115, }} />
                        <Sprite.Left />
                        <Sprite.Right />

                    </Sprite.Split>

                </Layout.Content>
                <Layout.Panel noPadding onClickClose={onClickPanelClose}>
                    
                    <Loader.Reloader type="preload" color="translucent" index={selection} duration={1000}  />
                    <PatientsPanel title={utils.names[selection]} />

                </Layout.Panel>
            </Layout.WithPanel>

        </React.Fragment>

    )
}