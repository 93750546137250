import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, useStepper, steps, } from "./Onboard2.config";
import "./Onboard2.scss";

const DEBUG = 1;


export default function Page(props){
    const {nextUrl, } = useStepper();



    // render
    return (
        <Container icon222="clock" title="Success">
            <Sprite.Container className="lds-onboard2-success">

                <Sprite.Button to={nextUrl} debug={DEBUG} style={{top: 910, left: 130, width: 220,  }} />
            
            </Sprite.Container>
        </Container>
    );
}
