import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, useStepper, steps, } from "./Onboard2.config";
import "./Onboard2.scss";


const DEBUG = 0;


// --- pages --- //

export default function Page(){
    const dispatch = useDispatch();
    const {onboard1, } = useGlobals();
    const {baseUrl, nextUrl, stepName, stepIndex, } = useStepper();

    console.log("stepName: " + stepName + ", stepIndex: " + stepIndex + ", nextUrl: " + nextUrl);


    return (
        <React.Fragment>

            <Email title="Finish setting up Town Pharmacy" spriteId="lds-onboard1-email-singleowner">

                <Sprite.Button debug={DEBUG} to={nextUrl} style={{top: 350, left: 190, width: 130, }} />
                
            </Email>

        </React.Fragment>

    );
}


