import {STATE, } from './settings';

function check(state, method, message){
    console.warn("REDUCER WARN - " + method + ": " + message);
    return {...state};
}

function warn(state, method, message){
    console.warn("REDUCER WARN - " + method + ": " + message);
    return {...state};
}

// reducers
const reducers = {

    // INITIATE: (state, action) => {
        
    //     console.log("####### INITIATE 555");
    //     const {screen, callback} = action;
    //     state = {...state, initiated: true, screen: screen};
    //     if(callback) callback(state);
    //     return state;
    // },

    // load module data
    LOAD_DATA: (state, action) => {
        const {modules} = action;
        // let state2 = {...state};

        // modules data
        let map = {};
        modules.forEach(module => {
            const {name, data} = module;
            if(data) map[`${name}_rows`] = data;
        })

        console.log("============= LOAD_DATA: ", map);
        return {...state, ...map};
    },

    SHOW_MODAL: (state, action) => {
        const {component} = action;
        return {...state, showModal: true, modalComponent: component, };
    },

    HIDE_MODAL: (state, action) => {
        return {...state, showModal: false, modalComponent: undefined, };
    },

    SHOW_MOBILE: (state, action) => {
        return {...state, showMobile: true, };
    },

    HIDE_MOBILE: (state, action) => {
        return {...state, showMobile: false, };
    },

    HIDE_LOADER: (state, action) => {
        return {...state, showLoader: false, };
    },

    SHOW_LOADER: (state, action) => {
        const {component} = action;
        return {...state, showLoader: true, };
    },

    HIDE_MODAL_LOADER: (state, action) => {
        return {...state, showModalLoader: false, };
    },

    SHOW_MODAL_LOADER: (state, action) => {
        const {component} = action;
        return {...state, showModalLoader: true, };
    },

    SET_AUTH: (state, action) => {
        let {value} = action;
        value = !!value;
        if(value) localStorage.setItem("demoAuth", 'true'); // set cache
        else localStorage.removeItem("demoAuth"); // remove cache
        return {...state, isAuth: value, };
    },

    TOGGLE_MESSAGES_PANEL: (state, action) => {
        const {value} = action;
        return {...state, showMessagesPanel: (typeof value === 'boolean') ? value : !state.showMessagesPanel, };
    },

    TOGGLE_TASKS_PANEL: (state, action) => {
        const {value} = action;
        return {...state, showTasksPanel: (typeof value === 'boolean') ? value : !state.showTasksPanel, };
    },

    TOGGLE_PATIENTS_PANEL: (state, action) => {
        const {value} = action;
        return {...state, showPatientsPanel: (typeof value === 'boolean') ? value : !state.showPatientsPanel, };
    },

    TOGGLE_SCHEDULE_PANEL: (state, action) => {
        const {value} = action;
        return {...state, showSchedulePanel: (typeof value === 'boolean') ? value : !state.showSchedulePanel, };
    },

    SET_CONTEXT: (state, action) => {
        const {item} = action;
        // set selected
        const items = state.contextItems.map(item2 => {
            item2.selected = (item.id === item2.id);
            return item2;
        });
        // return
        return {...state, context: item, contextItems: [...items], }; // 
    },

    SET_PHARMACY_CHAT: (state, action) => {
        const {value} = action;
        const limit = 4; // one more than 3
        return {...state, pharmacyChat: (value >= limit) ? limit : value, };
    },

    SET_PATIENT_CHAT: (state, action) => {
        const {value} = action;
        const limit = 4; // one more than 3
        return {...state, patientChat: (value >= limit) ? limit : value, };
    },

    SET_TASK_SELECTION: (state, action) => {
        const {value} = action;
        return {...state, taskSelection: value, };
    },

    SET_APPT_ADDED: (state, action) => {
        const {value} = action;
        return {...state, apptAdded: value === undefined ? true : value, };
    },

    SET_MESSAGE_ADDED: (state, action) => {
        const {value} = action;
        return {...state, messageAdded: value === undefined ? true : value, };
    },

    SET_DEMO: (state, action) => {
        const {value} = action;
        return {...state, demo: value, };
    },

    SET_REDIRECT: (state, action) => {
        const {value} = action;
        console.log("SET_REDIRECT: " + value);
        return {...state, redirect: value, };
    },

    // --- onboard 1 --- //

    // SET_ONBOARD1_STEP: (state, action) => {
    //     const {value} = action;
    //     const {onboard1} = state;
    //     return {...state, onboard1: {...onboard1, step: value, }, };
    // },

    // SET_ONBOARD1_VALUES: (state, action) => {
    //     const {values, } = action;
    //     const {onboard1, } = state;
    //     console.log("==== SET_ONBOARD1_VALUES: ", onboard1);
    //     return {...state, onboard1: {...onboard1, ...values, }, };
    // },


    // --- onboard 2 --- //

    SET_ONBOARD2: (state, action) => {
        const {values, } = action;
        const {onboard2, } = state;
        return {...state, onboard2: {...onboard2, ...values, }, };
    },

    RESET_ONBOARD2: (state, action) => {
        return {...state, onboard2: {...STATE.onboard2}, };
    },

    // END
}

export default reducers;