import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import ApptModal from "./AppointmentModal";
import "./Schedule.scss";

const debug = 0; // debug


export default function Page(){
    const dispatch = useDispatch();
    // const {details} = location.state || {};
    // const {showSchedulePanel, } = useGlobals();
    const [showPanel, setPanel] = React.useState(false);
    const {state} = useLocation();

    const onCTA = () => dispatch({call: "SHOW_MODAL", component: <ApptModal />, }); // ApptModal
    //const onCTA = () => {};


    // const onClickToggle = (value) => dispatch({call: "TOGGLE_SCHEDULE_PANEL", value: value, });
    const onClickToggle = () => setPanel(!showPanel);
    const onLoaderComplete = () => {
        if(state?.showPanel) setPanel(true);
    }



    return (

        <React.Fragment>

            <Loader type="preload" color="translucent" onComplete={onLoaderComplete} debug={debug} duration={2000} style={{top: 70, left: 280, width: 1050, height: 800, }} />


            <Layout.WithPanel showPanel={showPanel}>
                <Layout.Sidenav spriteId="lds-schedule-sidenav">

                    <Layout.CTA to="/schedule/add" debug={debug} onClick222={onCTA} />
                    <Layout.Selection  debug={debug} label="Upcoming" style={{top: 91}} />
                    <Layout.Hotspot debug={debug} style={{top: 90, height: 330, }} />

                </Layout.Sidenav>
                <Layout.Content noPadding style={{paddingTop: 24, paddingLeft: 24, }}>
                    <Sprite.Container id="lds-schedule-calendar">

                        <Sprite.Button debug={debug} style={{top: 0, left: 0, width: 150, }} />
                        <Sprite.Button debug={debug} style={{top: 0, left: 810, width: 210, }} />
                        <Sprite.Button debug={debug} style={{top: 80, left: 0, width: 1020, height: 730, }} onClick={onClickToggle} />

                    </Sprite.Container> 
                </Layout.Content>
                <Layout.Panel noPadding onClickClose={onClickToggle}>
                    <Sprite.Container id="lds-schedule-panel">
                        
                        {/* PANEL CONTENT */}

                    </Sprite.Container>
                </Layout.Panel>
            </Layout.WithPanel>


            {/* 
            <Sprite.Sidenav onCTA={onCTA} debug={debug} id="lds-schedule-sidenav">
                <Sprite.Button debug={debug} style={{top: 90, width: "100%", height: 120, }} />
            </Sprite.Sidenav>

            <Sprite.Container id="lds-schedule">
                <Sprite.Button debug={debug} style={{top: 20, left: 290, width: 150, }} />
                <Sprite.Button debug={debug} style={{top: 20, left: 1100, width: 210, }} />
            </Sprite.Container> 
            */}


        </React.Fragment>


    )
}