import React from "react";
import {Sprite, Select, Alert, Input, } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import { useNavigate } from "react-router";
import Loader from "../../components/core/Loader";
import utils from "../../functions/utils";
import Modal from "../../components/containers/Modal";

const debug = 0;

export default function Modal2(props){
    const dispatch = useDispatch();
    const {sales, } = useGlobals();
    const nav = useNavigate();
    const [sending, setSending] = React.useState(false);
    const onSend = () => setSending(true);
    const onCompleteLoader = () => {
        dispatch({call: "SET_TASK_SELECTION", value: 2, });
        dispatch({call: "HIDE_MODAL"});
    }


    // status radios
    const [status, setStatus] = React.useState('1');
    const onStatusClick = (item) => setStatus(item.value);

    // type select
    const typeOptions = ['General', 'Refill', 'Transfer'];
    // const typeOptions = {"General": '1', "Refill": '2', "Transfer": '3', }
    // const [formError, setFormError] = React.useState(null);

    // utils.showError("yooooo 333", formError);

    // const onOK = (e) => {
    //     e.preventDefault();
    //     dispatch({call: "HIDE_MODAL"});
    //     // console.log("++++++++++ onOK");
    //     // // dispatch({call: "HIDE_MODAL"});

    //     // dispatch({call: "SHOW_MODAL_LOADER", });
    //     // setTimeout(() => {
    //     //     dispatch({call: "HIDE_MODAL_LOADER", });
    //     //     dispatch({call: "HIDE_MODAL"});
    //     // }, 2000);
    // }

    // const typeOptions = ['General', 'Refill', 'Transfer'];

    return (
        <Modal size="lg" label="New Task" buttons={{ok: onSend}}>
        
            {sending && <Loader type="preload" color="translucent" duration={1000} onComplete={onCompleteLoader} />}

            
            <Sprite.Container id="lds-tasks-edit-modal">    

                {/* <Input.Checkbox debug={debug} sprite style={{top: 2, width: 100, }} /> */}


                <Input.Radio debug={debug} groupValue={status} onClick={onStatusClick} name="status" value="1" sprite style={{top: 0, width: 100, }} />
                <Input.Radio debug={debug} groupValue={status} onClick={onStatusClick} name="status" value="2" sprite style={{top: 0, width: 100, left: 140, }} />
                <Input.Radio debug={debug} groupValue={status} onClick={onStatusClick} name="status" value="3" sprite style={{top: 0, width: 100, left: 280, }} />
                <Input.Radio debug={debug} groupValue={status} onClick={onStatusClick} name="status" value="4" sprite style={{top: 0, width: 100, left: 420, }} />
                

                <Input.Select sprite debug={debug} label="Type" options={typeOptions} style={{top: 40, left: 0, width: 300, }} />

                <Input sprite debug={debug} label="Summary" value={sales("Contact Dr. Lane about updating supply")} style={{top: 117, left: 10, width: '98%', }} />
                <Input sprite debug={debug} label="Assignee" value={sales("Courtney Henry")} style={{top: 191, left: 43, width: 370, }} />
                <Input sprite debug={debug} label="Patient" value={sales("Martha Smith")} style={{top: 367, left: 43, width: 370, }} />
                <Input.TextArea sprite debug={debug} label="Descriptions" value={sales("Martha Smith would like to fill her Humalog Kwik Pen prescription for a 90 day supply instead of 30 days. Contact Dr. Lane’s office for approval, he’s done this in the past.")} style={{top: 440, left: 8, height: 84, width: '98%', }} />
                 
                {/* <Sprite.Button debug={debug} style={{top: 0, left: 0, width: 220, height: 30, }} className="is-cursor-text" /> */}
                {/* <Sprite.Button debug={debug} style={{top: 40, left: 0, width: 220, height: 400, }} />
                <Sprite.Button debug={debug} style={{top: 160, left: 240, width: 530, height: 190, }} className="is-cursor-text" /> */}

            </Sprite.Container>
        </Modal>
    );
}

