import React from "react";
import {Sprite, Select, Alert, Icon2, } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Modal from "../../components/containers/Modal";
import Layout from "../../components/containers/Layout";

import { useNavigate } from 'react-router-dom';
import { Button } from "@mui/material";
import "./Demos.scss";


const debug = 0;


// --- main --- //

function Item(props){
    let {to, url, onClick, label, } = props;
    const nav = useNavigate();

    if(!onClick) onClick = () => {
        utils.nav(nav, to);
    }

    const onDemoClick = () => {
        // const {name, } = item;
        // dispatch({call: "SET_DEMO", value: item.name, });
        // console.log("==== yoooooo: ", item);
        if(url) window.location = url;
        else utils.nav(nav, to);
    }

    // if(url) return <div><a href={url}>{label}</a></div>
    return <div className="lds-demos-link" onClick={() => onDemoClick()} >{label}</div>;
}

function Section(props){
    const {title, description, children} = props;

    return (
        <div className="lds-demos-section">
            {title && <h2>{title}</h2>}
            {description && <p>{description}</p>}
            {children}
        </div>
    );
}

export default function Screen(){
    // const dispatch = useDispatch();
    // const nav = useNavigate();
    // const {demos, } = useGlobals();

    // PATH 1 --- M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z
    // PATH 2 --- M29.75 29.75L23.5875 23.5875M26.9167 15.5833C26.9167 21.8426 21.8426 26.9167 15.5833 26.9167C9.32411 26.9167 4.25 21.8426 4.25 15.5833C4.25 9.32411 9.32411 4.25 15.5833 4.25C21.8426 4.25 26.9167 9.32411 26.9167 15.5833Z


    // render
    return (
        <div id="lds-demos-page">

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" color="#333333"><path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a1.998 1.998 0 0 1 0 2.83 1.998 1.998 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-4 0v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a1.998 1.998 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 1 1 0-4h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 1 1 2.83-2.83l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 1 1 4 0v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 3.417 1.415 2 2 0 0 1-.587 1.415l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 0 4h-.09a1.65 1.65 0 0 0-1.51 1Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>


<svg width="16" height="16" viewBox="0 0 16 16" fill="none" color="#333333"><path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a1.998 1.998 0 0 1 0 2.83 1.998 1.998 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-4 0v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a1.998 1.998 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 1 1 0-4h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 1 1 2.83-2.83l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 1 1 4 0v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 3.417 1.415 2 2 0 0 1-.587 1.415l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 0 4h-.09a1.65 1.65 0 0 0-1.51 1Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>




<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z" stroke="#748995" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
<path d="M29.75 29.75L23.5875 23.5875M26.9167 15.5833C26.9167 21.8426 21.8426 26.9167 15.5833 26.9167C9.32411 26.9167 4.25 21.8426 4.25 15.5833C4.25 9.32411 9.32411 4.25 15.5833 4.25C21.8426 4.25 26.9167 9.32411 26.9167 15.5833Z" stroke="#748995" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



            <Icon2 type="chevron-right" size={22} stroke={3} />

            <h1>Internal Demos</h1>

            <p>Click on the notiable screens and flows below.</p>

            <div id="lds-demos-list">


                <Section title="Lab" description222="Notable screens and flows">
                    <Item to="/demos/onboard2" label="Onboard 2: Single-owner" />
                    <Item to="/demos/onboard1" label="Onboard 1: Single-owner" />
                    <Item to="/demos/onboard1/6" label="Onboard 1: Design step" />
                    <Item to="/dashboard3" label="Dashboard v2" />
                    <Item to="/app/push/refillready" label="Patient refill flow" />
                </Section>

                <Section title="Demos" description222="Notable screens and flows">
                    <Item url="http://design.lumistry.com/" label="Design Demo" />
                    <Item url="http://demo.lumistry.com/" label="Sales Demo" />
                </Section>

            </div>
        </div>


    );
}
