import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import Mobile from "../../components/containers/Mobile";
import Button from "../../components/core/Button";
import MedsDetailsModal from "./MedsDetailsModal";
import MedsInsuranceSheet from "./MedsInsuranceSheet";
import Loader from "../../components/core/Loader";
import MedsTabs from "./MedsTabs";

const debug = 0;



export default function Screen(){
    const dispatch = useDispatch();
    const refillagain = true; // CONFIG

    // const onClickCTA = () => {}
    // const onClickDetails = () => {}
    const onClickDetails = () => dispatch({call: "SHOW_MODAL", component: <MedsDetailsModal />});

    // const onClickDetails = () => dispatch({call: "SHOW_MODAL", component: <MedsDetailsModal />});

    const onClickRefill = () => {
        console.log("========= onClickRefill");
        dispatch({call: "SHOW_MODAL", component: <MedsInsuranceSheet />})
    }

    // render
    return (
        <React.Fragment>

            <Loader type="preload" color="translucent" debug={debug} duration={2000} style={{height: 812, }} />

            {refillagain && <Button onClick={onClickRefill} type="primary" mobileBottom icon="shop-white" shadow label="Order" />}
            {!refillagain && <Button to="/app/meds/refill" type="primary" mobileBottom icon="shop-white" shadow label="Order" />}
            
            <Mobile.Wrapper type="tab" label="Medications" spriteId="lds-app-meds" name="meds" topContent={<MedsTabs />}>

                <Input.Checkbox debug={debug} size="lg" style={{top: 368, left: 16, width: 150, }} />
                <Input.Checkbox debug={debug} size="lg" style={{top: 458, left: 16, width: 150, }} />
                <Input.Checkbox debug={debug} size="lg" style={{top: 548, left: 16, width: 150, }} />
                <Input.Checkbox debug={debug} size="lg" style={{top: 658, left: 16, width: 150, }} />

                <Sprite.Button debug={debug} style={{top: 360, left: 210, width: 40, }} onClick={onClickDetails} />
                <Sprite.Button debug={debug} style={{top: 450, left: 230, width: 40, }} onClick={onClickDetails} />
                <Sprite.Button debug={debug} style={{top: 650, left: 200, width: 40, }} onClick={onClickDetails} />
                <Sprite.Button debug={debug} style={{top: 770, left: 120, width: 40, }} onClick={onClickDetails} />
                <Sprite.Button debug={debug} style={{top: 840, left: 160, width: 40, }} onClick={onClickDetails} />



            </Mobile.Wrapper>
        </React.Fragment>

    );
}
