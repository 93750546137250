import React, { useEffect } from "react";
import Mobile from "../../components/containers/Mobile";
import { Sprite, Input } from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";


const debug = 0;

export default function Modal(){
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    const closer = () => dispatch({call: "HIDE_MODAL"});

    const onEnter = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            closer();
        }, 1000);
    }

    // const Footer = () => (
    //     <Sprite.Button id="lds-app-notifications-modal-footer" onClick={() => dispatch({call: "HIDE_MODAL"})} />
    // );

    return (
        <Mobile.Modal spriteId="lds-app-store-locator-modal" label="Select Store">
            {loading && <Loader type="preload" color="translucent" duration={4000} />}
            <Input sprite standard className="is-abs" label="Store, city, zip code" onEnter={onEnter} style={{top: 5, left: 54, width: 260, }} />
            <Sprite.Button debug={debug} onClick={closer} style={{width: "100%", top: 110, height: 80, }} />
            <Sprite.Button debug={debug} onClick={closer} style={{width: "100%", top: 240, height: 410, }} />
        </Mobile.Modal>
    );
}
