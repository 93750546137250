import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import PatientsPanel from "../patients/PatientsPanel";
import "./Messages.scss";

const debug = 0;

function Convo(){
    const dispatch = useDispatch();
    const {pharmacyChat} = useGlobals();
    const [value, setValue] = React.useState("");
    const onValueChange = (e) => {
        const messages = [0, "No Problem Martha! We’ll go head and get you taken care of. We’ll just have to get Dr. Lane to update the prescription"];
        utils.typing(e, setValue, messages, pharmacyChat);
    };

    // handlers
    const onClickSend = (e) => {
        dispatch({call: "SET_PHARMACY_CHAT", value: pharmacyChat + 1, });
        setValue(""); // clear input
    };

    const onLastChat = () => {
        dispatch({call: "SET_PHARMACY_CHAT", value: pharmacyChat + 1, });
    }

    // on chat count
    React.useEffect(() => {
        const delay = (pharmacyChat === 0) ? 2000 : 4000;
        if(pharmacyChat === 0 || pharmacyChat === 2) setTimeout(() => {
            dispatch({call: "SET_PHARMACY_CHAT", value: pharmacyChat + 1, });
        }, delay);
    }, [pharmacyChat]);

    // render
    const css2 = utils.classNames(pharmacyChat && `is-count-${pharmacyChat}`);
    return (
        <Sprite.Container debug={debug} id="lds-messages-convo" style={{top: 140, left: 263, width: 734, height: 440, }}>

            <Sprite.Container debug={debug} id="lds-messages-convo-top">
                <Sprite.Container debug={debug} id="lds-messages-convo-thread" className={css2}>
                    {/* {(pharmacyChat === 1) && <Misc.ChatDots style={{top: 197, left: 50, }} duration={6000} />} */}
                    {(pharmacyChat === 3) && <Misc.ChatDots style={{top: 351, left: 50, }} duration={4000} onComplete={onLastChat} />}
                </Sprite.Container>
            </Sprite.Container>
            <Input debug={debug} id="lds-messages-convo-input" onChange={onValueChange} value={value} inputId="lds-messages-convo-field" label="Type your message..." onEnter={onClickSend} standard sprite />
            <Sprite.Button debug={debug} onClick={onClickSend} />
        </Sprite.Container>
    );
}

export default function Page(){
    const dispatch = useDispatch();
    const [showPanel, setPanel] = React.useState(false);
    const [showActivity, setActivity] = React.useState(false);
    
    React.useEffect(() => {
        setTimeout(() => setPanel(true), 1000)
    }, []);

    const onCTA = () => dispatch({call: "SHOW_MODAL", component: <ChatModal />});
    // const onClickToggle = (value) => dispatch({call: "TOGGLE_MESSAGES_PANEL", value: value, });
    const onClickToggle = () => setPanel(!showPanel);


    // render
    return (
        <React.Fragment>

            {false && <Loader type="preload" duration={2000} onComplete={onClickToggle} style={{top: 70, left: 280, width: 1020, height: 680,  }} />}

            <Layout.WithPanel showPanel={showPanel}>
                <Layout.Sidenav spriteId="lds-messages-sidenav">

                    <Sprite.Button debug={debug} to="/messages/send" style={{top: 20, left: 100, width: 80, }} />
                    <Sprite.Button debug={debug} to="/messages/auto" style={{top: 20, left: 180, width: 80, }} />

                    <Layout.CTA debug={debug} onClick={onCTA} style={{top: 80, }} />
                    <Layout.Selection  debug={debug} label="All" style={{top: 143, }} />
                    <Layout.Hotspot debug={debug} style={{top: 140, height: 340, }} />

                </Layout.Sidenav>
                <Layout.Content noPadding style={{paddingTop: 0, paddingLeft: 24, }}>
                    <Sprite.Container id="lds-messages-inbox">

                        {/* <Sprite.Button debug={debug} style={{top: 0, left: 0, width: 150, }} />
                        <Sprite.Button debug={debug} style={{top: 0, left: 810, width: 210, }} /> */}
                        <Sprite.Button debug={debug} style={{top: 70, left: 260, width: 320, height: 50, }} onClick={onClickToggle} />
                        <Sprite.Button debug={debug} style={{top: 150, left: 0, width: 250, height: 460, }} onClick={onClickToggle} />


                        <Convo />

                    </Sprite.Container> 
                </Layout.Content>
                <Layout.Panel noPadding onClickClose={onClickToggle}>

                    <PatientsPanel />

                </Layout.Panel>
            </Layout.WithPanel>




        </React.Fragment>

    )
}