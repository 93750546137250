let i = 1;

const rows = [
    {
        id: i++,
        firstname: "Arlene",
        lastname: "McCoy",
        color: '#6DAC5D',
    },
    {
        id: i++,
        firstname: "Martha",
        lastname: "Johnson",
        color: '#3F92BE',
    },
    {
        id: i++,
        firstname: "Courtney",
        lastname: "Henry",
        color: '#C9D36E',
    },
    {
        id: i++,
        firstname: "Theressa",
        lastname: "Web",
        color: '#B5983A',
    },
    {
        id: i++,
        firstname: "Marvin",
        lastname: "McKinney",
        color: '#6EB292',
    },
    {
        id: i++,
        firstname: "Savanna",
        lastname: "Smith",
        color: '#C4873E',
    },
    {
        id: i++,
        firstname: "Jason",
        lastname: "Paterson",
        color: '#DD4374',
    },
];

rows.forEach(item => {
    item.initials = item.firstname[0] + item.lastname[0]; // initials
    if(item.inverse === undefined) item.inverse = true; // default inverse is TRUE
})


export default rows;