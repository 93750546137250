import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import Mobile from "../../components/containers/Mobile";
import utils from "../../functions/utils";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';

import Loader from "../../components/core/Loader";
import { Button } from "@mui/material";
import "./Auth.scss";

const debug = 0;

const bypassAliases = {
    "refillready": "/app/home?notification=refillready",
}

export default function Screen(props){
    const dispatch = useDispatch();
    const nav = useNavigate();
    const location = useLocation();
    const {bypass} = location.state || {}; // nav params

    // bypass?
    React.useEffect(() => {
        if(!bypass) return;
        const url = bypassAliases[bypass] || bypass;
        
        setTimeout(() => {
            utils.delayRedirect(dispatch, nav, url, 2000);
        }, 500);
        // setTimeout(() => utils.nav(nav, url), 500);
    }, []);

    const onClickEmail = () => {
        utils.delayRedirect(dispatch, nav, "/app/home");
    }

    // render
    return (
        <Mobile.Wrapper type="basic" spriteId="lds-app-login">

            <Sprite.Button debug={debug} style={{top: 300, width: '100%', }} />
            <Sprite.Button debug={debug} onClick={onClickEmail} style={{top: 520, width: '100%', }} />

        </Mobile.Wrapper>
    );
}
